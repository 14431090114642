<template>
  <v-card class="px-5">
    <v-dialog v-model="dialog_delete" max-width="600px" persistent>
        <v-card>
            <v-card-title class="text-h5">Soll dieser Eintrag wirklich gelöscht werden?</v-card-title>
            <v-card-text class="text-body-1">
                Die Dokumentation dieses Termins sowie zugehörige Dokumente werden ebenso gelöscht.
            </v-card-text>
            <v-card-actions class="px-6 pb-5">
            <v-spacer></v-spacer>
            <v-btn :color="$store.state.theme.primary" :disabled="deleting_appointment" text @click="dialog_delete = false">Nein</v-btn>
            <v-btn class="ml-2" :color="$store.state.theme.red" :loading="deleting_appointment" outlined @click="deleteItemConfirm">Ja</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-row>
        <span class="my-7 px-3 text-h5">{{ formTitle }}</span>
    </v-row>
    <v-form v-model="valid" ref="form">
        <v-row>
            <v-col cols="12" class="py-0">
                <v-autocomplete v-model="editedItem.selected" :loading="!loadedCustomers" loader-height="5" :disabled="!loadedCustomers"
                :items="sortedCustomers" item-text="name" item-value="fk_klienten_id" return-object
                label="Klient" outlined dense :rules="clientRule">
                    <template v-slot:item="{ item }">
                        <div class="d-flex align-center">
                            <span>{{ item.name }}</span>
                            <v-chip v-if="item.warteliste" :color="$store.state.theme.orange" class="ml-2" dark small>Warteliste</v-chip>
                            <v-chip v-else-if="item.archiviert" :color="$store.state.theme.primary" class="ml-2" dark small>Archiviert</v-chip>
                            <v-chip v-for="merkmal in item.merkmale" :key="'chip-' + merkmal.id" class="ml-2" small>{{ merkmal.merkmal }}</v-chip>
                        </div>
                    </template>
                    <template v-slot:selection="{ item }">
                        <div class="d-flex align-center">
                            <span>{{ item.name }}</span>
                            <v-chip v-if="item.warteliste" :color="$store.state.theme.orange" class="ml-2" dark small>Warteliste</v-chip>
                            <v-chip v-else-if="item.archiviert" :color="$store.state.theme.primary" class="ml-2" dark small>Archiviert</v-chip>
                            <v-chip v-for="merkmal in item.merkmale" :key="'chip-selection-' + merkmal.id" class="ml-2" small>{{ merkmal.merkmal }}</v-chip>
                        </div>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
        <div v-if="editedIndex===-1">
            <v-row v-for="(appointment, index) in appointments_to_insert" :key="appointment.id" class="my-3">
                <v-col cols="6" class="py-0 my-auto">
                    <v-menu :close-on-content-click="true" offset-y max-width="290px" min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field dense outlined v-model="appointment.appointment.datumFormatted" label="Datum" hint="" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" @input="updateDate($event, index)" :rules="dateRule" />
                        </template>
                        <v-date-picker dense outlined first-day-of-week="1" v-model="appointment.appointment.datum" no-title @input="updateAndClose($event, index)"/>
                    </v-menu>
                </v-col>
                <v-col cols="6" class="d-flex py-0 my-auto">
                    <v-text-field dense outlined v-model="appointment.appointment.uhrzeit" label="Uhrzeit" value="12:00:00" type="time" :rules="timeRule" />
                    <v-btn v-if="appointment.id === appointments_to_insert.length" class="ml-5" icon @click="addAppointmentRow">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                    <v-btn v-if="appointment.id === appointments_to_insert.length && appointments_to_insert.length > 1" class="ml-2" icon @click="removeAppointmentRow">
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </div>
        <v-row v-else class="my-3">
            <v-col cols="6" class="py-0 my-auto">
                <v-menu :close-on-content-click="true" offset-y max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field dense outlined v-model="editedItem.datumFormatted" label="Datum" hint="" prepend-inner-icon="mdi-calendar" v-bind="attrs" v-on="on" @input="updateDate($event, null)" :rules="dateRule" />
                    </template>
                    <v-date-picker dense outlined first-day-of-week="1" v-model="editedItem.datum" no-title @input="updateAndClose($event, null)" />
                </v-menu>
            </v-col>
            <v-col cols="6" class="py-0 my-auto">
                <v-text-field dense outlined v-model="editedItem.uhrzeit" label="Uhrzeit" value="12:00:00" type="time" :rules="timeRule" />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12" md="6" class="py-0">
                <v-autocomplete v-model="service" :loading="!loaded_services" loader-height="5" :disabled="!loaded_services" 
                    :items="services" item-text="bezeichnung" item-value="id" return-object 
                    label="Dienstleistung" outlined dense @change="changedService" clearable clear-icon="mdi-close-circle" :rules="serviceRule">
                    <template v-slot:item="{ item }">
                        <div class="d-flex align-center py-3 py-sm-0">
                            <span>{{ item.bezeichnung }}</span>
                            <v-chip v-if="item.dauer !== null || item.preis !== null" class="ml-2" small>
                                <span v-if="item.dauer !== null && item.preis !== null">{{ item.preis }} € / {{ item.dauer }} Min.</span>
                                <span v-else-if="item.dauer !== null">{{ item.dauer }} Min.</span>
                                <span v-else-if="item.preis !== null">{{ item.preis }} €</span>
                            </v-chip>
                        </div>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col class="py-0">
                <v-text-field  dense outlined type="number" v-model="editedItem.preis" label="Preis" :rules="amountRule" />
            </v-col>
            <v-col class="py-0">
                <v-text-field  dense outlined type="number" v-model="editedItem.dauer" label="Dauer (Minuten)" :rules="durationRule" />
            </v-col>
        </v-row>
    </v-form>

    <v-row no-gutters class="mt-0 pt-0" v-if="editedItem.selected && (editedItem.klient_termin_erinnerung || editedItem.selected.termin_erinnerung)">
        <v-col cols="12" class="d-flex my-0 py-0 align-center justify-start">
            <v-switch :append-icon="editedItem.termin_erinnerung === true ? 'mdi-bell-ring' : 'mdi-bell-off'" class="mr-5" v-model="editedItem.termin_erinnerung" inset
                      :color="$store.state.theme.green" :disabled="editedItem.erinnerung_gesendet !== null" />
            <span class="text-body-1">
                {{ editedItem.termin_erinnerung ? '' : 'Keine ' }}Termin-Erinnerung per SMS
            </span>
        </v-col>
        <v-col cols="12" v-if="editedItem.erinnerung_gesendet !== null">
            <v-alert type="info" outlined>
                Eine Termin-Erinnerung wurde bereits versendet. Falls du das Datum oder die Uhrzeit änderst, wird keine neue Erinnerung versendet.
            </v-alert>
        </v-col>
    </v-row>

    <v-card-actions class="mx-0 px-0 pb-5">
        <v-btn v-if="editedIndex > -1" :color="$store.state.theme.red" outlined @click="deleteItem">
            {{ $vuetify.breakpoint.xsOnly ? 'Löschen' : 'Termin löschen' }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn :color="$store.state.theme.primary" :disabled="inserting_entry" text @click="abort">
            Abbrechen
        </v-btn>
        <v-btn :color="$store.state.theme.green" outlined @click="save" :disabled="!showSaveButton" :loading="inserting_entry">
            Speichern
        </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import connector from '../helpers/supabase-connector.js'
import cipher from '@/helpers/cipher'
import dayjs from 'dayjs'

export default {
    emits: ['close', 'refreshAndClose', 'updateAndClose'],
    props: ['defaultItem', 'editedItem', 'editedIndex', 'session', 'google_appointments', 'dialog', 'appointments'],
    components: { },

    data() {
        return {
            deleting_appointment: false,
            valid: true,
            dateRule: [
                v => !!v || 'Datum wird benötigt',
                v => /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(\d{4})$/.test(v) || 'Datum muss das Format TT.MM.JJJJ haben.',
            ],
            timeRule: [
                v => !!v || 'Uhrzeit wird benötigt',
                v => /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9](?::\d{2})?$/.test(v) || 'Uhrzeit muss das Format HH:MM haben.',
            ],
            clientRule: [
                v => !!v || 'Bitte wähle einen Klienten aus.',
            ],
            serviceRule: [
                v => !!v || 'Bitte wähle eine Dienstleistung aus.',
            ],
            amountRule: [
                v => v !== null && v !== undefined && v !== '' || 'Bitte gib einen Preis ein. Der Preis kann auch 0 sein.',
            ],
            durationRule: [
                v => v !== null && v !== undefined && v !== '' || 'Bitte gib eine Dauer ein. Die Dauer kann auch 0 sein.',
            ],
            service: null,
            n_customers: null,
            customers: [],
            services: [],
            loaded_services: false,

            gapi: null,
            gapi_inited: false,

            inserting_entry: false,
            dialog_delete: false,

            appointments_to_insert: [
                {
                    id: 1,
                    appointment: Object.assign({}, this.editedItem),
                    menu: false,
                }
            ],
        }
    },

    mounted() {
        this.gapi = window.gapi
        // check if gapi client is already loaded
        if (this.gapi.client) {
            // already loaded
            this.gapi_inited = true
            //this.initialize()
        } else {
            this.gapi.load('client', this.initializeGapiClient)
        }

        // set default termin_erinnerung to true if the  klient has termin_erinnerung set to true
        // but only if we are creating a new appointment, if we are editing an appointment, keep the value as it is
        if (this.editedItem.klient_termin_erinnerung && this.editedItem.termin_erinnerung === null && this.editedIndex === -1) {
            this.editedItem.termin_erinnerung = true;
        }

        connector.getDataOnly(this, 'vwklienten', 'id', true)
            .then((customers) => this.checkReturnValueOfCustomers(customers))

        connector.getDataOnly(this, 'vwdienstleistungen', 'id', true)
            .then((services) => {
                this.services = services
                this.loaded_services = true
                // for some reason the dialog watch is not triggered on the first load / opening, therefore set appointments here, as this is called on first dialog open
                // check if editing item, if yes and fk_dienstleistung is set, set service
                if (this.editedItem.fk_dienstleistung) {
                    this.service = this.services.find((service) => service.id === this.editedItem.fk_dienstleistung);
                } else {
                    // set the first service as default
                    if (this.services.length > 0) {
                        this.service = this.services[0]

                        this.editedItem.preis = this.service.preis
                        this.editedItem.dauer = this.service.dauer
                        this.editedItem.bezeichnung = this.service.bezeichnung
                        this.editedItem.ust_befreiung = this.service.ust_befreiung
                        this.editedItem.fk_dienstleistung = this.service.id
                    }
                }

                this.$nextTick(() => {
                    this.$refs.form.validate(true);
                })
            })
    },

    watch: {
        dialog() {
            if (this.dialog) {
                // dialog just opened, therefore set appointments again
                this.appointments_to_insert = [
                    {
                        id: 1,
                        appointment: Object.assign({}, this.editedItem),
                        menu: false,
                    }
                ]
                // check if editing item, if yes and fk_dienstleistung is set, set service
                if (this.editedItem.fk_dienstleistung) {
                    this.service = this.services.find((service) => service.id === this.editedItem.fk_dienstleistung);
                } else {
                    // set the first service as default
                    if (this.services.length > 0) {
                        this.service = this.services[0]

                        this.editedItem.preis = this.service.preis
                        this.editedItem.dauer = this.service.dauer
                        this.editedItem.bezeichnung = this.service.bezeichnung
                        this.editedItem.ust_befreiung = this.service.ust_befreiung
                        this.editedItem.fk_dienstleistung = this.service.id
                    }
                }
                // set the input fields to dirty, so that the validation is triggered
                this.$nextTick(() => {
                    this.$refs.form.validate(true);
                })
            }
        },

        // watch if selected customer is changed
        'editedItem.selected': {
            handler(){

                if (this.editedItem.selected) {
                    
                    // if we are creating a new appointment and the selected has termin_erinnerung set, set it to true as default
                    if (this.editedIndex === -1 && this.editedItem.selected.termin_erinnerung) {
                        this.editedItem.termin_erinnerung = true
                    } else if (this.editedIndex === -1) {
                        this.editedItem.termin_erinnerung = null
                    }
                }

                // when creating new appointment, check if selected customer is changed, if so, update service to the last service of the customer
                if (this.editedItem.selected && this.services.length > 0 && this.editedIndex === -1) {
                    // check if the client already had some appointments, if yes, set the serive to the service of his last appointment
                    if (this.appointments.some((appointment) => appointment.fk_klienten_id === this.editedItem.selected.fk_klienten_id)) {
                        let last_appointment = this.appointments.filter((appointment) => appointment.fk_klienten_id === this.editedItem.selected.fk_klienten_id).sort((a, b) => dayjs(b.datum) - dayjs(a.datum))[0]
                        this.service = this.services.find((service) => service.id === last_appointment.fk_dienstleistung);
                        if (!this.service) {
                            // if for some reason, the services is not found, set to the first one.
                            // this could probably happen in old cases, where there were no services.
                            this.service = this.services[0];
                        }

                        this.editedItem.preis = this.service.preis
                        this.editedItem.dauer = this.service.dauer
                        this.editedItem.bezeichnung = this.service.bezeichnung
                        this.editedItem.ust_befreiung = this.service.ust_befreiung
                        this.editedItem.fk_dienstleistung = this.service.id

                    } else {
                        // the client has no appointments yet, therefore set the first service as default
                        this.service = this.services[0]

                        this.editedItem.preis = this.service.preis
                        this.editedItem.dauer = this.service.dauer
                        this.editedItem.bezeichnung = this.service.bezeichnung
                        this.editedItem.ust_befreiung = this.service.ust_befreiung
                        this.editedItem.fk_dienstleistung = this.service.id
                    }
                }
            },
            deep: true
        }
        
    },

    computed: {

      formTitle () {
        return this.editedIndex === -1 ? 'Neuer Termin' : 'Termin bearbeiten'
      },

      sortedCustomers() {
        if (this.editedIndex === -1) {
            // if creating new appointment, show only active clients
            return this.customers.filter((customer) => !customer.archiviert).sort(this.sortListOfObjectsByString)
        } else {
            // since we are editing an appointment, also show the selected client (as he may be archived)
            return this.customers.filter((customer) => (!customer.archiviert || customer.fk_klienten_id === this.editedItem.fk_klienten_id)).sort(this.sortListOfObjectsByString)
        }
      },

      loadedCustomers() {
        return this.n_customers === this.customers.length
      },

      showSaveButton() {
        return (this.$store.state.client.google_calendar === null || this.gapi_inited === true) && this.valid
      },

      hasReachedAppointmentsLimit() {
        // check subscription

        if (this.$store.getters.subscriptionTier === "VOLLZEIT") {
            return false
        }

        let appointment_limit = 10;

        if (this.$store.getters.subscriptionTier === "TEILZEIT") {
            appointment_limit = 30;
        }

        let existing_month_counts = {};

        let existing_appointments = this.appointments.map((appointment) => {
            // just return the date of the appointment stored in datum using dayjs
            let date = dayjs(appointment.datum)
            let month_key = date.format('YYYY-MM')

            if (!existing_month_counts[month_key]) {
                existing_month_counts[month_key] = 1
            } else {
                existing_month_counts[month_key]++
            }
            return date
        });

        let new_appointments = this.appointments_to_insert.map((appointment) => {
            let toInsert = JSON.parse(JSON.stringify(appointment.appointment))
            return toInsert
        });

        // create an object to keep count of appointments per month for existing appointments
        // check if adding new appointments will exceed the limit
        for (let idx in new_appointments) {
            
            let date = dayjs(new_appointments[idx].datum)
            let month_key = date.format('YYYY-MM')

            // create or increment the count for this month
            if (!existing_month_counts[month_key]) {
                existing_month_counts[month_key] = 1
            } else {
                existing_month_counts[month_key]++
            }

            // check if the count for this month exceeds the limit
            if (existing_month_counts[month_key] > appointment_limit) {
                return true;
            }
        };

        return false;
      },
    },

    methods: {

        changedService(event) {
            if(event) {
                let service = this.services.find((service) => service.id === event.id);
                this.editedItem.preis = service.preis;
                this.editedItem.dauer = service.dauer;
                this.editedItem.bezeichnung = service.bezeichnung;
                this.editedItem.ust_befreiung = service.ust_befreiung;
                this.editedItem.fk_dienstleistung = service.id;
            }
        },

        updateAndClose(value, index) {

            // check if date is properly formatted using dayjs (it comes from the calender click, therefore this format)
            if (!dayjs(value, 'YYYY-MM-DD').isValid()) {
                return;
            }    

            let date = dayjs(value, 'YYYY-MM-DD').format('YYYY-MM-DD');

            if (index !== null) {
                this.appointments_to_insert[index].appointment.datum = date;
                this.appointments_to_insert[index].appointment.datumFormatted = dayjs(value, 'YYYY-MM-DD').format('DD.MM.YYYY');
            } else {
                this.editedItem.datum = date;
                this.editedItem.datumFormatted = dayjs(value, 'YYYY-MM-DD').format('DD.MM.YYYY');
            }
        },

        updateDate(value, index) {
            // check if date is properly formatted using dayjs
            if (!dayjs(value, 'DD.MM.YYYY').isValid()) {
                return;
            }        

            let date = dayjs(value, 'DD.MM.YYYY').format('YYYY-MM-DD');

            if (index !== null) {
                this.appointments_to_insert[index].appointment.datum = date;
                this.appointments_to_insert[index].appointment.datumFormatted = dayjs(value, 'DD.MM.YYYY').format('DD.MM.YYYY');
            } else {
                this.editedItem.datum = date;
                this.editedItem.datumFormatted = dayjs(value, 'DD.MM.YYYY').format('DD.MM.YYYY');
            }

        },

        async initializeGapiClient() {
            await this.gapi.client.init({
                apiKey: "AIzaSyBVojXCiR8LbVSlFt94GU0-O_yShuQpRTs",
                discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
            })
            this.gapi.client.setToken({access_token: this.session.provider_token})
            this.gapi_inited = true
            //this.initialize()
        },

        sortListOfObjectsByString(a, b) {
            const nameA = a.name.toUpperCase() // ignore upper and lowercase
            const nameB = b.name.toUpperCase() // ignore upper and lowercase
            if (nameA > nameB) {
            return 1
            }
            if (nameA < nameB) {
            return -1
            }
            // names must be equal
            return 0
        },

        checkReturnValueOfCustomers(customers) {
            if (customers === -1) {
            // wrong key
                this.customers = []
                this.n_customers = 0
            }
            else {
                this.customers = []
                this.n_customers = customers.length
                customers.map((obj) => cipher.decryptObject(this, this.$store.state.aes_key, obj).then((dec) => {
                    this.customers.push({
                        vorname: dec.vorname,
                        nachname: dec.nachname,
                        name: dec.nachname + ' ' + dec.vorname, 
                        fk_klienten_id: dec.id, 
                        merkmale: dec.merkmale,
                        archiviert: dec.archiviert,
                        warteliste: dec.warteliste,
                        termin_erinnerung: dec.termin_erinnerung,
                    })
                }))
            }
        },

        addAppointmentRow() {
            this.appointments_to_insert.push(
                {
                    id: this.appointments_to_insert.length + 1,
                    appointment: Object.assign({}, this.defaultItem),
                    menu: false,
                }
            )
        },

        removeAppointmentRow() {
            this.appointments_to_insert.pop()
        },

        abort() {
            this.appointments_to_insert = [
                {
                    id: 1,
                    appointment: Object.assign({}, this.defaultItem),
                    menu: false,
                }
            ]
            this.$emit('close')
        },

        deleteItem() {
            if (this.editedItem.fk_rechnungs_id) {
                this.$emit('showError', {'message': 'Der Termin kann nicht gelöscht werden, da er bereits verrechnet ist.'})
            return
            }
            this.dialog_delete = true
        },

        async deleteItemConfirm () {

            this.deleting_appointment = true;

            let deleted_files = true;
            // delete files from the corresponding bucket (if any)
            if (this.session.user.id && this.editedIndex && this.editedItem.selected.fk_klienten_id) {
                let client_id = this.editedItem.selected.fk_klienten_id;
                let path = this.session.user.id + '/' + client_id + '/' + this.editedIndex + '/';
                deleted_files = await connector.deleteAllFilesInBucket(this, 'documentation', path);
                if (!deleted_files) {
                    // error has already been shown
                    // stop deleting
                    this.deleting_appointment = false;
                    return;
                }
            }

            let deleted = await connector.delete(this, 'termine', 'id', this.editedIndex);
            if (!deleted) {
                // error has already been shown
                this.deleting_appointment = false;
                return;
            };

            // check if google calendar is connected and if yes, also delete the appointment from the google calendar
            if (this.$store.state.client.google_calendar) {
                try {

                    // find the event with the same id
                    let google_appointment = this.google_appointments.find((appointment) => appointment.id === this.editedIndex);

                    if (google_appointment) {
                        await this.gapi.client.calendar.events.delete({
                            'calendarId': this.$store.state.client.google_calendar,
                            'eventId': google_appointment.eventId,
                        });
                    } else {
                        console.log('No google appointment found for id: ', this.editedIndex);
                    }
                } catch (err) {
                    console.log('Error during deleting calendar event: ', err);
                }
            }

            this.deleting_appointment = false;
            this.dialog_delete = false;
            this.$emit('refreshAndClose', false);

            if (deleted && deleted_files) {
                this.$emit('showInfo', {
                    message: 'Der Termin wurde erfolgreich gelöscht.',
                    timeout: 5000
                });
            }
        },

        async save () {
            this.inserting_entry = true;

            // check if editedItem.selected is not null and if it is an object with the keys fk_klienten_id and name
            // if not, show error that the user should select a Klient from the dropdown.
            // this check is needed if someone just enters a string and does not select a Klient from the dropdown.

            if (this.editedItem.selected
                && (typeof this.editedItem.selected !== 'object' 
                || !('fk_klienten_id' in this.editedItem.selected && 'name' in this.editedItem.selected))) {
                this.$emit('showError', {message: 'Bitte wähle einen Klienten aus der Liste deiner Klienten aus.'})
                this.inserting_entry = false
                return
            }

            // check if fk_klienten_id is not null
            if (!this.editedItem.selected || this.editedItem.selected.fk_klienten_id === null) {
                this.$emit('showError', {message: 'Bitte wähle einen Klienten aus.'})
                this.inserting_entry = false
                return
            }

            // check if service is set
            if (!this.service) {
                this.$emit('showError', {message: 'Bitte wähle eine Dienstleistung aus.'})
                this.inserting_entry = false
                return
            }

            // check if preis is set
            if (this.editedItem.preis === null || this.editedItem.preis === '' || this.editedItem.preis === undefined) {
                this.$emit('showError', {
                    message: 'Bitte gib einen Preis ein. Der Preis kann auch 0 sein.',
                    timeout: 5000
                })
                this.inserting_entry = false
                return
            }

            // check if dauer is set
            if (this.editedItem.dauer === null || this.editedItem.dauer === '' || this.editedItem.dauer === undefined) {
                this.$emit('showError', {
                    message: 'Bitte gib eine Dauer ein. Die Dauer kann auch 0 sein.',
                    timeout: 5000
                })
                this.inserting_entry = false
                return
            }

            if(this.hasReachedAppointmentsLimit) {

                // check if the user has already a TEILZEIT subscription
                if (this.$store.getters.getTrueSubscriptionTier === 'TEILZEIT') {

                    this.$emit('showError', {
                        message: 'Du hast diesen Monat keine freien Termine mehr. Mit dem Vollzeit Abo kannst du unbegrenzt Termine anlegen.',
                        additional_button: {
                            text: 'Abo Verwalten',
                            target: 'manage-subscription'
                        }
                    })
                    this.inserting_entry = false;
                    return;

                } else {
                    this.$emit('showError', {
                        message: 'Du hast diesen Monat keine freien Termine mehr. Mit dem Teilzeit oder Vollzeit Abo kannst du weitere Termine anlegen.',
                        additional_button: {
                            text: 'Abos Anzeigen',
                            target: '/upgrade'
                        }
                    })
                    this.inserting_entry = false;
                    return;
                }
            }

            if (this.editedIndex > -1) {

                // check if the appointment is already verrechnet, if so do not allow to edit
                if (this.editedItem.fk_rechnungs_id) {
                    this.$emit('showError', {
                        message: 'Der Termin kann nicht bearbeitet werden, da er bereits verrechnet ist. Lösche zuerst die entsprechende Rechnung.',
                        timeout: 10000
                    });
                    this.inserting_entry = false;
                    return;
                }

                /*
                    uid uuid not null,
                    inserted_at timestamp without time zone not null default now(),
                    updated_at timestamp without time zone not null default now(),
                    fk_klienten_id bigint not null,
                    fk_rechnungs_id bigint null,
                    datum timestamp without time zone not null,
                    preis double precision not null,
                    dauer double precision null,
                    bezeichnung text null,
                    ust_befreiung integer null,
                    fk_dienstleistung bigint null,
                    dokumentation bytea null,
                    erinnerung_gesendet boolean null,
                */

                let toUpdate = {
                    fk_klienten_id: this.editedItem.selected.fk_klienten_id,
                    datum: this.editedItem.datum.split('T')[0] + 'T' + this.editedItem.uhrzeit,
                    preis: this.editedItem.preis,
                    dauer: this.editedItem.dauer,
                    bezeichnung: this.editedItem.bezeichnung,
                    ust_befreiung: this.editedItem.ust_befreiung,
                    fk_dienstleistung: this.editedItem.fk_dienstleistung,
                    termin_erinnerung: this.editedItem.termin_erinnerung,
                }

                // check if the fk_klienten_id has changed, if yes, check if there are already files in the corresponding bucket
                // if so, do not allow to edit
                if (this.editedItem.selected.fk_klienten_id !== this.editedItem.fk_klienten_id) {
                    let path = this.session.user.id + '/' + this.editedItem.fk_klienten_id + '/' + this.editedItem.id + '/';
                    let has_files = await connector.checkIfFilesExistInBucket(this, 'documentation', path);
                    if (has_files === null) {
                        // error has already been shown
                        this.inserting_entry = false;
                        return;
                    } else if (has_files) {
                        this.$emit('showError', {
                            'message': 'Der Klient wurde geändert, aber es sind bereits Dateien in der Dokumentation für diesen Termin vorhanden. Lösche zuerst die Dateien, bevor du den Klienten änderst.'
                        });
                        this.inserting_entry = false;
                        return;
                    }
                }

                try {
                    let updated = await connector.update(this, 'termine', toUpdate, this.editedItem.id);
                    if (updated === null) {
                        // error has already been shown
                        this.inserting_entry = false;
                        return;
                    }

                    // check if google calendar is connected, and if yes, update the event in the calendar
                    if (this.$store.state.client.google_calendar) {
                        await this.updateGoogleCalendarEvent(toUpdate, this.editedIndex);
                    } 

                } catch (error) {
                    this.$emit('showError', {
                        message: 'Es ist ein Fehler aufgetreten, bitte versuche es erneut.',
                        error: error
                    });
                } finally {
                    let refreshAll = this.editedItem.selected.fk_klienten_id !== this.editedItem.fk_klienten_id;
                    this.finalizeAndClose(refreshAll, toUpdate);
                }

            } else {
                try {
                    // create new entry
                    let toInsert = this.appointments_to_insert.map((appointment) => {
                        /*
                            uid uuid not null,
                            inserted_at timestamp without time zone not null default now(),
                            updated_at timestamp without time zone not null default now(),
                            fk_klienten_id bigint not null,
                            fk_rechnungs_id bigint null,
                            datum timestamp without time zone not null,
                            preis double precision not null,
                            dauer double precision null,
                            bezeichnung text null,
                            ust_befreiung integer null,
                            fk_dienstleistung bigint null,
                            dokumentation bytea null,
                            termin_erinnerung boolean null,
                            erinnerung_gesendet boolean null,
                        */

                        return {
                            uid: this.session.user.id,
                            fk_klienten_id: this.editedItem.selected.fk_klienten_id,
                            //fk_rechnungs_id: null, // not set here, as this is a new entry
                            datum: appointment.appointment.datum.split('T')[0] + 'T' + appointment.appointment.uhrzeit,
                            preis: this.editedItem.preis,
                            dauer: this.editedItem.dauer,
                            bezeichnung: this.editedItem.bezeichnung,
                            ust_befreiung: this.editedItem.ust_befreiung,
                            fk_dienstleistung: this.service.id,
                            termin_erinnerung: this.editedItem.termin_erinnerung,
                            //document: null, // not set here, as this is a new entry
                            //erinnerung_gesendet: null, // not set here, as this is a new entry
                        }
                    });

                    let entries = await connector.inserts(this, 'termine', toInsert);
                    if (entries === null) {
                        // errors has already been shown
                        this.inserting_entry = false;
                        return;
                    }

                    // check if google calendar is connected, and if yes, insert the event in the calendar
                    if (this.$store.state.client.google_calendar) {
                        let inserted_entries = await this.addToGoogleCalendar(entries);
                    }
                } catch (error) {
                    this.$emit('showError', {
                        message: 'Es ist ein Fehler aufgetreten, bitte versuche es erneut.',
                        error: error
                    });
                } finally {
                    this.finalizeAndClose(true);
                }
            }
        },

        async addToGoogleCalendar(entries) {
            try {
                let events = entries.map((event) => {
                    let client_name = this.getClientName(event.fk_klienten_id);
                    return {
                        'calendarId': this.$store.state.client.google_calendar,
                        'start': {
                            'dateTime': dayjs(event.datum).format('YYYY-MM-DDTHH:mm:ss'),
                            'timeZone': 'Europe/Berlin'
                        },
                        'end': {
                            'dateTime': dayjs(event.datum).add(event.dauer, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
                            'timeZone': 'Europe/Berlin'
                        },
                        'summary': client_name,
                        'description': 'https://app.zeipsy.com/termine/?id=' + event.id,
                    }
                });

                const batch = this.gapi.client.newBatch();
                events.forEach((event) => {
                    batch.add(this.gapi.client.calendar.events.insert(event));
                });

                return new Promise((resolve, reject) => {
                    batch.execute((responseMap, rawBatchResponse) => {
                        resolve(Object.entries(responseMap));
                    });
                });
            } catch (error) {
                this.$emit('showError', {
                    message: (entries.length > 1 ? 'Die Einträge wurden ' : 'Der Eintrag wurde ') + 'erfolgreich gespeichert, konnte jedoch nicht zum Google-Kalender hinzugefügt werden.',
                    timeout: 10000,
                    error: error
                });
                return [];
            }
        },

        async updateGoogleCalendarEvent(toUpdate, id) {
            let google_appointment = this.google_appointments.find((appointment) => appointment.id === id);
            
            if (!google_appointment) {
                console.log('No Google appointment found for id', id);
                return;
            }

            try {
                let client_name = this.getClientName(this.editedItem.selected.fk_klienten_id);

                const request = {
                    'calendarId': this.$store.state.client.google_calendar,
                    'eventId': google_appointment.eventId,
                    'start': {
                        'dateTime': dayjs(toUpdate.datum).format('YYYY-MM-DDTHH:mm:ss'),
                        'timeZone': 'Europe/Berlin'
                    },
                    'end': {
                        'dateTime': dayjs(toUpdate.datum).add(toUpdate.dauer, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
                        'timeZone': 'Europe/Berlin'
                    },
                    'summary': client_name,
                    'description': 'https://app.zeipsy.com/termine/?id=' + id,
                };

                await this.gapi.client.calendar.events.update(request);
            } catch (err) {
                console.error('Error updating Google Calendar event:', err);
                if (typeof err === 'string') {
                    connector.logError(this, err);
                } else {
                    connector.logError(this, JSON.stringify(err));
                }
            }
        },

        getClientName(fk_klienten_id) {
            let client_name = 'Klienten-Termin';
            if (fk_klienten_id) {
                let client = this.customers.find((client) => client.fk_klienten_id === fk_klienten_id);
                if (client) {
                    if (this.$store.state.client.google_calendar_anonymize) {
                        if (this.$store.state.client.google_calendar_anonymization_format) {
                            client_name = client.vorname.slice(0, 2) + client.nachname.slice(0, 2);
                        } else {
                            client_name = client.nachname.slice(0, 2) + client.vorname.slice(0, 2);
                        }
                    } else {
                        client_name = client.name;
                    }
                }
            }
            return client_name;
        },

        finalizeAndClose(refreshAll, toInsert = null) {
            this.appointments_to_insert = [
                {
                    id: 1,
                    appointment: Object.assign({}, this.defaultItem),
                    menu: false,
                }
            ];
            this.inserting_entry = false;
            if (refreshAll) {
                // refresh all appointments, as the fk_klienten_id has changed (as we need to load the names of the clients)
                this.$emit('refreshAndClose', false);
            } else {
                this.$emit('updateAndClose', toInsert, this.editedIndex);
            }
        }

    }
}
</script>

<style scoped>
.v-sheet.v-card {
  border-radius: 6px;
}
</style>