<template>
    <div>
        <AntragDialogs 
            :dialog_google_terms.sync="dialog_google_terms"
            :dialog_save_form.sync="dialog_save_form"
            :dialog_prompt_download.sync="dialog_prompt_download"
            :session="session"
            :formData="formData"
            :client="client"
            :file_name.sync="file_name"
            :saving_form.sync="saving_form"
            @close-dialog="closeDialog"
            @save-form="saveForm"
            @download-form="downloadForm"
        />
        <v-dialog v-model="dialog" persistent fullscreen no-click-animation>
            <v-card color="grey lighten-4">
                <v-toolbar dark :color="$store.state.theme.primary">
                    <v-btn icon dark @click="dialog_prompt_download = true">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Antrag auf Kostenzuschuss</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="!client">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <v-btn outlined disabled class="mr-5" elevation="0" v-bind="attrs" v-on="on" @click="showSaveDialog">
                                    <v-icon left>mdi-content-save</v-icon>
                                    Speichern
                                </v-btn>
                            </div>
                        </template>
                        <span>Wähle einen Klienten aus um den Antrag zu speichern</span>
                    </v-tooltip>
                    <v-btn v-else outlined class="mr-5" elevation="0" @click="showSaveDialog">
                        <v-icon left>mdi-content-save</v-icon>
                        Speichern
                    </v-btn>
                    <v-btn :color="$store.state.theme.green" dark elevation="1" @click="downloadForm">
                        <v-icon left>mdi-printer</v-icon>
                        Herunterladen
                    </v-btn>
                </v-toolbar>
                <v-tabs grow v-model="current_tab" class="mt-3" :background-color="$store.state.theme.background">
                    <v-tab>
                        <v-icon left>mdi-information</v-icon>
                        Antragsinformationen
                    </v-tab>
                    <v-tab>
                        <v-icon left>mdi-folder-information</v-icon>
                        Behandlungsinformationen
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="current_tab" style="background-color: #f5f5f5">
                    <v-tab-item eager>
                        <v-row class="px-5 py-5">
                            <v-col cols="6">
                                <v-card>
                                    <v-card-title class="d-flex justify-space-between">
                                        Daten der versicherten Person
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="d-flex align-center">
                                            <v-autocomplete v-model="client"
                                                :loading="!loaded_customers || loading_client_data" loader-height="3"
                                                class="" :disabled="!loaded_customers" :items="sortedCustomers"
                                                item-text="name" item-value="fk_klienten_id" return-object
                                                label="Klient" outlined dense clear-icon="mdi-close-circle" clearable
                                                prepend-inner-icon="mdi-account">
                                                <template v-slot:item="{ item }">
                                                    <div class="d-flex align-center">
                                                        <span>{{ item.name }}</span>
                                                        <v-chip v-for="merkmal in item.merkmale"
                                                            :key="'chip-' + merkmal.id" class="ml-2" small>{{
                                                            merkmal.merkmal }}</v-chip>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <div class="d-flex align-center">
                                                        <span>{{ item.name }}</span>
                                                        <v-chip v-for="merkmal in item.merkmale"
                                                            :key="'chip-selection-' + merkmal.id" class="ml-2" small>{{
                                                            merkmal.merkmal }}</v-chip>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                            <v-checkbox v-model="formData.self_insured" label="Patient/in ist selbstversichert" class="mt-0 pt-0 ml-5"></v-checkbox>
                                        </div>
                                        <div class="d-flex">
                                            <v-text-field class="mr-2" outlined dense label="Familienname Patient/in" v-model="formData.lastname" />
                                            <v-text-field class="mx-2" outlined dense label="Vorname Patient/in" v-model="formData.firstname" />
                                            <v-text-field class="ml-2" outlined dense label="Versicherungsnr. Patient/in" v-model="formData.svnr" />
                                        </div>
                                        <v-textarea rows="2" auto-grow outlined dense label="Anschrift Patient/in" v-model="formData.address" />
                                        <div v-if="formData.self_insured === false" class="d-flex">
                                            <v-text-field class="mr-2" outlined dense label="Versicherte/r" v-model="formData.insurer_name" />
                                            <v-text-field class="ml-2" outlined dense label="Versicherungsnr. Versicherte/r" v-model="formData.insurer_svnr" />
                                        </div>
                                        <v-text-field class="mb-6" outlined dense label="Beschäftigt bei" v-model="formData.job" hide-details />

                                        <v-autocomplete v-model="selected_insurer" :items="insurer"
                                            item-text="displayName" item-value="id" label="Versicherungsträger"
                                            return-object outlined dense />
                                        <div class="d-flex">
                                            <v-text-field class="mr-2" outlined dense label="Versicherungsträger" v-model="formData.insurance" hide-details />
                                            <v-text-field class="mx-2" outlined dense label="Bundesland" v-model="formData.insurance_state" hide-details />
                                            <v-text-field class="mx-2" outlined dense label="Straße & Nr." v-model="formData.insurance_street" hide-details />
                                            <v-text-field class="ml-2" outlined dense label="PLZ & Ort" v-model="formData.insurance_place" hide-details />
                                        </div>
                                    </v-card-text>
                                </v-card>
                                <v-card class="mt-5" v-if="selected_insurer && selected_insurer.name === 'SVS'">
                                    <v-card-title>
                                        Antragsinformationen
                                    </v-card-title>
                                    <v-card-text>
                                        <span class="text-body-1">
                                            Ich beantrage die
                                        </span>
                                        <v-radio-group v-model="formData.application_form" column class="d-flex align-center mt-0 ">
                                            <v-radio label="Grundsätzliche Gewährung von Kostenzuschüssen für die Weiterführung der Therapie lt. umseitiger Angaben unter Vorlage von Honorarnoten" value="0"></v-radio>
                                            <v-radio label="Bewilligung für die Weiterführung der Therapie lt. umseitiger Angaben ohne Vorlage von Honorarnoten" value="1"></v-radio>
                                        </v-radio-group>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="6" ref="iframe-pdf-1">
                                <v-card full-width class="d-flex flex-column align-center justify-center" rounded>
                                    <span class="text-h6">Vorschau</span>
                                    <span class="text-caption">(bitte keine Änderungen direkt in der Vorschau vornehmen)</span>
                                    <iframe v-if="form_url" :key="form_url" :src="form_url + '#toolbar=0'" @load="onIframeLoad(1)" :style="{
                                        width: '100%',
                                        height: `${scaledHeight}px`,
                                        border: 'none',
                                    }">
                                    </iframe>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>

                    <v-tab-item eager>
                        <v-row class="px-5 py-5">
                            <v-col cols="6">
                                <v-card>
                                    <v-card-title class="d-flex justify-space-between">
                                        Behandelnde/r Psychotherapeut/in
                                    </v-card-title>
                                    <v-card-text>
                                        <v-textarea rows="4" auto-grow outlined dense v-model="formData.therapist" hide-details />
                                    </v-card-text>
                                </v-card>

                                <v-card class="mt-5">
                                    <v-card-title class="text-h6 d-flex">
                                        1. Behandelte Störungen
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="d-flex mb-5">
                                            <v-autocomplete outlined v-model="formData.icd_diagnosis" :items="icdCodes"
                                                hide-details item-text="code" item-value="short_code"
                                                :dense="formData.icd_diagnosis.length === 0" label="Diagnose nach ICD-10"
                                                placeholder="Suchbegriff eingeben" return-object multiple
                                                deletable-chips chips :menu-props="{ maxWidth: 750 }">
                                            </v-autocomplete>
                                        </div>
                                        <p class="text-subtitle-2">Vorschläge:</p>
                                        <v-chip v-if="suggestedDiagnoses.length === 0" class="mr-2 mb-2"
                                            @click="selectDiagnosis({ 'code': 'F32.1 Mittelgradige depressive Episode', 'short_code': 'F32.1' })">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>F32.1 Mittelgradige depressive Episode</span>
                                        </v-chip>
                                        <v-chip v-if="suggestedDiagnoses.length === 0" class="mr-2 mb-2"
                                            @click="selectDiagnosis({ 'code': 'F43.2 Anpassungsstörungen', 'short_code': 'F43.2' })">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>F43.2 Anpassungsstörungen</span>
                                        </v-chip>
                                        <v-chip outlined color="deep-purple accent-4" v-for="(diagnosis, index) in suggestedDiagnoses" :key="index" class="mt-0 pt-0 mr-2 mb-2" @click="selectDiagnosis(diagnosis)">
                                            <v-icon left>mdi-plus</v-icon>
                                            {{ diagnosis.code }}
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="getRecommendations('getICDDiagnosisPT')" :disabled="loading_getICDDiagnosisPT" color="success" outlined>
                                            <v-progress-circular v-if="loading_getICDDiagnosisPT" indeterminate size="15" width="3" class="mr-2" />
                                            <v-icon v-else left small>mdi-auto-fix</v-icon>
                                            <span class="font-weight-medium">Andere Diagnosen vorschlagen</span>
                                        </v-chip>
                                    
                                        <p class="mt-5 text-h6 black--text">
                                            2. Angaben zur Intensität der Störung
                                        </p>
                                        <v-textarea rows="3" auto-grow outlined dense hide-details v-model="formData.field_1"
                                            placeholder="Hier die Angaben zur Intensität der Störung angeben..." />
                                        <p class="mt-2 text-subtitle-2">Vorschläge:</p>
                                        <v-chip class="mr-2 mb-2"
                                            @click="addText('field_1', 'Hoher Leidensdruck')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Hoher Leidensdruck</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="addText('field_1', 'Wiederkehrende Symptomatik')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Wiederkehrende Symptomatik</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="addText('field_1', 'Starke Beeinträchtigung im Alltag')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Starke Beeinträchtigung im Alltag</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="addText('field_1', 'Schwerwiegende emotionale Belastung')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Schwerwiegende emotionale Belastung</span>
                                        </v-chip>
                                        <v-chip v-for="(item, id) in field_1_suggestions" :key="'field-1-' + id"
                                            @click="addText('field_1', item)" class="mr-2 mb-2"
                                            color="deep-purple accent-4" outlined>
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>{{ item }}</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="getRecommendations('getDisorderIntensityPT')"
                                            :disabled="loading_getDisorderIntensityPT" color="success" outlined>
                                            <v-progress-circular v-if="loading_getDisorderIntensityPT" indeterminate size="15"
                                                width="3" class="mr-2" />
                                            <v-icon v-else left small>mdi-auto-fix</v-icon>
                                            <span class="font-weight-medium">{{ field_1_suggestions.length === 0 ?
                                                'Weitere' : 'Andere' }} Textbausteine vorschlagen</span>
                                        </v-chip>

                                        <p class="mt-5 text-h6 black--text">
                                            3. Zusammenfassende Einschätzung des Krankheitsverlaufes
                                        </p>
                                        <v-textarea rows="3" auto-grow outlined dense hide-details v-model="formData.field_2"
                                            placeholder="Hier die zusammenfassende Einschätzung des Krankheitsverlaufes angeben..." />
                                        <p class="mt-2 text-subtitle-2">Vorschläge:</p>
                                        <v-chip class="mr-2 mb-2"
                                            @click="addText('field_2', 'Gute Arbeitsbeziehung')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Gute Arbeitsbeziehung</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="addText('field_2', 'Erhöhte Belastbarkeit')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Erhöhte Belastbarkeit</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2"
                                            @click="addText('field_2', 'Ziele definiert')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Ziele definiert</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="addText('field_2', 'Zunehmende Selbstreflexion')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Zunehmende Selbstreflexion</span>
                                        </v-chip>
                                        <v-chip v-for="(item, id) in field_2_suggestions" :key="'field-2-' + id"
                                            @click="addText('field_2', item)" class="mr-2 mb-2"
                                            color="deep-purple accent-4" outlined>
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>{{ item }}</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="getRecommendations('getProgressPT')"
                                            :disabled="loading_getProgressPT" color="success" outlined>
                                            <v-progress-circular v-if="loading_getProgressPT" indeterminate size="15"
                                                width="3" class="mr-2" />
                                            <v-icon v-else left small>mdi-auto-fix</v-icon>
                                            <span class="font-weight-medium">{{ field_2_suggestions.length === 0 ?
                                                'Weitere' : 'Andere' }} Textbausteine vorschlagen</span>
                                        </v-chip>

                                        <p class="mt-5 text-h6 black--text">
                                            4. Vorgesehene Methode
                                        </p>
                                        <div class="d-flex">
                                            <v-text-field outlined dense hide-details class="mr-5" v-model="formData.method" label="Methode" />
                                            <v-text-field outlined dense hide-details v-model="formData.method_note" label="Anmerkungen" />
                                        </div>
                                    
                                        <p class="mt-5 mb-0 text-h6 black--text">
                                            5. Vorgesehene Sitzungsform
                                        </p>
                                        <div class="d-flex align-center">
                                            <v-radio-group class="d-flex" v-model="formData.therapy_setting" row>
                                                <v-radio label="Einzelsetting" value="0"></v-radio>
                                                <v-radio label="Gruppensetting" value="1"></v-radio>
                                                <v-radio label="Anderes Setting" value="2"></v-radio>
                                            </v-radio-group>
                                            <v-text-field v-if="formData.therapy_setting === '2'" placeholder="Art des Settings"
                                                v-model="formData.therapy_setting_other_text" class="ml-2" outlined dense
                                                hide-details />
                                        </div>

                                        <v-text-field v-model="formData.therapy_units_other" outlined dense
                                                label="6a. Anzahl der Sitzungen mit Methode bei anderen Psychotherapeuten" />

                                        <div class="d-flex">
                                            <v-text-field class="mr-2" v-model="formData.therapy_units_past" outlined dense label="6b. Bisher stattgefundene Einheiten" />
                                            <v-text-field class="mx-2" v-model="formData.therapy_start" outlined dense label="6b. Beginn der Behandlung und in welcher Frequenz" />
                                            <v-text-field class="ml-2" v-model="formData.therapy_units" outlined dense label="6c. Notwendige weitere Sitzungen" />
                                        </div>
                                        <div class="d-flex">
                                            <v-text-field class="mr-2" v-model="formData.therapy_units_requested" outlined dense label="7. Beantragte Sitzungen" />
                                            <v-text-field class="ml-2" v-model="formData.therapy_frequency" outlined dense label="8. Geplante Frequenz" />
                                        </div>
                                        <v-text-field v-model="formData.notes" label="9. Anmerkungen" outlined dense />
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="6" ref="iframe-pdf-2">
                                <v-card full-width class="d-flex flex-column align-center justify-center" rounded>
                                    <span class="text-h6">Vorschau</span>
                                    <span class="text-caption">(bitte keine Änderungen direkt in der Vorschau
                                        vornehmen)</span>

                                    <iframe v-if="form_url" :key="form_url" :src="form_url + '#toolbar=0'" @load="onIframeLoad(2)" :style="{
                                        width: '100%',
                                        height: `${scaledHeight}px`,
                                        border: 'none',
                                    }">
                                    </iframe>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import AntragDialogs from "./AntragDialogs.vue";
import { FunctionsHttpError } from "@supabase/supabase-js";
import { supabase } from '../supabase';
import { PDFDocument, rgb, StandardFonts, PDFCheckBox, PDFTextField } from 'pdf-lib';
import { debounce } from 'lodash';
import { saveAs } from 'file-saver';
import connector from '../helpers/supabase-connector.js';
import cipher from '@/helpers/cipher';
import dayjs from 'dayjs';
import json from '../assets/icdCodes.json';

export default {

    components: {
        AntragDialogs
    },
    props: ['session', 'current_file'],

    data() {
        return {

            resizeHandler: null,
            iframeLoaded: false,
            clientWidth: 0,
            formWatchers: [],
            isMounted: true,  // Track if the component is still mounted

            isPrefilling: false,
            debouncedRenderPDF: debounce(this.renderPDF, 500),

            formData: {
                application_form: '0',
                insurance: '',
                insurance_state: '',
                insurance_street: '',
                insurance_place: '',
                self_insured: true,
                insurer_name: '',
                insurer_svnr: '',
                lastname: '',
                firstname: '',
                address: '',
                svnr: '',
                therapist: '',
                icd_diagnosis: [],
                job: '',
                field_1: '',
                field_2: '',
                method: '',
                method_note: '',
                therapy_setting: '0',
                therapy_start: '',
                therapy_units: '',
                therapy_units_past: '',
                therapy_units_other: '',
                therapy_units_requested: '',
                therapy_frequency: '',
                therapy_setting_other_text: '',
                notes: '',
            },

            formDataDefault: {}, 

            pdfDoc: null,
            pdfForm: null,
            pdfPages: [],
            pdfFont: null,

            // New mapping for non-SVS PDF (using existing form fields)
            nonSVSFieldMapping: {
                'topmostSubform[0].Page1[0].patient[0]': {
                    dependsOn: ['lastname', 'firstname'],
                    getValue: (component) => `${component.formData.lastname} ${component.formData.firstname}`,
                },
                'topmostSubform[0].Page1[0].vsnr[0]': (component) => component.formatVSNR(component.formData.svnr, 'vsnr'),
                'topmostSubform[0].Page1[0].tag[0]': (component) => component.formatVSNR(component.formData.svnr, 'tag'),
                'topmostSubform[0].Page1[0].monat[0]': (component) => component.formatVSNR(component.formData.svnr, 'monat'),
                'topmostSubform[0].Page1[0].jahr[0]': (component) => component.formatVSNR(component.formData.svnr, 'jahr'),
                'topmostSubform[0].Page1[0].vsnr1[0]': (component) => component.formData.self_insured ? '' : component.formatVSNR(component.formData.insurer_svnr, 'vsnr'),
                'topmostSubform[0].Page1[0].tag1[0]': (component) => component.formData.self_insured ? '' : component.formatVSNR(component.formData.insurer_svnr, 'tag'),
                'topmostSubform[0].Page1[0].monat1[0]': (component) => component.formData.self_insured ? '' : component.formatVSNR(component.formData.insurer_svnr, 'monat'),
                'topmostSubform[0].Page1[0].jahr1[0]': (component) => component.formData.self_insured ? '' : component.formatVSNR(component.formData.insurer_svnr, 'jahr'),
                'topmostSubform[0].Page1[0].anschrift[0]': (component) => component.formData.address.replace(/\n/g, ' '),
                'topmostSubform[0].Page1[0].dienstgeber[0]': 'job',
                'topmostSubform[0].Page1[0].versicherter[0]': (component) => component.formData.self_insured ? '' : `${component.formData.insurer_name}`,
                'topmostSubform[0].Page1[0].an_die[0]': (component) => component.selected_insurer ? `${component.formData.insurance}, ${component.formData.insurance_street}, ${component.formData.insurance_place}` : '',
                'topmostSubform[0].Page2[0].behandelnder_psychotherapeut[0]': 'therapist',
                'topmostSubform[0].Page2[0].welche_störungen[0]': (component) => component.formData.icd_diagnosis.map((diagnosis) => diagnosis.code).join(', '),
                'topmostSubform[0].Page2[0].kurze_stellungnahme[0]': 'field_1',
                'topmostSubform[0].Page2[0].zusammenfassende_einschätzung[0]': 'field_2',
                'topmostSubform[0].Page2[0].vorgesehene_methode[0]': 'method',
                'topmostSubform[0].Page2[0].anmerkungen[0]': 'method_note',
                'topmostSubform[0].Page2[0].kontrollkästchen4[0]': {
                    dependsOn: ['therapy_setting'],
                    getValue: (component) => component.formData.therapy_setting === '0',
                },
                'topmostSubform[0].Page2[0].kontrollkästchen5[0]': {
                    dependsOn: ['therapy_setting'],
                    getValue: (component) => component.formData.therapy_setting === '1',
                },
                'topmostSubform[0].Page2[0].kontrollkästchen6[0]': {
                    dependsOn: ['therapy_setting'],
                    getValue: (component) => component.formData.therapy_setting === '2',
                },
                'topmostSubform[0].Page2[0].sitzungsform[0]': 'therapy_setting_other_text',
                'topmostSubform[0].Page2[0].angabe_des_patienten[0]': 'therapy_units_other',
                'topmostSubform[0].Page2[0].wie_viele_sitzungen[0]': 'therapy_units_past',
                'topmostSubform[0].Page2[0].wie_viele_sitzungen_in_welcher_frequenz[0]': 'therapy_start',
                'topmostSubform[0].Page2[0].voraussichtlich_notwendig[0]': 'therapy_units',
                'topmostSubform[0].Page2[0].kostenzuschuss[0]': 'therapy_units_requested',
                'topmostSubform[0].Page2[0].sitzungsfrequenz[0]': 'therapy_frequency',
                'topmostSubform[0].Page2[0].anmerkungen1[0]': 'notes',
            },

            SVSFieldMapping: {
                'patient': {
                    dependsOn: ['lastname', 'firstname'],
                    getValue: (component) => `${component.formData.lastname} ${component.formData.firstname}`,
                },
                'svnr': 'svnr',
                'address': 'address',
                'insurer_name': (component) => component.formData.self_insured ? '' : `${component.formData.insurer_name}`,
                'insurer_svnr': (component) => component.formData.self_insured ? '' : `${component.formData.insurer_svnr}`,
                'job': 'job',
                'Erstantrag': {
                    dependsOn: ['application_form'],
                    getValue: (component) => component.formData.application_form === '0',
                },
                'Folgeantrag': {
                    dependsOn: ['application_form'],
                    getValue: (component) => component.formData.application_form === '1',
                },
                'therapist': 'therapist',
                'icd_diagnosis': (component) => component.formData.icd_diagnosis.map((diagnosis) => diagnosis.code).join(', '),
                'field_1': 'field_1',
                'field_2': 'field_2',
                'method': 'method',
                'method_note': 'method_note',
                'therapy_setting_single': {
                    dependsOn: ['therapy_setting'],
                    getValue: (component) => component.formData.therapy_setting === '0',
                },
                'therapy_setting_group': {
                    dependsOn: ['therapy_setting'],
                    getValue: (component) => component.formData.therapy_setting === '1',
                },
                'therapy_setting_other': {
                    dependsOn: ['therapy_setting'],
                    getValue: (component) => component.formData.therapy_setting === '2',
                },
                'therapy_setting_other_text': 'therapy_setting_other_text',
                'therapy_units_other': 'therapy_units_other',
                'therapy_units_past': 'therapy_units_past',
                'therapy_start': 'therapy_start',
                'therapy_units': 'therapy_units',
                'therapy_units_requested': 'therapy_units_requested',
                'therapy_frequency': 'therapy_frequency',
                'notes': 'notes',
            },

            pdfPageHeight: 0,
            pdfPageWidth: 0,
            windowWidth: window.innerWidth,

            dialog_prompt_download: false,
            dialog_save_form: false,
            file_name: null,
            saving_form: false,

            icdCodes: [],

            dialog_google_terms: false,

            loading_getICDDiagnosisPT: false,
            loading_getDisorderIntensityPT: false,
            loading_getProgressPT: false,

            n_customers: null,
            customers: [],
            client: null,

            current_tab: 0,
            loaded_customers: false,
            suggested_diagnosis: null,
            field_2_suggestions: [],
            field_1_suggestions: [],
            dialog: false,

            pdf_document: null,
            form_url: null,

            selected_insurer: null,

            // if update, also change in Klienten.vue
            // and also in the DB (versicherungen)
            insurer: [
                { "id": 1, "name": "ÖGK", "state": "Burgenland", "address": "Siegfried Marcus-Straße 5", "place": "7000 Eisenstadt", "displayName": "ÖGK (Burgenland)" },
                { "id": 2, "name": "ÖGK", "state": "Kärnten", "address": "Kempfstraße 8", "place": "9021 Klagenfurt am Wörthersee", "displayName": "ÖGK (Kärnten)" },
                { "id": 3, "name": "ÖGK", "state": "Niederösterreich", "address": "Kremser Landstraße 3", "place": "3100 St. Pölten", "displayName": "ÖGK (Niederösterreich)" },
                { "id": 4, "name": "ÖGK", "state": "Oberösterreich", "address": "Gruberstraße 77", "place": "4021 Linz", "displayName": "ÖGK (Oberösterreich)" },
                { "id": 5, "name": "ÖGK", "state": "Salzburg", "address": "Engelbert-Weiß-Weg 10", "place": "5020 Salzburg", "displayName": "ÖGK (Salzburg)" },
                { "id": 6, "name": "ÖGK", "state": "Steiermark", "address": "Josef-Pongratz-Platz 1", "place": "8010 Graz", "displayName": "ÖGK (Steiermark)" },
                { "id": 7, "name": "ÖGK", "state": "Tirol", "address": "Klara-Pölt-Weg 2", "place": "6020 Innsbruck", "displayName": "ÖGK (Tirol)" },
                { "id": 8, "name": "ÖGK", "state": "Vorarlberg", "address": "Jahngasse 4", "place": "6850 Dornbirn", "displayName": "ÖGK (Vorarlberg)" },
                { "id": 9, "name": "ÖGK", "state": "Wien", "address": "Wienerbergstraße 15-19", "place": "1100 Wien", "displayName": "ÖGK (Wien)" },
                { "id": 10, "name": "BVAEB", "state": "Kärnten", "address": "Siebenhügelstraße 1", "place": "9020 Klagenfurt am Wörthersee", "displayName": "BVAEB (Kärnten)" },
                { "id": 11, "name": "BVAEB", "state": "Oberösterreich", "address": "Hessenplatz 14", "place": "4020 Linz", "displayName": "BVAEB (Oberösterreich)" },
                { "id": 12, "name": "BVAEB", "state": "Salzburg", "address": "Faberstraße 2A", "place": "5020 Salzburg", "displayName": "BVAEB (Salzburg)" },
                { "id": 13, "name": "BVAEB", "state": "Steiermark", "address": "Grieskai 106", "place": "8020 Graz", "displayName": "BVAEB (Steiermark)" },
                { "id": 14, "name": "BVAEB", "state": "Tirol", "address": "Meinhardstraße 1", "place": "6010 Innsbruck", "displayName": "BVAEB (Tirol)" },
                { "id": 15, "name": "BVAEB", "state": "Vorarlberg", "address": "Montfortstraße 11", "place": "6900 Bregenz", "displayName": "BVAEB (Vorarlberg)" },
                { "id": 16, "name": "BVAEB", "state": "Wien, NÖ & Burgenland", "address": "Josefstädter Straße 80", "place": "1080 Wien", "displayName": "BVAEB (Wien, NÖ & Burgenland)" },
                { "id": 17, "name": "SVS", "state": "Burgenland", "address": "Siegfried Marcus-Straße 5", "place": "7000 Eisenstadt", "displayName": "SVS (Burgenland)" },
                { "id": 18, "name": "SVS", "state": "Kärnten", "address": "Bahnhofstraße 67", "place": "9020 Klagenfurt", "displayName": "SVS (Kärnten)" },
                { "id": 19, "name": "SVS", "state": "Niederösterreich", "address": "Neugebäudeplatz 1", "place": "3100 Sankt Pölten", "displayName": "SVS (Niederösterreich)" },
                { "id": 20, "name": "SVS", "state": "Oberösterreich", "address": "Hanuschstraße 34", "place": "4020 Linz", "displayName": "SVS (Oberösterreich)" },
                { "id": 21, "name": "SVS", "state": "Salzburg", "address": "Auerspergstraße 24", "place": "5020 Salzburg", "displayName": "SVS (Salzburg)" },
                { "id": 22, "name": "SVS", "state": "Steiermark", "address": "Körblergasse 115", "place": "8010 Graz", "displayName": "SVS (Steiermark)" },
                { "id": 23, "name": "SVS", "state": "Tirol", "address": "Klara-Pölt-Weg 1", "place": "6020 Innsbruck", "displayName": "SVS (Tirol)" },
                { "id": 24, "name": "SVS", "state": "Vorarlberg", "address": "Schloßgraben 14", "place": "6800 Feldkirch", "displayName": "SVS (Vorarlberg)" },
                { "id": 25, "name": "SVS", "state": "Wien", "address": "Wiedner Hauptstraße 84-86", "place": "1051 Wien", "displayName": "SVS (Wien)" },
                { "id": 26, "name": "LKUF", "state": "Oberösterreich", "address": "Leonfeldner Straße 11", "place": "4040 Linz", "displayName": "LKUF (Oberösterreich)" },
                { "id": 27, "name": "KFL", "state": "Oberösterreich", "address": "Böhmerwaldstraße 16", "place": "4020 Linz", "displayName": "KFL (Oberösterreich)" },
                { "id": 28, "name": "KFG", "state": "Oberösterreich", "address": "Friedrichstraße 11", "place": "4041 Linz", "displayName": "KFG (Oberösterreich)" },
                { "id": 29, "name": "KFA", "state": "Steiermark", "address": "Hauptplatz 1", "place": "8010 Graz", "displayName": "KFA (Graz)" },
            ],

            loading_client_data: false,
            error_loading_anamnese: false,
            error_loading_documentation: false,
            appointments: [],
            anamnese: null,
        };
    },

    methods: {

        formatVSNR(svnr, part) {
            // Remove any whitespace from the SVNR
            const cleanSVNR = svnr.replace(/\s/g, '');
            
            switch (part) {
                case 'vsnr':
                    return cleanSVNR.substring(0, 4);
                case 'tag':
                    return cleanSVNR.substring(4, 6);
                case 'monat':
                    return cleanSVNR.substring(6, 8);
                case 'jahr':
                    return cleanSVNR.substring(8, 10);
                default:
                    return '';
            }
        },

        async loadClientData(id) {
            // disable all editing possibilities while loading and indicate loading state
            this.loading_client_data = true;

            // reset error indicators
            this.error_loading_anamnese = false;
            this.error_loading_documentation = false;

            // set to empty immediately, so that "Behandlungsverlauf" disappears
            this.appointments = [];
            this.anamnese = null;

            let anamnese = await connector.getDataOnlyFiltered(this, 'vwdokumentationanamnese', 'eq', 'id', id, 'id');
            if (anamnese === -1) {
                // error has already been displayed
                this.anamnese = null;
                this.error_loading_anamnese = true;
            } else if (anamnese.length > 0) {
                let dec_anamnese = await cipher.decryptObject(this, this.$store.state.aes_key, anamnese[0]);
                this.anamnese = dec_anamnese.anamnese;
            } else {
                this.anamnese = null;
            }

            let appointments = await connector.getDataOnlyFiltered(this, 'vwterminedokumentation', 'eq', 'fk_klienten_id', id, 'datum', false);
            if (appointments === -1) {
                // error has already been shown, we return, so that
                this.appointments = [];
                this.error_loading_documentation = true;

            } else {
                this.appointments = await cipher.decryptDataSync(this, appointments);
            }

            this.loading_client_data = false;
        },

        fillExistingFormFields(isSVS) {
            const form = this.pdfForm;
            const fieldMapping = isSVS ? this.SVSFieldMapping : this.nonSVSFieldMapping;

            Object.entries(fieldMapping).forEach(([fieldName, mapping]) => {
                const field = form.getField(fieldName);
                if (field) {
                    let value;
                    if (typeof mapping === 'string') {
                        value = this.formData[mapping];
                    } else if (typeof mapping === 'function') {
                        value = mapping(this);
                    } else if (typeof mapping === 'object' && mapping.getValue) {
                        value = mapping.getValue(this);
                    } else {
                        value = '';
                    }

                    if (field instanceof PDFTextField) {
                        field.setText(value ? value.toString() : '');
                    } else if (field instanceof PDFCheckBox) {
                        if (value) {
                            field.check();
                        } else {
                            field.uncheck();
                        }
                    }
                } else {
                    console.warn(`Field "${fieldName}" not found in the PDF.`);
                }
            });
        },


        updateField(formDataKey, value) {
            if (!this.pdfForm) return;

              // Handle self_insured logic
            if (formDataKey === 'self_insured') {
                if (value) {
                    this.updateField('insurer_name', '');
                    this.updateField('insurer_svnr', '');
                } else {
                    if (this.formData.insurer_name) {
                        this.updateField('insurer_name', this.formData.insurer_name);
                    }
                    if (this.formData.insurer_svnr) {
                        this.updateField('insurer_svnr', this.formData.insurer_svnr);
                    }
                }
            }

            const isSVS = this.selected_insurer && this.selected_insurer.name === 'SVS';
            const fieldMapping = isSVS ? this.SVSFieldMapping : this.nonSVSFieldMapping;

            Object.entries(fieldMapping).forEach(([fieldName, mapping]) => {
                let dependsOn = [];
                let fieldValue;

                if (typeof mapping === 'string') {
                    if (mapping === formDataKey) {
                        dependsOn.push(mapping);
                        fieldValue = this.formData[mapping];
                    }
                } else if (typeof mapping === 'function') {
                    // Cannot determine dependsOn, so we might need to always update these fields
                    dependsOn = [formDataKey];
                    fieldValue = mapping(this);
                } else if (typeof mapping === 'object' && mapping.getValue) {
                    dependsOn = mapping.dependsOn || [];
                    if (dependsOn.includes(formDataKey)) {
                        fieldValue = mapping.getValue(this);
                    }
                }

                if (dependsOn.includes(formDataKey)) {
                    const field = this.pdfForm.getField(fieldName);
                    if (field) {
                        if (field instanceof PDFTextField) {
                            field.setText(fieldValue ? fieldValue.toString() : '');
                        } else if (field instanceof PDFCheckBox) {
                            if (fieldValue) {
                                field.check();
                            } else {
                                field.uncheck();
                            }
                        }
                    }
                }
            });

            // Debounced PDF render to avoid unnecessary re-renders
            this.debouncedRenderPDF();
        },

        async saveSpecificPages(pageIndices) {
            const pdfBytes = await this.pdfDoc.save();
            const newPdfDoc = await PDFDocument.load(pdfBytes);

            const pageIndexToKeep = pageIndices[0];
            const totalPages = newPdfDoc.getPageCount();

            // Remove pages from the end to the beginning, except the one we want to keep
            for (let i = totalPages - 1; i >= 0; i--) {
                if (i !== pageIndexToKeep) {
                    newPdfDoc.removePage(i);
                }
            }
            // Serialize the new PDF document to bytes
            const newPdfBytes = await newPdfDoc.save();
            return newPdfBytes;
        },

        async renderPDF() {
            if (!this.pdfDoc || !this.isMounted) return;  // Check if still mounted

            const pdfBytes = await this.saveSpecificPages([this.current_tab]);
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            if (this.form_url) URL.revokeObjectURL(this.form_url);
            this.form_url = URL.createObjectURL(blob);
        },

        setAllFieldsReadOnly(isReadOnly) {
            if (!this.pdfForm) return;

            const fields = this.pdfForm.getFields();
            fields.forEach(field => {
                if (field.constructor.name === 'PDFTextField' || field.constructor.name === 'PDFCheckBox') {
                    if (isReadOnly) {
                        field.enableReadOnly();
                    } else {
                        field.disableReadOnly();
                    }
                }
            });
        },

        async downloadForm() {
            if (!this.pdfDoc) return;

            this.setAllFieldsReadOnly(false);
            const pdfBytes = await this.pdfDoc.save();
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            let name = this.formData.firstname.substring(0, 2) + this.formData.lastname.substring(0, 2);
            await saveAs(blob, "antrag_zuschuss_" + name + ".pdf");
            this.setAllFieldsReadOnly(true);
        },

        async createInitialPDF(isSVS = false) {
            const url = isSVS ? '/Kostenzuschuss-PT-SVS.pdf' : '/Kostenzuschuss-PT.pdf';
            const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
            
            // Load the PDF directly
            this.pdfDoc = await PDFDocument.load(existingPdfBytes);
            this.pdf_document = this.pdfDoc;
            this.pdfForm = this.pdfDoc.getForm();

            // Get the dimensions of the first page
            const { width, height } = this.pdfDoc.getPage(0).getSize();
            this.pdfPageHeight = height;
            this.pdfPageWidth = width;

            // Initialize pdfPages and pdfFont before using them
            this.pdfPages = this.pdfDoc.getPages();
            this.pdfFont = await this.pdfDoc.embedFont(StandardFonts.Helvetica);

            // Fill existing form fields
            this.fillExistingFormFields(isSVS);

            // Set all form fields to read-only for display
            this.setAllFieldsReadOnly(true);

            // Initial render
            await this.renderPDF();
        },

        selectDiagnosis(diagnosis) {
            if (this.formData.icd_diagnosis.find((item) => item.code === diagnosis.code)) {
                return;
            } else {
                this.formData.icd_diagnosis.push(diagnosis);
            }
        },

        addText(fieldName, text, join_char = ', ') {
            if (this.formData[fieldName] === '' || !this.formData[fieldName]) {
                this.formData[fieldName] = text;
            } else {
                this.formData[fieldName] += join_char + text;
                this.formData[fieldName] = this.formData[fieldName].trim();
            }
        },

        async getRecommendations(type) {
            const validationRules = {
                getDisorderIntensityPT: () => this.validateDiagnosis(),
                getProgressPT: () => this.validateDiagnosis(),
                getICDDiagnosisPT: () => this.validateField1(),
            };

            if (!validationRules[type]()) {
                return;
            }

            if (!this.checkGoogleTermsAgreement()) {
                return;
            }

            const requestBody = this.buildRequestBody(type);

            try {
                this.setLoading(type, true);
                const { data, error } = await supabase.functions.invoke('get-text-response', { body: requestBody });

                if (error) {
                    this.handleError(type, error);
                    return;
                }

                this.setRecommendations(type, this.parseLLMResponseText(data));
            } catch (error) {
                this.handleError(type, error);
            } finally {
                this.setLoading(type, false);
            }
        },

        validateDiagnosis() {
            if (this.formData.icd_diagnosis.length === 0) {
                this.$emit('showError', {
                    message: 'Bitte gib zuerst eine Diagnose ein um passende Vorschläge zu erhalten.',
                    timeout: 10000
                });
                return false;
            }
            return true;
        },

        validateField1() {
            if (!this.formData.field_1 || this.formData.field_1.length < 10) {
                this.$emit('showError', {
                    message: 'Fülle zuerst die Angaben zur Intensität der Störung aus, um Vorschläge zu erhalten. Diese sollten mindestens 10 Zeichen lang sein.',
                    timeout: 10000
                });
                return false;
            }
            return true;
        },

        checkGoogleTermsAgreement() {
            if (!localStorage.getItem('agreed_google_terms')) {
                this.dialog_google_terms = true;
                return false;
            }
            return true;
        },

        buildRequestBody(type) {
            const baseBody = {
                type,
                anamnese: this.formData.field_1,
                progress: this.formData.field_2,
                diagnosis: this.formData.icd_diagnosis.length > 0 ? this.formData.icd_diagnosis.map((diagnosis) => diagnosis.code).join(', ') : '',
            };

            return baseBody;
        },

        setLoading(type, value) {
            this[`loading_${type}`] = value;
        },

        setRecommendations(type, recommendations) {
            const recommendationMap = {
                getDisorderIntensityPT: 'field_1_suggestions',
                getProgressPT: 'field_2_suggestions',
                getICDDiagnosisPT: 'suggested_diagnosis',
            };
            this[recommendationMap[type]] = recommendations;
        },

        async handleError(type, error) {
            const errorMessages = {
                getProgressPT: 'Textbausteine',
                getDisorderIntensityPT: 'Textbausteine',
                getICDDiagnosisPT: 'Diagnosen',
            };

            let error_message = error.message || '';

            if (error instanceof FunctionsHttpError) {
                let error_json = await error.context.json();
                error_message = error_json.error;
            }

            if (error_message.includes('429 Too Many Requests')) {
                this.$emit('showError', {
                    message: `Die Anfragen an die KI sind aktuell überlastet. Warte eine kurze Zeit und versuche es erneut.`,
                    timeout: 10000,
                });
            } else {
                this.$emit('showError', {
                    message: `Ein Fehler ist beim Vorschlagen der ${errorMessages[type]} aufgetreten. Bitte versuche es erneut.`,
                    timeout: 5000,
                });
            }
        },

        async initialize() {
            // set data for user therapist
            if (this.$store.state.client.name) this.formData.therapist = this.$store.state.client.name;
            if (this.$store.state.client.adresse) this.formData.therapist += '\n' + this.$store.state.client.adresse;
            if (this.$store.state.client.plz) this.formData.therapist += '\n' + this.$store.state.client.plz;
            if (this.$store.state.client.ort) this.formData.therapist += ' ' + this.$store.state.client.ort;

            let customers = await connector.getDataOnly(this, 'vwklienten', 'id', true)
            this.checkReturnValueOfCustomers(customers);

            await this.createInitialPDF();
        },

        closeDialog() {
            this.dialog_prompt_download = false;
            this.dialog = false;
            if (this.form_url) {
                URL.revokeObjectURL(this.form_url);
                this.form_url = null;
            }
            this.removeWatchers();

            // this also resets all other fields in the watcher.
            if (this.client === null) {

                this.selected_insurer = null;
                // clear all the fields
                this.formData = JSON.parse(JSON.stringify(this.formDataDefault));
                
                // set data for user therapist
                if (this.$store.state.client.name) this.formData.therapist = this.$store.state.client.name;
                if (this.$store.state.client.adresse) this.formData.therapist += '\n' + this.$store.state.client.adresse;
                if (this.$store.state.client.plz) this.formData.therapist += '\n' + this.$store.state.client.plz;
                if (this.$store.state.client.ort) this.formData.therapist += ' ' + this.$store.state.client.ort;

            } else {
                this.client = null;
            }
            
        },

        removeWatchers() {
            this.formWatchers.forEach(unwatch => unwatch());
            this.formWatchers = [];
        },

        showSaveDialog() {
            this.dialog_save_form = true;
        },

        async saveForm() {
            if (!this.file_name) {
                this.$emit('showError', {
                    message: 'Bitte gib einen Dateinamen an.',
                    timeout: 10000
                });
                return;
            }

            if (!this.client) {
                this.$emit('showError', {
                    message: 'Bitte wähle zuerst einen Klienten aus.',
                    timeout: 10000
                });
                return;
            }

            // replace all german umlauts and ß with ae oe ue ss etc, also consider capital letters
            this.file_name = this.file_name.replace(/ä/g, 'ae').replace(/Ä/g, 'Ae').replace(/ö/g, 'oe').replace(/Ö/g, 'Oe').replace(/ü/g, 'ue').replace(/Ü/g, 'Ue').replace(/ß/g, 'ss');

            // check if the file name contains special characters, if so, show an error message and skip the file
            if (this.file_name.match(/[^a-zA-Z0-9. \-()_]/)) {
                this.$emit('showError', {
                    message: 'Dateinamen dürfen keine Sonderzeichen oder Umlaute enthalten.',
                });
                return;
            }

            this.saving_form = true;

            let form = {
                client_id: this.client.fk_klienten_id,
                application_form: this.formData.application_form,
                selected_insurer: this.selected_insurer,
                insurance: this.formData.insurance,
                insurance_state: this.formData.insurance_state,
                insurance_street: this.formData.insurance_street,
                insurance_place: this.formData.insurance_place,
                self_insured: this.formData.self_insured,
                insurer_name: this.formData.insurer_name,
                insurer_svnr: this.formData.insurer_svnr,
                lastname: this.formData.lastname,
                firstname: this.formData.firstname,
                address: this.formData.address,
                svnr: this.formData.svnr,
                therapist: this.formData.therapist,
                icd_diagnosis: this.formData.icd_diagnosis,
                job: this.formData.job,
                field_1: this.formData.field_1,
                field_2: this.formData.field_2,
                therapy_setting: this.formData.therapy_setting,
                therapy_setting_other_text: this.formData.therapy_setting_other_text,
                therapy_start: this.formData.therapy_start,
                therapy_units: this.formData.therapy_units,
                therapy_units_past: this.formData.therapy_units_past,
                therapy_units_other: this.formData.therapy_units_other,
                therapy_units_requested: this.formData.therapy_units_requested,
                therapy_frequency: this.formData.therapy_frequency,
                method: this.formData.method,
                method_note: this.formData.method_note,
                notes: this.formData.notes,
            };

            let formString = new TextEncoder().encode(JSON.stringify(form));
            let encrypted_file = await cipher.encryptFile(this.$store.state.aes_key_file, formString);
            let fileSize = '1 KB';

            const fileDataJSON = JSON.stringify({
                iv: encrypted_file.iv,
                file: encrypted_file.file,
                size: fileSize,
            });

            let blob = new Blob([fileDataJSON], { type: 'application/json' });
            let client_id_folder = this.client.fk_klienten_id + '/';
            let uploaded = await connector.uploadFileToBucket(this, 'documentation', this.session.user.id + '/' + client_id_folder, this.file_name + '.ptzpsy', blob, '0', 'application/json');
            
            if (uploaded) {
                this.$emit('showInfo', {
                    message: 'Antrag wurde erfolgreich gespeichert. Du kannst ihn jederzeit in den Dokumenten des Klienten wiederfinden.',
                    timeout: 5000
                });
                this.dialog_save_form = false;
            }
            this.saving_form = false;
        },

        async editForm(prefill = null) {
            this.isPrefilling = true;
            await this.initialize();
            this.icdCodes = json.icdCodes;
            this.current_tab = 0;

            if (prefill) {
                if (this.current_file && 'name' in this.current_file) {
                    this.file_name = this.current_file.name.replace('.ptzpsy', '');
                }
                this.client = this.customers.find((customer) => customer.id === prefill.client_id);

                if (prefill.selected_insurer && 'name' in prefill.selected_insurer && prefill.selected_insurer.name === 'SVS') {
                    await this.createInitialPDF(true);
                } else {
                    await this.createInitialPDF(false);
                }

                // we are loading client data here, as in the watcher the async causes issues with the waiting, causing diagnosis overwrites (empty)
                await this.loadClientData(this.client.fk_klienten_id);
                for (let key in prefill) {
                    if (key in this.formData) {
                        this.formData[key] = prefill[key];
                        this.updateField(key, prefill[key]);
                    }
                }
                this.setupWatchers();
                this.isPrefilling = false; // Set flag after prefill
                await this.renderPDF();
                this.dialog = true;
            } else {
                this.setupWatchers();
                this.isPrefilling = false; // Set flag after prefill
                this.dialog = true;
            }
        },

        sortListOfObjectsByString(a, b) {
            const nameA = a.name.toUpperCase() // ignore upper and lowercase
            const nameB = b.name.toUpperCase() // ignore upper and lowercase
            if (nameA > nameB) {
                return 1
            }
            if (nameA < nameB) {
                return -1
            }
            // names must be equal
            return 0
        },

        async checkReturnValueOfCustomers(customers) {
            if (customers === -1) {
                // wrong key
                this.customers = []
                this.n_customers = 0
            }
            else {
                this.customers = []
                this.n_customers = customers.length
                this.customers = await cipher.decryptDataSync(this, customers);
                this.customers = this.customers.map((dec) => {
                    let customer = dec;
                    customer.name = customer.nachname + ' ' + customer.vorname;
                    customer.fk_klienten_id = customer.id;
                    return customer;
                })

                this.loaded_customers = true;
            }
        },

        parseLLMResponseText(response) {
            let candidate_parts = response.candidates;
            let content_parts = candidate_parts.filter((item) => 'content' in item && 'parts' in item.content).map((item) => item.content.parts).flat(1);
            let text = content_parts.filter((item) => 'text' in item).map((item) => item.text).join("");

            if (!text) {
                return [];
            }

            try {
                const parsed_json = JSON.parse(text);

                if (typeof parsed_json === 'object' && parsed_json !== null && 'suggestions' in parsed_json) {
                    return Array.isArray(parsed_json.suggestions) ? parsed_json.suggestions : [];
                } else {
                    console.error('The parsed JSON does not contain a "suggestions" key or it\'s not an array');
                    return [];
                }
            } catch (error) {
                console.error('Error parsing JSON:', error);
                return [];
            }
        },

        setupWatchers() {
            const formDataKeys = Object.keys(this.formData);
            formDataKeys.forEach(key => {
                const unwatch = this.$watch(`formData.${key}`, (newValue) => {
                    if (!this.isPrefilling) {
                        this.updateField(key, newValue);
                    }
                });
                this.formWatchers.push(unwatch);
            });
        },

        onIframeLoad(id) {
            this.iframeLoaded = true;
            this.updateClientWidth(id);
        },

        updateClientWidth(id) {
            this.$nextTick(() => {
                const iframe = this.$refs['iframe-pdf-' + id];
                if (iframe && iframe.clientWidth > 0) {
                    this.clientWidth = iframe.clientWidth;
                }
            });
        }
    },

    watch: {

        async selected_insurer(newVal) {
            if (newVal !== null && newVal !== undefined && 'address' in newVal) {
                if (this.isPrefilling) return; 
                if (newVal.name === 'SVS') {
                    await this.createInitialPDF(true);
                } else {
                    await this.createInitialPDF(false);
                }
                this.formData.insurance = newVal.name;
                this.formData.insurance_state = newVal.state;
                this.formData.insurance_street = newVal.address;
                this.formData.insurance_place = newVal.place;
            }
        },

        // watch if client changes, if yes, load the documentation for the client and set it. The function is asnyc.
        async client() {
            if (this.client !== null && this.client !== undefined && this.client.fk_klienten_id !== undefined && this.client.fk_klienten_id !== null) {
                // retrieve the client's anamnese from the database in the view vwdokumentationanamnese

                // reset selected insurer
                this.selected_insurer = null;

                const { mitversichert_bei, versicherungsträger } = this.client;

                let insurerId;
                if (mitversichert_bei) {
                    const propertyName = `${mitversichert_bei}_versicherungsträger`;
                    insurerId = this.client[propertyName];
                    
                    if (!(propertyName in this.client)) {
                        this.$emit('showError', {
                            message: 'Fehler beim Lesen der Versicherten Person. Fehler: Unbekannte Person. Bitte wende dich an den Support.',
                        });
                        return;
                    }
                } else {
                    insurerId = versicherungsträger;
                }

                this.selected_insurer = this.insurer.find(insurer => insurer.id === insurerId);

                if (this.isPrefilling) return; // <-- IMPORTANT: Ignore watcher until initial prefill is done otherwhise, we might overwrirte values

                await this.loadClientData(this.client.fk_klienten_id);

                // set all the client information
                if (this.client.vorname) this.formData.firstname = this.client.vorname;
                else this.formData.firstname = '';

                if (this.client.nachname) this.formData.lastname = this.client.nachname;
                else this.formData.lastname = '';

                this.formData.address = '';
                if (this.client.adresse) this.formData.address += this.client.adresse;
                if (this.client.plz) this.formData.address += '\n' + this.client.plz;
                if (this.client.ort) this.formData.address += ' ' + this.client.ort;

                if (this.client.svnr) this.formData.svnr = this.client.svnr;
                else this.formData.svnr = '';

                this.formData.icd_diagnosis = [];
                if (this.client.zusatztext) {
                    // try to parse diagnosis
                    let diagnoses = this.client.zusatztext.match(/([F][0-9]{2}\.[0-9]{1,2})/g);
                    if (diagnoses && diagnoses.length > 0) {
                        // find the diagnosis in the list of diagnoses
                        for (let diagnosis of diagnoses) {
                            let diagnosis_obj = this.icdCodes.find((diag) => diag.short_code === diagnosis);
                            if (diagnosis_obj) {
                                this.formData.icd_diagnosis.push(diagnosis_obj);
                            }
                        }
                    }
                }

                if (this.client.mitversichert_bei) {
                    this.formData.self_insured = false;

                    let insurance_obj = null;

                    // get the insurer information and set it
                    if (this.client.mitversichert_bei === 'vater') {
                        this.formData.insurer_name = this.client.vater_vorname + ' ' + this.client.vater_nachname;
                        this.formData.insurer_svnr = this.client.vater_svnr;
                        insurance_obj = this.insurer.find(insurer => insurer.id === this.client.vater_versicherungsträger);
                    } else if (this.client.mitversichert_bei === 'mutter') {
                        this.formData.insurer_name = this.client.mutter_vorname + ' ' + this.client.mutter_nachname;
                        this.formData.insurer_svnr = this.client.mutter_svnr;
                        insurance_obj = this.insurer.find(insurer => insurer.id === this.client.mutter_versicherungsträger);
                    } else if (this.client.mitversichert_bei === 'partner') {
                        this.formData.insurer_name = this.client.partner_vorname + ' ' + this.client.partner_nachname;
                        this.formData.insurer_svnr = this.client.partner_svnr;
                        insurance_obj = this.insurer.find(insurer => insurer.id === this.client.partner_versicherungsträger);
                    } else {
                        this.$emit('showError', {
                            message: 'Fehler beim Lesen der Versicherten Person. Fehler: Unbekannte Person. Bitte wende dich an den Support.',
                        });
                    }

                    if (insurance_obj) {
                        this.formData.insurance = insurance_obj.name;
                        this.formData.insurance_state = insurance_obj.state;
                        this.formData.insurance_street = insurance_obj.address;
                        this.formData.insurance_place = insurance_obj.place;
                    } else {
                        this.formData.insurance = '';
                        this.formData.insurance_state = '';
                        this.formData.insurance_street = '';
                        this.formData.insurance_place = '';
                    }
                } else {
                    this.formData.self_insured = true;
                    // reset those two values, as those might be set from previous client.
                    this.formData.insurer_name = '';
                    this.formData.insurer_svnr = '';
                    let insurance_obj = this.insurer.find(insurer => insurer.id === this.client.versicherungsträger);

                    if (insurance_obj) {
                        this.formData.insurance = insurance_obj.name;
                        this.formData.insurance_state = insurance_obj.state;
                        this.formData.insurance_street = insurance_obj.address;
                        this.formData.insurance_place = insurance_obj.place;
                    } else {
                        this.formData.insurance = '';
                        this.formData.insurance_state = '';
                        this.formData.insurance_street = '';
                        this.formData.insurance_place = '';
                    }
                }

                if (this.appointments.length > 0) {
                    let appointments = this.appointments.filter(appointment => appointment.fk_klienten_id === this.client.fk_klienten_id)
                    // get the last appointment as it is the first one and set the datum as therapy_start
                    if (appointments.length > 0) {
                        // format the date as DD.MM.YYYY using dayjs
                        this.formData.therapy_start = dayjs(appointments[appointments.length - 1].datum).format('DD.MM.YYYY');
                    }

                    // get all past appointments and sum up the units, use dayjs to compare the dates
                    let past_appointments = appointments.filter(appointment => appointment.fk_klienten_id === this.client.fk_klienten_id && dayjs(appointment.datum).isBefore(dayjs()));
                    if (past_appointments.length > 0) {
                        //  use the length and convert the number to a string
                        this.formData.therapy_units_past = past_appointments.length.toString();
                    }
                }
            } else {

                this.selected_insurer = null;
                // clear all the fields
                this.formData = JSON.parse(JSON.stringify(this.formDataDefault));
                
                // set data for user therapist
                if (this.$store.state.client.name) this.formData.therapist = this.$store.state.client.name;
                if (this.$store.state.client.adresse) this.formData.therapist += '\n' + this.$store.state.client.adresse;
                if (this.$store.state.client.plz) this.formData.therapist += '\n' + this.$store.state.client.plz;
                if (this.$store.state.client.ort) this.formData.therapist += ' ' + this.$store.state.client.ort;
            }
        },

        async current_tab() {
            await this.renderPDF();
        },
    },

    mounted() {
        this.isMounted = true;
        this.formDataDefault = JSON.parse(JSON.stringify(this.formData));
        this.resizeHandler = () => {
            this.updateClientWidth(this.current_tab + 1);
            this.windowWidth = window.innerWidth;
            this.debouncedRenderPDF();
        };
        window.addEventListener('resize', this.resizeHandler);
    },
    beforeDestroy() {
        this.isMounted = false;  // Set to false when component is about to unmount
        window.removeEventListener('resize', this.resizeHandler);
        this.removeWatchers();
    },

    computed: {

        scaledHeight() {

            if (!this.pdfPageWidth || !this.iframeLoaded) {
                return 300; // Default height
            }

            let scale = (this.clientWidth / this.pdfPageWidth) * (this.windowWidth / this.windowWidth);
            scale = scale || 1; // Fallback to 1 if scale is 0

            let height = this.pdfPageHeight ? this.pdfPageHeight * scale : 300;
            return height;
        },

        sortedCustomers() {
            return [...this.customers].sort(this.sortListOfObjectsByString);
        },

        suggestedDiagnoses() {
            if (!this.suggested_diagnosis || this.suggested_diagnosis.length === 0) {
                return "";
            }

            // match the icd codes with regex in the format FDD.D and store it in an array
            const matches = this.suggested_diagnosis;
            const responseArray = matches ? [...new Set(matches)] : [];

            // try to split the response into an array of strings using , as delimiter

            let mapped_diagnoses = [];

            // iterate over the array and try to find the most similar diagnoses from the list of icd10 diagnoses
            for (let i = 0; i < responseArray.length; i++) {
                let similarDiagnoses = this.icdCodes.filter((diagnosis) => diagnosis.short_code.toLowerCase() === responseArray[i].trim().toLowerCase());
                // add the similar diagnoses to the mapped_diagnoses array
                mapped_diagnoses.push(similarDiagnoses);
            }

            return [...new Set(mapped_diagnoses.flat(1))];
        },
    }
};
</script>

<style scoped>
.v-chip-custom {
    height: auto !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    white-space: normal !important;
}
.v-sheet.v-card {
    border-radius: 6px;
}
</style>