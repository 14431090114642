<template>
    <v-container fluid>
        <v-dialog v-model="dialog_anamnese" max-width="1000px" persistent :fullscreen="$vuetify.breakpoint.xsOnly || isFullscreen">
            <v-card>
                <v-card-title class="mr-4 pr-0">
                    <span class="text-h5">Vorgeschichte / Anamnese bearbeiten</span>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom open-delay="300" v-if="$vuetify.breakpoint.smAndUp">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="toggleFullscreen" v-bind="attrs" v-on="on">
                                <v-icon>{{ isFullscreen ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}</v-icon>
                            </v-btn>
                        </template>
                        {{ isFullscreen ? 'Vollbild beenden.' : 'In den Vollbildmodus wechseln.' }}
                    </v-tooltip>
                </v-card-title>
                <v-card-text>
                    <TipTapEditor ref="editor-anamnese" :templates="templates" :value="edit_anamnese" @input="handleInput('edit_anamnese', $event)" @fetchTemplates="fetchTemplates" :session="session" />
                </v-card-text>
                <v-card-actions class="pb-5 px-6">
                    <v-spacer></v-spacer>
                    <v-btn text :color="$store.state.theme.primary" @click="abortAnamnese">Abbrechen</v-btn>
                    <v-btn outlined :color="$store.state.theme.green" @click="saveAnamnese" :loading="saving">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_documentation" max-width="1000px" persistent :fullscreen="$vuetify.breakpoint.xsOnly || isFullscreen">
            <v-card>
                <v-tabs v-model="tabs_documentation_type" color="grey darken-4" :background-color="$store.state.theme.background_tabs" grow class="mb-5" show-arrows>
                    <v-tabs-slider color="grey darken-4" />
                    <v-tab>
                        Dokumentation
                        <v-tooltip bottom open-delay="300">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon right v-bind="attrs" v-on="on">
                                    mdi-information-outline
                                </v-icon>
                            </template>
                            <span>
                                Erstelle hier die Dokumentation der Einheit. Diese kann bei Bedarf vom Klienten angefordert und exportiert werden.
                            </span>
                        </v-tooltip>
                    </v-tab>
                    <v-tab>
                        Persönliche Notizen
                        <v-tooltip bottom open-delay="300">
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon right v-bind="attrs" v-on="on">
                                    mdi-information-outline
                                </v-icon>
                            </template>
                            <span>
                                Schreibe hier persönliche Notizen zur Einheit. Diese sind nicht Teil der offiziellen Dokumentation und werden nicht exportiert.
                            </span>
                        </v-tooltip>
                    </v-tab>
                    <v-tooltip bottom open-delay="300" v-if="$vuetify.breakpoint.smAndUp">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="toggleFullscreen" class="mt-2 mx-3" v-bind="attrs" v-on="on">
                                <v-icon>{{ isFullscreen ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}</v-icon>
                            </v-btn>
                        </template>
                        {{ isFullscreen ? 'Vollbild beenden.' : 'In den Vollbildmodus wechseln.' }}
                    </v-tooltip>
                </v-tabs>
                
                <v-card-text class="pt-1">
                    <v-tabs-items v-model="tabs_documentation_type">
                        <v-tab-item eager>
                            <TipTapEditor ref="editor-documentation" :templates="templates" :value="documentation" @input="handleInput('documentation', $event)" @fetchTemplates="fetchTemplates" :session="session" />
                        </v-tab-item>
                        <v-tab-item eager>
                            <TipTapEditor ref="editor-notes" :templates="templates" :value="notes" @input="handleInput('notes', $event)" @fetchTemplates="fetchTemplates" :session="session" />
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
                
                <v-card-actions class="pb-5 px-6">
                    <v-spacer></v-spacer>
                    <v-btn text :color="$store.state.theme.primary" @click="abortDocumentation">Abbrechen</v-btn>
                    <v-btn outlined :color="$store.state.theme.green" @click="saveDocumentation" :loading="saving">Speichern</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-row no-gutters>
            <v-col class="d-flex align-center justify-space-between my-0 py-0">
                <span :class="$vuetify.breakpoint.xsOnly ? 'text-h6' : 'text-h5'">Dokumentation</span>
                <v-btn v-if="selectedClientStatistics" @click="downloadDocumentation" :disabled="loading_client_data || error_loading_anamnese || error_loading_documentation">
                    <v-icon left>
                        mdi-file-download-outline
                    </v-icon>

                    <span v-if="$vuetify.breakpoint.smAndUp">Dokumentation herunterladen</span>
                    <span v-else>Herunterladen</span>
                </v-btn>
            </v-col>
        </v-row>

        <v-row no-gutters v-if="!selectedClientStatistics">
            <v-col>
                <v-card class="pa-5 mb-4 mt-3">

                    <p class="text-h6 mb-3">
                        Klient auswählen
                    </p>

                    <v-autocomplete 
                        prepend-inner-icon="mdi-account" 
                        v-model="selected_client" 
                        :items="formattedClientsNotArchived"
                        label="Klient" 
                        item-text="name" 
                        item-value="id" 
                        outlined 
                        return-object 
                        dense
                        clear-icon="mdi-close-circle" 
                        clearable 
                        placeholder="Suchbegriff..." 
                        persistent-placeholder
                        @change="onClientChange"
                    >
                        <template v-slot:item="{ item }">
                            <div class="d-flex align-center">
                                <span>{{ item.name }}</span>
                                <v-chip v-for="merkmal in item.merkmale" :key="'chip-' + merkmal.id" class="ml-2" small>{{
                                    merkmal.merkmal }}</v-chip>
                            </div>
                        </template>
                        <template v-slot:selection="{ item }">
                            <div class="d-flex align-center">
                                <span>{{ item.name }}</span>
                                <v-chip v-for="merkmal in item.merkmale" :key="'chip-selection-' + merkmal.id" class="ml-2"
                                    small>{{
                                        merkmal.merkmal }}</v-chip>
                            </div>
                        </template>
                    </v-autocomplete>

                    <p class="text-h6 mb-3" v-if="!selectedClientStatistics">
                        Schnellauswahl
                    </p>
                    <v-row>
                        <v-col v-for="client in clientsQuickSelect" :key="client.id" cols="12" md="3">
                            <v-hover v-slot="{ hover }">
                                <v-card outlined class="file-card"
                                    @click="onClientChange(formattedClients.find(c => c.id === client.id))"
                                    :elevation="hover ? '2' : '0'">
                                    <v-card-title>
                                        <v-icon left>
                                            mdi-account
                                        </v-icon>
                                        <span class="text-truncate">{{ client.name }}</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <p class="mb-0">Nicht dokumentierte Termine: {{ client.nicht_dokumentiert }}</p>
                                    </v-card-text>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-else>
            <v-col cols="12" md="6" class="mt-0 pt-0">
                <v-card class="pa-5 mb-4">
                    <div class="d-flex flex-column align-center">
                        <v-autocomplete 
                            v-model="selected_client" 
                            :items="formattedClientsNotArchived" 
                            outlined 
                            append-icon="mdi-magnify"
                            item-text="name" 
                            item-value="id" 
                            return-object 
                            full-width 
                            style="width: 100%;" 
                            clear-icon="mdi-close-circle" 
                            clearable placeholder="Suchbegriff..." 
                            persistent-placeholder
                            @change="onClientChange"
                        >
                            <template v-slot:item="{ item }">
                                <div class="d-flex align-center">
                                    <span>{{ item.name }}</span>
                                    <v-chip v-for="merkmal in item.merkmale" :key="'chip-' + merkmal.id" class="ml-2" small>
                                        {{ merkmal.merkmal }}
                                    </v-chip>
                                </div>
                            </template>
                            <template v-slot:selection="{ item }">
                                <div class="d-flex align-center justify-center" style="width: 100%;">
                                    <v-icon left large>
                                        mdi-account
                                    </v-icon>
                                    <span class="text-h6">
                                        {{ item.name }} {{ selected_client.alter ? '(' + selected_client.alter + ')' : '' }}
                                    </span>
                                    <v-chip v-if="item.archiviert" :color="$store.state.theme.primary" class="ml-2" small dark>Archiviert</v-chip>
                                </div>
                            </template>
                        </v-autocomplete>

                        <div class="subtitle-1 text-center">{{ selected_client.adresse }}</div>
                        <div full-width class="d-flex flex-wrap justify-center">
                            <v-chip v-for="merkmal in selected_client.merkmale" :key="'chip-details-' + merkmal.id"
                                class="mr-2 my-1">{{
                                    merkmal.merkmal }}
                            </v-chip>
                            <v-chip class="my-1" v-if="selected_client.svnr">SV-Nr.: {{ selected_client.svnr }}</v-chip>
                        </div>
                    </div>

                    <div v-if="selected_client.zusatztext" class="mt-3 text-center">{{ selected_client.zusatztext }}</div>

                    <v-row class="mt-3">
                        <v-col cols="6" class="d-flex flex-column align-center">
                            <div class="body-1">Einheiten</div>
                            <div class="title">{{ selectedClientStatistics.appointment_count }} ({{
                                selectedClientStatistics.appointment_sum }} Minuten)</div>
                        </v-col>

                        <v-col cols="6" class="d-flex flex-column align-center">
                            <div class="body-1">Behandlungsfrequenz</div>
                            <div class="title">{{ selectedClientStatistics.appointment_frequency }}x / Monat</div>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="6" class="d-flex flex-column align-center">
                            <div class="body-1">Erste Einheit</div>
                            <div class="title">{{ selectedClientStatistics.first_appointment }}</div>
                        </v-col>

                        <v-col cols="6" class="d-flex flex-column align-center">
                            <div class="body-1">Letzte Einheit</div>
                            <div class="title">{{ selectedClientStatistics.last_appointment }}</div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>

            <v-col cols="12" md="6" class="mt-0 pt-0">
                <UploadedFilesCard 
                    ref="uploadedFilesCard"
                    :session="session"
                    :clientId="selected_client ? selected_client.id : null"
                    :appointments="appointments"
                    @update:uploadedAppointmentFiles="uploadedAppointmentFiles = $event"
                    @showError="(arg) => $emit('showError', arg)"
                    @showInfo="(arg) => $emit('showInfo', arg)"
                    />
            </v-col>
        </v-row>

        <div v-if="selectedClientStatistics">
            <p class="text-h5 mt-5">Vorgeschichte / Anamnese</p>
            <v-card>
                <v-card-text class="pb-5 text-body-1 black--text" ref="container-anamnese">
                    <v-skeleton-loader v-show="loading_client_data" type="paragraph" transition="fade-transition" />
                    <div v-show="!loading_client_data && anamnese" class="p-container" style="white-space: pre-line;" v-html="anamnese">
                    </div>
                    <span v-show="!loading_client_data && !anamnese && !error_loading_anamnese" class="font-italic">
                        Es wurde noch keine Anamnese für diesen Klienten verfasst.
                    </span>
                    <span v-show="!loading_client_data && !anamnese && error_loading_anamnese" class="font-italic">
                        <v-icon left>mdi-connection</v-icon>
                        Die Anamnese konnte nicht geladen werden.<br/>
                        <v-btn outlined :color="$store.state.theme.green" class="mt-5" @click="reloadWindow">
                            <v-icon left>
                                mdi-refresh
                            </v-icon>
                            Neu Laden
                        </v-btn>
                    </span>
                </v-card-text>
                <v-card-actions v-if="!error_loading_anamnese" class="justify-end px-5 pb-5">
                    <v-btn outlined @click="editAnamnese" :disabled="loading_client_data">Bearbeiten</v-btn>
                </v-card-actions>
            </v-card>

            <div class="d-flex align-center">
                <p class="text-h5 mt-5">Behandlungsverlauf</p>
                <v-btn icon class="ml-4" @click="sort_appointments_descending = !sort_appointments_descending">
                    <v-icon>mdi-swap-vertical-bold</v-icon>
                </v-btn>
            </div>

            <v-card v-for="appointment in selectedClientAppointments" :key="appointment.id" class="mb-5">
                <v-card-title>
                    <div class="d-flex flex-column">
                        <span class="text-subtitle-1 font-weight-bold">
                            {{ appointment.appointment_count }}. Termin am {{ getFormattedDate(appointment.datum) }} um {{ getFormattedTime(appointment.uhrzeit) }}
                        </span>
                        <span class="text-subtitle-2" v-if="appointment.bezeichnung">{{ appointment.bezeichnung }}</span>
                    </div>               
                </v-card-title>
                <v-card-text class="pb-2 text-body-1 black--text">
                    <div v-if="appointment.dokumentation && appointment.dokumentation !== '<p></p>'" style="white-space: pre-line;" class="p-container" v-html="appointment.dokumentation">
                    </div>
                    <span v-else class="font-italic">
                        Diese Einheit wurde noch nicht dokumentiert.
                    </span>
                    <p v-if="appointment.notizen && appointment.notizen !== '<p></p>'" class="text-subtitle-1 font-weight-bold pb-1 mt-5 mb-0">
                        Persönliche Notizen:
                    </p>
                    <div v-if="appointment.notizen && appointment.notizen !== '<p></p>'" style="white-space: pre-line;" class="p-container" v-html="appointment.notizen">
                    </div>
                </v-card-text>
                <v-card-actions :class="$vuetify.breakpoint.smAndDown ? 'd-flex flex-column align-end px-5 pb-5' : 'justify-end px-5 pb-5'">
                    <v-row no-gutters v-if="appointmentFileIds.length > 0 && appointmentFileIds.includes(appointment.id)">
                        <v-col
                            v-for="(file) in uploadedAppointmentFiles.filter(file => file.appointment_id === appointment.id)"
                            :key="'appointment-' + file.id" cols="12" md="6" lg="4">
                            <v-hover v-slot="{ hover }">
                                <v-card class="d-flex align-center px-3 py-1 ma-2 file-card" :elevation="hover ? 2 : 0"
                                    outlined @click.stop="$refs.uploadedFilesCard.openFile(file)">
                                    <v-icon v-if="!file.uploading" class="mr-3" :color="file.iconColor">{{ file.icon
                                    }}</v-icon>
                                    <v-progress-circular v-else class="mr-3" :size="30" indeterminate color="primary" />
                                    <div class="file-info-container d-flex flex-column justify-center flex-grow-1">
                                        <span class="text-caption text-truncate-file">{{ file.name }}</span>
                                        <span>{{ file.size }}</span>
                                    </div>
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                    <v-btn text @click="selectFilesAppointments(appointment.id)">Dateien hinzufügen</v-btn>
                    <v-btn outlined @click="editDocumentation(appointment)">Bearbeiten</v-btn>
                </v-card-actions>
            </v-card>
            <v-card v-if="selectedClientAppointments.length === 0">
                <v-card-text v-if="!loading_client_data && !error_loading_documentation" class="d-flex align-center text-body-1 black--text">
                    <v-icon left large>
                        mdi-information-outline
                    </v-icon>
                    <span class="font-italic">
                        Es haben noch keine Termine stattgefunden.
                        Um Termine dokumentieren zu können, müssen zuerst Termine erstellt werden.
                    </span>
                </v-card-text>
                <v-card-text v-else-if="!loading_client_data && error_loading_documentation" class="d-flex align-center text-body-1 black--text">
                    <span class="font-italic">
                    <v-icon left>mdi-connection</v-icon>
                    Die Dokumentation konnte nicht geladen werden.<br/>
                    <v-btn outlined :color="$store.state.theme.green" class="mt-5" @click="reloadWindow">
                        <v-icon left>
                            mdi-refresh
                        </v-icon>
                        Neu Laden
                    </v-btn>
                </span>
                </v-card-text>
            </v-card>
        </div>
        <p v-else class="d-flex justify-center">
            <v-icon left large>
                mdi-chart-timeline-variant-shimmer
            </v-icon>
            <span class="">Wähle einen Klienten aus um die Dokumentation zu sehen.</span>
        </p>
    </v-container>
</template>

<script>
import connector from '../helpers/supabase-connector.js'
import cipher from '../helpers/cipher.js'
import dayjs from 'dayjs'

import PizZip from 'pizzip'
import Docxtemplater from 'docxtemplater'
import InspectModule from 'docxtemplater/js/inspect-module'
import { saveAs } from 'file-saver'
import TipTapEditor from '../components/TipTapEditor.vue'
import UploadedFilesCard from '../components/UploadedFilesCard.vue';

export default {

    components: {
        TipTapEditor,
        UploadedFilesCard
    },

    props: ['session'],

    data() {
        return {

            error_loading_anamnese: false,
            error_loading_documentation: false,

            templates: [], // Array to store template data
            isFullscreen: false,
            tabs_documentation_type: 0,

            uploadedAppointmentFiles: [],
            dialog_anamnese: false,
            dialog_documentation: false,

            clients: [],
            loading_clients: false,
            selected_client: null,

            appointments: [],
            documentation: null,
            notes: null,

            documentation_appointment_id: null,
            sort_appointments_descending: true,

            anamnese: null,
            edit_anamnese: null,
            saving: false,
        }
    },

    async mounted() {
        await this.initialize();
        await this.loadClientData();
    },

    watch: {
        '$route.params.id': {
            immediate: true,
            async handler(newId) {
                if (newId && this.clients.length === 0) {
                    await this.initialize();
                }
                await this.loadClientData();
            }
        }
    },

    methods: {

        reloadWindow() {
            window.location.reload(true);
        },

        onClientChange(value) {
            if (value && value.id) {
                this.$router.push({ path: `/dokumentation/${value.id}` });
            } else {
                this.$router.push({ path: `/dokumentation` });
            }
        },

        async loadClientData() {
            if (this.$route.params.id) {

                // disable all editing possibilities while loading and indicate loading state
                this.loading_client_data = true;
                
                // reset error indicators
                this.error_loading_anamnese = false;
                this.error_loading_documentation = false;

                // set to empty immediately, so that "Behandlungsverlauf" disappears
                this.appointments = [];

                const id = parseInt(this.$route.params.id);
                let selected_client = this.formattedClients.find(client => client.id === id);
                if (!selected_client) {
                    this.$emit('showError', {
                        message: 'Dieser Klient wurde nicht gefunden.',
                        timeout: 10000,
                        error: 'id: ' + this.$route.params.id,
                    });
                    this.$router.push({ path: `/dokumentation` });
                    return;
                }
                
                let anamnese = await connector.getDataOnlyFiltered(this, 'vwdokumentationanamnese', 'eq', 'id', id, 'id');
                if (anamnese === -1) {
                    // error has already been displayed
                    this.anamnese = null;
                    this.edit_anamnese = null;
                    this.error_loading_anamnese = true;
                } else if (anamnese.length > 0) {
                    let dec_anamnese = await cipher.decryptObject(this, this.$store.state.aes_key, anamnese[0]);
                    this.anamnese = dec_anamnese.anamnese;
                    this.edit_anamnese = this.anamnese;
                } else {
                    this.anamnese = null;
                    this.edit_anamnese = null;
                }

                let appointments = await connector.getDataOnlyFiltered(this, 'vwterminedokumentation', 'eq', 'fk_klienten_id', id, 'datum', false);
                if (appointments === -1) {
                    // error has already been shown, we return, so that 
                    this.appointments = [];
                    this.error_loading_documentation = true;

                } else {
                    this.appointments = await cipher.decryptDataSync(this, appointments);
                }
                
                // setting at the end, as this triggers the ui change.
                this.selected_client = selected_client;
                this.loading_client_data = false;
                
            } else {
                this.selected_client = null;
                this.anamnese = null;
                this.edit_anamnese = null;
            }
        },

        selectFilesAppointments(id) {
            this.$refs.uploadedFilesCard.selectFilesForAppointment(id);
        },

        async fetchTemplates() {
            let templates = await connector.getDataOnly(this, 'vwdokumentationsvorlagen', 'name', true);
            if (templates === -1) {
                // error has already been displayed
                this.templates = [];
            } else {
                this.templates = templates.map((template) => {
                    template.deleting = false;
                    return template;
                });
            }
        },

        toggleFullscreen() {
            this.isFullscreen = !this.isFullscreen;
        },

        removeTags(str) {
            if ((str === null) || (str === '') || typeof str !== 'string') return '';
    
            // Replace <br> tags with newline
            str = str.replace(/<br\s*\/?>/gi, '\n');

            // Replace closing <p> tags with newlines
            // the opening tags are replaced afterwards with empty string
            // Optionally, you might want to handle <p> more carefully to avoid too many blank lines
            str = str.replace(/<\/p>/gi, '\n');
                
            return str.replace(/(<([^>]+)>)/ig, '');
        },

        async initialize() {
            this.loading_clients = true;
            
            await this.fetchTemplates();
            
            let clients = await connector.getDataOnly(this, 'vwklientendokumentation', 'id', false);
            if (clients === 1) {
                // error has already been displayed.
                this.clients = [];
            } else {
                this.clients = await cipher.decryptDataSync(this, clients);
            }
            
            this.loading_clients = false;
        },

        sortListOfObjectsByString(a, b) {
            const nameA = a.name.toUpperCase() // ignore upper and lowercase
            const nameB = b.name.toUpperCase() // ignore upper and lowercase
            if (nameA > nameB) {
                return 1
            }
            if (nameA < nameB) {
                return -1
            }
            // names must be equal
            return 0
        },

        getFormattedDate(date) {
            return dayjs(date).format('DD.MM.YYYY')
        },

        getFormattedTime(time) {
            return dayjs(time, 'HH:mm:ss').format('HH:mm')
        },

        async saveAnamnese() {
            this.saving = true;

            try {
                // save the anamnese to the database
                let enc_anamnese = await cipher.encryptObject(this.$store.state.aes_key, {
                    anamnese: this.edit_anamnese,
                });

                let updated = await connector.update(this, 'klienten', enc_anamnese, this.selected_client.id);
                if (updated === null) {
                    // error has already been shown
                    this.saving = false;
                    return;
                }
                // so that user sees the edited changes
                this.anamnese = this.edit_anamnese;
                this.$refs['editor-anamnese'].clearHistory();
                this.saving = false;
                this.dialog_anamnese = false;
                
            } catch (error) {
                await connector.logError(this, {
                    uid: this.session.user.id,
                    message: 'Error saving anamnese ' + error.message,
                });
                this.saving = false;
            }
        },

        abortAnamnese() {
            // reset the anamnese to the last saved value
            this.edit_anamnese = this.anamnese
            this.$refs['editor-anamnese'].clearHistory();
            this.dialog_anamnese = false
        },

        editDocumentation(appointment) {
            this.tabs_documentation_type = 0;
            this.documentation = appointment.dokumentation;
            this.notes = appointment.notizen;

            if ('editor-documentation' in this.$refs) {
                this.$refs['editor-documentation'].setContent(this.documentation);
            }

            if ('editor-notes' in this.$refs) {
                this.$refs['editor-notes'].setContent(this.notes);
            }

            this.documentation_appointment_id = appointment.id;
            this.dialog_documentation = true;
        },

        editAnamnese() {
            this.edit_anamnese = this.anamnese;
            if ('editor-anamnese' in this.$refs) {
                this.$refs['editor-anamnese'].setContent(this.edit_anamnese);
            }
            this.dialog_anamnese = true
        },

        abortDocumentation() {
            this.$refs['editor-documentation'].clearHistory();
            this.$refs['editor-notes'].clearHistory();
            this.dialog_documentation = false;
            this.tabs_documentation_type = 0;
        },

        async saveDocumentation() {
            // save the anamnese to the database
            try {

                this.saving = true;
                let enc_documentation = await cipher.encryptObject(this.$store.state.aes_key, {
                    dokumentation: this.documentation,
                    notizen: this.notes,
                })

                let updated = await connector.update(this, 'termine', enc_documentation, this.documentation_appointment_id);
                if (updated === null) {
                    // error has already been shown
                    this.saving = false;
                    return;
                }

                // update the dokumentation property in this.appointments with the new value where the id matches
                let appointment_index = this.appointments.findIndex(appointment => appointment.id === this.documentation_appointment_id);
                this.appointments[appointment_index].dokumentation = this.documentation;
                this.appointments[appointment_index].notizen = this.notes;

                this.$refs['editor-documentation'].clearHistory();
                this.$refs['editor-notes'].clearHistory();

                // i guess its better to not reset in case something fails, then the user can just try again as the data is still there
                //this.documentation = null
                //this.documentation_appointment_id = null
                this.dialog_documentation = false;
                this.saving = false;
                this.tabs_documentation_type = 0;
            } catch (error) {
                this.saving = false;
                await connector.logError(this, {
                    uid: this.session.user.id,
                    message: 'Error saving documentation ' + error.message,
                });
            }
        },

        async downloadDocumentation() {

            try {
                let div = this.$refs['container-anamnese'];
                // Replace <br> with \n
                div.querySelectorAll('br').forEach(br => br.replaceWith('\n'));
                // Add \n after </p>
                div.querySelectorAll('p').forEach(p => p.append('\n'));
                let client_anamnese = div.textContent || div.innerText || "";

                let documentation = {
                    vorname: this.clients.find(client => client.id === this.selected_client.id).vorname,
                    nachname: this.clients.find(client => client.id === this.selected_client.id).nachname,
                    datum: dayjs().format('DD.MM.YYYY'),
                    erste_einheit: this.selectedClientStatistics.first_appointment,
                    letzte_einheit: this.selectedClientStatistics.last_appointment,
                    anzahl_einheiten: this.selectedClientStatistics.appointment_count + ' (' + this.selectedClientStatistics.appointment_sum + ' Minuten)',
                    frequenz: this.selectedClientStatistics.appointment_frequency + 'x pro Monat',
                    anamnese: client_anamnese,
                    verlauf: this.selectedClientAppointments.map(appointment => {
                        return (appointment.bezeichnung ? appointment.bezeichnung : 'Einheit') + ' am ' + dayjs(appointment.datum).format('DD.MM.YYYY') + ' um ' + dayjs(appointment.uhrzeit, 'HH:mm:ss').format('HH:mm') + ':\n' +
                            (appointment.dokumentation ? this.removeTags(appointment.dokumentation) : 'Diese Einheit wurde nicht dokumentiert.') + '\n\n'
                    }).reduce((accumulator, currentValue) => accumulator + currentValue, '')
                }

                let bucket = 'public-templates'
                let filename = 'template-documentation.docx'
                let path = 'documentation/'

                let content = await connector.downloadFile(this, bucket, path, filename);
                if (content === null) {
                    // error has already been shown
                    return;
                }

                let undefined_tags = [];

                const iModule = InspectModule()
                const zip = new PizZip(content)
                const doc = new Docxtemplater(zip, {
                    paragraphLoop: true,
                    linebreaks: true,
                    modules: [iModule],
                    parser: function (tag) {
                        return {
                            get: function (scope) {
                                let result = null;
                                // scope will be {user: "John"}
                                if (tag === ".") {
                                    result = scope;
                                } else {
                                    // Here we use the property "user" of the object {user: "John"}
                                    result = scope[tag.toLowerCase()];
                                }
                                return result;
                            },
                        };
                    },
                    nullGetter: function (part, scopeManager) {
                        if (!part.module) {
                            if (part.type === "placeholder" && part.value.toLowerCase() === "termin_dauer") {
                                return "-";
                            }
                            undefined_tags.push(part);
                            return "";
                        }
                        if (part.module === "rawxml") {
                            return "";
                        }
                        return "";
                    }
                })

                // Render the document (Replace variables)
                doc.render(documentation)

                const blob = doc.getZip().generate({
                    type: "blob",
                    mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                })

                let file_name = ('Dokumentation_' + documentation.nachname + '_' + documentation.vorname + '_' + documentation.datum).replaceAll(' ', '_')
                await saveAs(blob, file_name + ".docx")
            } catch (error) {
                this.$emit('showError', {
                    message: 'Ein Fehler ist beim Erstellen des Dokuments aufgetreten. Bitte versuche es erneut.',
                    timeout: 10000,
                    error: error
                });
            } 
        },

        handleInput(fieldName, value) {
            this[fieldName] = value;
        },
    },

    computed: {

        appointmentFileIds() {
            let ids = this.uploadedAppointmentFiles.map(file => file.appointment_id);
            return ids;
        },

        formattedClients() {
            return this.clients.map(client => {
                return {
                    name: client.nachname + ' ' + client.vorname,
                    adresse: client.plz ? client.adresse + ', ' + client.plz + ' ' + client.ort : client.adresse,
                    alter: client.geburtsdatum ? dayjs().diff(dayjs(client.geburtsdatum, 'DD.MM.YYYY'), 'year') : null,
                    svnr: client.svnr ? client.svnr : null,
                    id: client.id,
                    zusatztext: client.zusatztext,
                    merkmale: client.merkmale,
                    archiviert: client.archiviert
                };
            }).sort(this.sortListOfObjectsByString);
        },

        formattedClientsNotArchived() {
            if (this.$route.params.id) {
                // making sure to also include the opend one (as this might be archived)
                const id = parseInt(this.$route.params.id);
                return this.formattedClients.filter(client => !client.archiviert || client.id === id);
            } else {
                return this.formattedClients.filter(client => !client.archiviert);
            }
        },

        selectedClientStatistics() {

            if (this.selected_client === null) {
                return null
            }

            let client_appointments = this.appointments.filter(appointment => appointment.fk_klienten_id === this.selected_client.id).sort((a, b) => dayjs(b.datum) - dayjs(a.datum))
            if (client_appointments.length > 0) {

                let months_visiting = dayjs(client_appointments[0].datum).diff(dayjs(client_appointments[client_appointments.length - 1].datum), 'month', true)

                return {
                    first_appointment: dayjs(client_appointments[client_appointments.length - 1].datum).format('DD.MM.YYYY'),
                    last_appointment: dayjs(client_appointments[0].datum).format('DD.MM.YYYY'),
                    appointment_count: client_appointments.length,
                    appointment_sum: client_appointments.reduce((sum, appointment) => sum + appointment.dauer, 0),
                    // calculate the time in months between the first and last appointment, and divide the number of appointments by that
                    appointment_frequency: months_visiting === 0 ? 1 : Math.round((client_appointments.length / months_visiting + Number.EPSILON) * 10) / 10,

                }
            } else {
                return {
                    first_appointment: 'Noch Keine',
                    last_appointment: 'Noch Keine',
                    appointment_count: 0,
                    appointment_sum: 0,
                    appointment_frequency: 0,
                }
            }
        },

        selectedClientAppointments() {
            if (this.selected_client === null) {
                return [];
            }

            const filteredAppointments = this.appointments.filter(appointment => 
                // appointment.fk_klienten_id === this.selected_client.id && 
                dayjs(appointment.datum).format('YYYY-MM-DD') <= dayjs().format('YYYY-MM-DD')
            );

            if (this.sort_appointments_descending) {
                return filteredAppointments.sort((a, b) => dayjs(b.datum) - dayjs(a.datum)).map((appointment, index) => ({
                    ...appointment,
                    appointment_count: filteredAppointments.length - index
                }));
            } else {
                return filteredAppointments.sort((a, b) => dayjs(a.datum) - dayjs(b.datum)).map((appointment, index) => ({
                    ...appointment,
                    appointment_count: index + 1
                }));
            }
        },

        clientsQuickSelect() {
            return this.clients.filter(client => client.letzter_termin_nicht_dokumentiert !== null && !client.archiviert).map(client => {
                return {
                    name: client.nachname + ' ' + client.vorname,
                    id: client.id,
                    termine: client.n_termine,
                    nicht_dokumentiert: client.n_nicht_dokumentiert,
                    letzter_termin_nicht_dokumentiert: client.letzter_termin_nicht_dokumentiert,
                }
            }).sort((a, b) => dayjs(b.letzter_termin_nicht_dokumentiert) - dayjs(a.letzter_termin_nicht_dokumentiert)).slice(0, 12)

        }
    }
}
</script>

<style scoped>
.v-sheet.v-card {
  border-radius: 6px;
}

::v-deep .v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
    transform: rotate(0deg) !important;
}

.upload-area {
    border: dashed 2px lightgrey;
    border-radius: 20px;
    text-align: center;
}

.upload-area.drag-over {
    background-color: #f0f0f0;
}

.text-truncate {
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Additional styles for the file info container */
.file-info-container {
    min-width: 0;
    /* This ensures that the container can shrink as needed */
}

/* Ensure text truncation is applied */
.text-truncate-file {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-card {
    transition: box-shadow .3s ease;
    cursor: pointer;
    /* position: relative; */
    /* Ensure the positioning context for the speed dial */
}

span.break-words {
    display: block;
    /* Allows word-wrap to take effect */
    word-break: break-all;
    /* Breaks the word at any character */
}
::v-deep .p-container p {
    min-height: 1rem;
    margin-bottom: 0.1em;
}
</style>
