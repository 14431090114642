<template>
    <div>
        <v-dialog v-model="dialog_save_form" max-width="700px" persistent>
            <v-card>
                <v-card-title>Speichern als</v-card-title>
                <v-card-text class="mb-0 pb-0">
                    <v-text-field v-model="file_name" dense outlined label="Dateiname" />
                    <v-alert prominent text type="info">
                        Der Antrag wird in den Dokumenten des Klienten gespeichert. 
                        Um den Antrag später weiter zu bearbeiten, öffne das Antragsdokument des Klienten und klicke auf "Antrag bearbeiten".
                    </v-alert>
                </v-card-text>
                <v-card-actions class="mt-0 pt-0 px-5">
                    <v-spacer></v-spacer>
                    <v-btn text :color="$store.state.theme.red" :disabled="saving_form" @click="dialog_save_form = false">
                        Abbrechen
                    </v-btn>
                    <v-btn text :color="$store.state.theme.green" :disabled="!file_name" :loading="saving_form" @click="saveForm">
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_google_terms" persistent fullscreen>
            <v-card>
                <v-toolbar dark :color="$store.state.theme.primary">
                    <v-toolbar-title>Nutzungsbedingungen & Datenschutz</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="rejectTerms">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-card-text class="text-body-1 mt-5 text-justify">
                    <p>
                        Beim Ausfüllen dieses Antrags unterstützen wir dich mit <b>Vorschlägen</b> von einer Künstlichen Intelligenz (KI) von Google. 
                        Diese Vorschläge sollen dir helfen den Antrag auszufüllen. Es kann jedoch keine Garantie für die Richtigkeit der Vorschläge gegeben werden.
                        Um relevante Vorschläge machen zu können, benötigt die KI ein paar Informationen.<br/><br/>
                        <b>Da der Datenschutz für uns oberste Priorität hat, möchten wir dich darüber informieren, welche Daten die KI von Google benötigt, um relevante Vorschläge für dich machen zu können:</b>
                        <br/>
                        <ul>
                            <li>Die ICD-10 Diagnose & den GAF-Wert</li>
                            <li>Die Anamnese, den Behandlungsverlauf & die Zielsetzung auf dem Antragsformular</li>
                        </ul>
                        <br/>
                        <b>Bitte achte darauf, keine Namen oder andere persönliche Informationen in diese Felder einzugeben.</b>
                        Verwende am besten allgemeine Beschreibungen, da diese Anträge ja auch von Sachbearbeitern in den Krankenkassen durchgelesen werden.<br/><br/>
                        Google legt bei ihrer KI ebenso großen Wert auf Datenschutz und Sicherheit, deshalb werden die Daten von Google nur so lange gespeichert bis die KI-Vorschläge erstellt wurden.
                        Hier findest du weitere Informationen zu den <a href="https://cloud.google.com/vertex-ai/generative-ai/docs/data-governance?hl=de" target="_blank">Datenschutzbestimmungen von Google</a>.
                        <br/>
                        <b>Wenn du die KI-Vorschläge nicht verwendeset, werden diese Daten auch nicht an Google gesendet.</b>
                    </p>
                    <v-card outlined>
                        <v-card-title>
                            Hier siehst du, welche Daten für diese KI-Vorschläge an Google gesendet werden
                        </v-card-title>
                        <v-card-text>
                            <p>
                                <b>ICD-10 Diagnose:</b> {{ 
                                formData.icd_diagnosis.length > 0 ? formData.icd_diagnosis.map((diagnosis) =>
                                    diagnosis.code).join(', ') : 'Keine Diagnose angegeben' }}
                            </p>
                            <p>
                                <b>GAF-Wert:</b> {{ formData.gaf_value ? formData.gaf_value : 'Kein GAF-Wert angegeben' }}
                            </p>
                            <p>
                                <b>Anamnese:</b> {{ formData.field_1 ? formData.field_1 : 'Keine Anamnese angegeben' }}
                            </p>
                            <p>
                                <b>Behandlungsverlauf:</b> {{ formData.field_2 ? formData.field_2 : 'Kein Behandlungsverlauf angegeben' }}
                            </p>
                            <p>
                                <b>Zielsetzung:</b> {{ formData.field_3 ? formData.field_3 : 'Keine Zielsetzung angegeben' }}
                            </p>
                        </v-card-text>
                    </v-card>

                    <p class="mt-5">
                        Solltest du noch weitere Fragen haben, kannst du dich gerne an uns wenden.
                    </p>
                </v-card-text>
                <v-card-actions class="d-flex justify-center pb-5 mt-0 pt-0">
                    <v-btn outlined @click="rejectTerms" :color="$store.state.theme.red" class="mr-2">
                        Ablehnen
                    </v-btn>
                    <v-btn @click="acceptTerms" :color="$store.state.theme.green" dark class="ml-2">
                        Akzeptieren
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_gaf_explanation" persistent fullscreen>
            <v-card>
                <v-toolbar dark :color="$store.state.theme.primary">
                    <v-btn icon dark @click="dialog_gaf_explanation = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>GAF (Global Assessment of Functioning)</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pt-5">
                    <p class="text-body-1">
                        Das Global Assessment of Functioning (kurz GAF) ist eine Bewertungsskala aus der klinischen
                        Psychologie und Psychiatrie.
                        Sie wird verwendet, um das allgemeine Funktionsniveau einer Person zu erfassen.
                        Körperliche Erkrankungen und Umgebungsbedingungen sollen hierbei explizit nicht berücksichtigt
                        werden.
                        Die psychischen, sozialen und beruflichen Funktionen werden dabei auf einem hypothetischen Kontinuum
                        von psychischer Gesundheit bis Krankheit gedacht.
                    </p>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Bereich</th>
                                    <th class="text-left">GAF-Wert</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="text-left">100-91</td>
                                    <td class="text-left">
                                        Hervorragende Leistungsfähigkeit in einem breiten Spektrum von Aktivitäten;
                                        Schwierigkeiten im Leben scheinen nie außer Kontrolle zu geraten; keine Symptome.
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">90-81</td>
                                    <td class="text-left">
                                        Keine oder nur minimale Symptome (z. B. leichte Angst vor einer
                                        Prüfung), gute Leistungsfähigkeit in allen Gebieten, interessiert und eingebunden in
                                        ein breites Spektrum von Aktivitäten, sozial effektiv im Verhalten, im Allgemeinen
                                        zufrieden mit dem Leben, übliche Alltagsprobleme oder -sorgen (z. B. nur
                                        gelegentlicher Streit mit einem Familienmitglied).
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">80-71</td>
                                    <td class="text-left">
                                        Wenn Symptome vorliegen, sind dies vorübergehende oder zu
                                        erwartende Reaktionen auf psychosoziale Belastungsfaktoren (z. B.
                                        Konzentrationsschwierigkeiten nach einem Familienstreit); höchstens leichte
                                        Beeinträchtigung der sozialen beruflichen und schulischen Leistungsfähigkeit (z.B.
                                        zeitweises Zurückbleiben in der Schule).
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">70-61</td>
                                    <td class="text-left">
                                        Einige leichte Symptome (z. B. depressive Stimmung oder leichte
                                        Schlaflosigkeit) ODER einige leichte Schwierigkeiten hinsichtlich der sozialen,
                                        beruflichen oder schulischen Leistungsfähigkeit (z. B. gelegentliches
                                        Schuleschwänzen oder Diebstahl im Haushalt), aber im Allgemeinen relativ gute
                                        Leistungsfähigkeit; Bestehen einiger wichtiger zwischenmenschlicher Beziehungen.
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">60-51</td>
                                    <td class="text-left">
                                        Mäßig ausgeprägte Symptome (z. B. Affektverflachung,
                                        weitschweifige Sprache, gelegentliche Panikattacken) ODER mäßig ausgeprägte
                                        Schwierigkeiten bezüglich der sozialen, beruflichen oder schulischen
                                        Leistungsfähigkeit (z. B. wenige Freunde, Konflikte mit Arbeitskollegen,
                                        Schulkameraden oder Bezugspersonen).
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">50-41</td>
                                    <td class="text-left">
                                        Ernste Symptome (z. B. Suizidgedanken, schwere Zwangsrituale,
                                        häufige Ladendiebstähle) ODER eine Beeinträchtigung der sozialen, beruflichen und
                                        schulischen Leistungsfähigkeit (z. B. keine Freunde, Unfähigkeit, eine Arbeitsstelle
                                        zu behalten).
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">40-31</td>
                                    <td class="text-left">
                                        Einige Beeinträchtigungen in der Realitätskontrolle oder der
                                        Kommunikation (z. B. Sprache zeitweise unlogisch, unverständlich oder belanglos)
                                        ODER starke Beeinträchtigung in mehreren Bereichen, z. B. Arbeit oder Schule,
                                        familiäre Beziehungen, Urteilsvermögen, Denken oder Stimmung (z. B. ein Mann mit
                                        einer Depression vermeidet Freunde, vernachlässigt seine Familie und ist unfähig zu
                                        arbeiten; ein Kind schlägt häufig jüngere Kinder, ist zu Hause trotzig und versagt
                                        in der Schule).
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">30-21</td>
                                    <td class="text-left">
                                        Das Verhalten ist ernsthaft durch Wahnphänomene oder
                                        Halluzinationen beeinflusst ODER ernsthafte Beeinträchtigung der Kommunikation und
                                        des Urteilsvermögens (z. B. manchmal inkohärent, handelt grob inadäquat, starkes
                                        Eingenommensein von Selbstmordgedanken) ODER Leistungsunfähigkeit in fast allen
                                        Bereichen (z. B. bleibt den ganzen Tag im Bett, hat keine Arbeit, kein Zuhause und
                                        keine Freunde).
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">20-11</td>
                                    <td class="text-left">
                                        Selbst- und Fremdgefährdung (z. B. Selbstmordversuche ohne
                                        eindeutige Todesabsicht, häufig gewalttätig, manische Erregung) ODER ist
                                        gelegentlich nicht in der Lage, die geringste Hygiene aufrechtzuerhalten (z. B.
                                        schmiert mit Kot) ODER grobe Beeinträchtigung der Kommunikation (größtenteils
                                        inkohärent oder stumm).</td>
                                </tr>
                                <tr>
                                    <td class="text-left">10-1</td>
                                    <td class="text-left">
                                        Ständige Gefahr, sich oder andere schwer zu verletzen (z. B.
                                        wiederholte Gewaltanwendung) ODER anhaltende Unfähigkeit, die minimale persönliche
                                        Hygiene aufrechtzuerhalten ODER ernsthafter Selbstmordversuch mit eindeutiger
                                        Todesabsicht.
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-left">0</td>
                                    <td class="text-left">Unzureichende Informationen</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <p class="text-h5 my-5">Anleitung</p>
                    <p class="text-body-1 mt-5">
                        <b>Schritt 1:</b><br>
                        Beginne mit dem höchsten Niveau und frage bei jeder Abstufung: "Ist entweder der Schweregrad der
                        Symptome oder die Funktionsbeeinträchtigung niedriger als auf dieser Stufe beschrieben?"
                        <br><b>Schritt 2:</b><br>
                        Die Skala ist nach unten weiterzuverfolgen, bis das Niveau erreicht wird, das am besten zum
                        Schweregrad der Symptome oder zu der Funktionsbeeinträchtigung passt, je nachdem welches von beiden
                        niedriger ausgeprägt ist.
                        <br><b>Schritt 3:</b><br>
                        Zum Vergleich ist das nächstniedrige Niveau zu betrachten, um zu verhindern, dass zu früh
                        entschieden wurde.
                        Dieses Niveau sollte für sowohl den Schweregrad der Symptome als auch für die
                        Funktionsbeeinträchtigung zu niedrig sein.
                        Ist dies der Fall, so hat man das passende GAF-Niveau erreicht (und fährt mit Schritt 4 fort).
                        Ist dies nicht der Fall, sollte zu Schritt 2 zurückgegangen und die Skala weiter nach unten verfolgt
                        werden.
                        <br><b>Schritt 4:</b><br>
                        Um den spezifischen GAF-Wert innerhalb des ausgewählten Dezilbereichs festzulegen, ist zu erwägen,
                        ob die Person hinsichtlich ihrer Funktionen am oberen oder unteren Rand des Bereichs einzuordnen
                        ist.
                        Ein Beispiel wäre eine Person, die Stimmen hört, welche jedoch das Verhalten nicht beeinflussen
                        (z.B. jemand mit seit langem bestehender Schizophrenie, der die Halluzinationen als Teil der
                        Erkrankung akzeptiert).
                        Wenn die Stimmen relativ selten auftreten (z.B. höchstens einmal wöchentlich), wäre ein Wert von 39
                        oder 40 angemessen.
                        Im Gegensatz dazu wäre ein Wert von 31 oder 32 passend, wenn die Person fast ständig Stimmen hört.
                    </p>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_prompt_download" persistent max-width="800px">
            <v-card>
                <v-toolbar dark :color="$store.state.theme.primary">
                    <v-toolbar-title>Erinnerung</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pt-5 text-subtitle-1 text-justify">
                    <p>
                        Stelle sicher, dass du den Antrag gespeichert hast, wenn du ihn später weiter bearbeiten möchtest.
                        Anderenfalls gehen deine Änderungen verloren.
                        Du kannst den Antrag ebenso herunterladen, und gegebenenfalls noch direkt Änderungen im PDF Dokument vornehmen.
                    </p>
                </v-card-text>
                <v-card-actions class="pa-5">
                    <v-btn dark :color="$store.state.theme.primary" class="mr-5" @click="dialog_prompt_download = false">Weiter bearbeiten</v-btn>
                    <v-btn dark :color="$store.state.theme.red" outlined @click="closeDialog">Schließen</v-btn>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="!client">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <v-btn outlined disabled class="mr-5" elevation="0" v-bind="attrs" v-on="on" @click="showSaveDialog">
                                    <v-icon left>mdi-content-save</v-icon>
                                    Speichern
                                </v-btn>
                            </div>
                        </template>
                        <span>Wähle einen Klienten aus um den Antrag zu speichern</span>
                    </v-tooltip>
                    <v-btn v-else outlined class="mr-5" elevation="0" @click="showSaveDialog">
                        <v-icon left>mdi-content-save</v-icon>
                        Speichern
                    </v-btn>
                    <v-btn dark :color="$store.state.theme.green" @click="downloadForm">Herunterladen</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
        <v-dialog v-model="dialog" persistent fullscreen no-click-animation>
            <v-card color="grey lighten-4">
                <v-toolbar dark :color="$store.state.theme.primary">
                    <v-btn icon dark @click="dialog_prompt_download = true">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Antrag auf Kostenzuschuss</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-tooltip bottom v-if="!client">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <v-btn outlined disabled class="mr-5" elevation="0" v-bind="attrs" v-on="on" @click="showSaveDialog">
                                    <v-icon left>mdi-content-save</v-icon>
                                    Speichern
                                </v-btn>
                            </div>
                        </template>
                        <span>Wähle einen Klienten aus um den Antrag zu speichern</span>
                    </v-tooltip>
                    <v-btn v-else outlined class="mr-5" elevation="0" @click="showSaveDialog">
                        <v-icon left>mdi-content-save</v-icon>
                        Speichern
                    </v-btn>
                    <v-btn :color="$store.state.theme.green" dark elevation="1" @click="downloadForm">
                        <v-icon left>mdi-printer</v-icon>
                        Herunterladen
                    </v-btn>
                </v-toolbar>
                <v-tabs grow v-model="current_tab" class="mt-3" :background-color="$store.state.theme.background">
                    <v-tab>
                        <v-icon left>mdi-information</v-icon>
                        Antragsinformationen
                    </v-tab>
                    <v-tab>
                        <v-icon left>mdi-account</v-icon>
                        Angaben zum Patienten
                    </v-tab>
                    <v-tab>
                        <v-icon left>mdi-folder-information</v-icon>
                        Behandlungsinformationen
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="current_tab" style="background-color: #f5f5f5">
                    <v-tab-item eager>
                        <v-row class="px-5 py-5">
                            <v-col cols="6">
                                <v-card>
                                    <v-card-title class="d-flex justify-space-between">
                                        Daten der versicherten Person
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="d-flex align-center">
                                            <v-autocomplete v-model="client"
                                                :loading="!loaded_customers || loading_client_data" loader-height="3"
                                                class="" :disabled="!loaded_customers" :items="sortedCustomers"
                                                item-text="name" item-value="fk_klienten_id" return-object
                                                label="Klient" outlined dense clear-icon="mdi-close-circle" clearable
                                                prepend-inner-icon="mdi-account">
                                                <template v-slot:item="{ item }">
                                                    <div class="d-flex align-center">
                                                        <span>{{ item.name }}</span>
                                                        <v-chip v-for="merkmal in item.merkmale"
                                                            :key="'chip-' + merkmal.id" class="ml-2" small>{{
                                                            merkmal.merkmal }}</v-chip>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <div class="d-flex align-center">
                                                        <span>{{ item.name }}</span>
                                                        <v-chip v-for="merkmal in item.merkmale"
                                                            :key="'chip-selection-' + merkmal.id" class="ml-2" small>{{
                                                            merkmal.merkmal }}</v-chip>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                            <v-checkbox v-model="formData.self_insured" label="Patient/in ist selbstversichert" class="mt-0 pt-0 ml-5"></v-checkbox>
                                        </div>
                                        <div class="d-flex">
                                            <v-text-field class="mr-2" outlined dense label="Familienname Patient/in" v-model="formData.lastname" />
                                            <v-text-field class="mx-2" outlined dense label="Vorname Patient/in" v-model="formData.firstname" />
                                            <v-text-field class="ml-2" outlined dense label="Versicherungsnr. Patient/in" v-model="formData.svnr" />
                                        </div>
                                        <v-textarea rows="2" auto-grow outlined dense label="Anschrift Patient/in" v-model="formData.address" />
                                        <div v-if="formData.self_insured === false" class="d-flex">
                                            <v-text-field class="mr-2" outlined dense label="Versicherte/r" v-model="formData.insurer_name" />
                                            <v-text-field class="ml-2" outlined dense label="Versicherungsnr. Versicherte/r" v-model="formData.insurer_svnr" />
                                        </div>
                                    </v-card-text>
                                </v-card>
                                <v-card class="mt-5">
                                    <v-card-title class="d-flex align-start justify-space-between pb-0">
                                        <span>Antragsinformationen</span>
                                        <v-radio-group v-model="formData.application_form" row class="d-flex align-center mt-0 ">
                                            <v-radio label="Erstantrag" value="0"></v-radio>
                                            <v-radio label="Folgeantrag" value="1"></v-radio>
                                            <v-radio label="Behandlerwechsel" value="2"></v-radio>
                                        </v-radio-group>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-autocomplete v-model="selected_insurer" :items="insurer"
                                            item-text="displayName" item-value="id" label="Versicherungsträger"
                                            return-object outlined dense clearable clear-icon="mdi-close-circle" />

                                        <div class="d-flex">
                                            <v-text-field class="mr-2" outlined dense label="Versicherungsträger" v-model="formData.insurance" hide-details />
                                            <v-text-field class="mx-2" outlined dense label="Bundesland" v-model="formData.insurance_state" hide-details />
                                            <v-text-field class="mx-2" outlined dense label="Straße & Nr." v-model="formData.insurance_street" hide-details />
                                            <v-text-field class="ml-2" outlined dense label="PLZ & Ort" v-model="formData.insurance_place" hide-details />
                                        </div>
                                    </v-card-text>
                                </v-card>
                                <v-card class="mt-5">
                                    <v-card-title class="d-flex justify-space-between">
                                        Daten des klinischen Psychologen
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="d-flex">
                                            <v-text-field class="mr-2" outlined dense label="Name" v-model="formData.therapist_name" />
                                            <v-text-field class="ml-2" outlined dense label="VPNR" v-model="formData.therapist_nr" />
                                        </div>
                                        <v-textarea rows="2" auto-grow outlined dense label="Praxisadresse" v-model="formData.therapist_address" hide-details />
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="6" ref="iframe-pdf-1">
                                <v-card full-width class="d-flex flex-column align-center justify-center" rounded>
                                    <span class="text-h6">Vorschau</span>
                                    <span class="text-caption">(bitte keine Änderungen direkt in der Vorschau vornehmen)</span>
                                    <iframe v-if="form_url" :key="form_url" :src="form_url + '#toolbar=0'" @load="onIframeLoad(1)" :style="{
                                        width: '100%',
                                        height: `${scaledHeight}px`,
                                        border: 'none',
                                    }">
                                    </iframe>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>

                    <v-tab-item eager>
                        <v-row class="px-5 py-5">
                            <v-col cols="6">
                                <v-row>
                                    <v-col cols="12">
                                        <v-card style="height: 100%;">
                                            <v-card-title class="text-h6 d-flex">
                                                Diagnose
                                            </v-card-title>
                                            <v-card-text>
                                                <div class="d-flex mb-5">
                                                    <v-autocomplete outlined v-model="formData.icd_diagnosis" :items="icdCodes"
                                                        hide-details item-text="code" item-value="short_code"
                                                        :dense="formData.icd_diagnosis.length === 0" label="Diagnose nach ICD-10"
                                                        placeholder="Suchbegriff eingeben" return-object multiple
                                                        deletable-chips chips :menu-props="{ maxWidth: 750 }">
                                                    </v-autocomplete>
                                                </div>
                                                <p class="text-subtitle-2">Vorschläge:</p>
                                                <v-chip v-if="suggestedDiagnoses.length === 0" class="mr-2 mb-2"
                                                    @click="selectDiagnosis({ 'code': 'F32.1 Mittelgradige depressive Episode', 'short_code': 'F32.1' })">
                                                    <v-icon left>mdi-plus</v-icon>
                                                    <span>F32.1 Mittelgradige depressive Episode</span>
                                                </v-chip>
                                                <v-chip v-if="suggestedDiagnoses.length === 0" class="mr-2 mb-2"
                                                    @click="selectDiagnosis({ 'code': 'F43.2 Anpassungsstörungen', 'short_code': 'F43.2' })">
                                                    <v-icon left>mdi-plus</v-icon>
                                                    <span>F43.2 Anpassungsstörungen</span>
                                                </v-chip>
                                                <v-chip outlined color="deep-purple accent-4" v-for="(diagnosis, index) in suggestedDiagnoses" :key="index" class="mt-0 pt-0 mr-2 mb-2" @click="selectDiagnosis(diagnosis)">
                                                    <v-icon left>mdi-plus</v-icon>
                                                    {{ diagnosis.code }}
                                                </v-chip>
                                                <v-chip class="mr-2 mb-2" @click="getRecommendations('getICDDiagnosis')" :disabled="loading_getICDDiagnosis" color="success" outlined>
                                                    <v-progress-circular v-if="loading_getICDDiagnosis" indeterminate size="15" width="3" class="mr-2" />
                                                    <v-icon v-else left small>mdi-auto-fix</v-icon>
                                                    <span class="font-weight-medium">Andere Diagnosen vorschlagen</span>
                                                </v-chip>

                                                <div class="text-h6 black--text mt-3">
                                                    GAF-Wert
                                                    <v-btn icon>
                                                        <v-icon @click="dialog_gaf_explanation = true">
                                                            mdi-information
                                                        </v-icon>
                                                    </v-btn>
                                                </div>
                                                <div class="d-flex mt-5 ">
                                                    <v-text-field class="mr-5" style="max-width: 50px;" v-model="formData.gaf_value" @change="limitGAFValue" dense />
                                                    <v-slider v-model="formData.gaf_value" :color="gaf_color" min="0" max="100" />
                                                    <v-btn elevation="0" @click="getRecommendations('getGAF')" :loading="loading_getGAF" class="ml-5" rounded>
                                                        <v-icon left>mdi-auto-fix</v-icon>
                                                        Vorschlag
                                                    </v-btn>
                                                </div>
                                                <div class="d-flex flex-column">
                                                    <span>
                                                        <b>Bewertung:</b> {{ gafValueDescription }}
                                                    </span>
                                                    <p v-if="suggested_gaf_description && suggested_gaf_description">
                                                        <b>Begründung:</b> {{ suggested_gaf_description }}
                                                    </p>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>

                                <v-card class="mt-5">
                                    <v-card-title>
                                        Berufstätigkeit & psychiatrische Behandlung
                                    </v-card-title>
                                    <v-card-text>
                                        <v-row>
                                            <v-col class="d-flex flex-column">
                                                <span class="text-overline mt-0 pt-0">Berufstätig</span>
                                                <v-btn-toggle v-model="formData.has_job" dense mandatory>
                                                    <v-btn>
                                                        Ja
                                                    </v-btn>
                                                    <v-btn>
                                                        Nein
                                                    </v-btn>
                                                    <v-btn>
                                                        Keine Angabe
                                                    </v-btn>
                                                </v-btn-toggle>

                                                <v-text-field class="mt-5" v-if="formData.has_job !== 1" outlined dense label="Art der Berufstätigkeit" v-model="formData.job" hide-details />
                                            </v-col>
                                            <v-col class="d-flex flex-column">
                                                <span class="text-overline mt-0 pt-0">Psychiatrische Behandlung</span>
                                                <v-btn-toggle v-model="formData.has_psychiatrist" dense mandatory>
                                                    <v-btn>
                                                        Ja
                                                    </v-btn>
                                                    <v-btn>
                                                        Nein
                                                    </v-btn>
                                                    <v-btn>
                                                        Keine Angabe
                                                    </v-btn>
                                                </v-btn-toggle>
                                                <v-text-field class="mt-5" v-if="formData.has_psychiatrist !== 1" outlined dense
                                                    label="Name des Facharztes / der Fachärztin" v-model="formData.psychiatrist"
                                                    hide-details />
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>

                                <v-card class="mt-5">
                                    <v-card-title>
                                        Behandlungsbezogene Anamnese (in Stichworten)
                                    </v-card-title>
                                    <v-card-text>
                                        <v-textarea rows="4" auto-grow outlined dense v-model="formData.field_1"
                                            placeholder="Hier die Behandlungsbezogene Anamnese in Stichworten angeben..." />
                                        <p class="text-subtitle-2">Vorschläge:</p>
                                        <v-chip class="mr-2 mb-2"
                                            @click="addText('field_1', 'Traumatische Ereignisse')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Traumatische Ereignisse</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="addText('field_1', 'Substanzabusus')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Substanzabusus</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2"
                                            @click="addText('field_1', 'Krankenstände im Zusammenhang mit der Erkrankung')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Krankenstände im Zusammenhang mit der Erkrankung</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="addText('field_1', 'Stationäre Aufenthalte')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Stationäre Aufenthalte</span>
                                        </v-chip>
                                        <v-chip v-for="(item, id) in anamnese_recommendations" :key="id"
                                            @click="addText('field_1', item)" class="mr-2 mb-2"
                                            color="deep-purple accent-4" outlined>
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>{{ item }}</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="getRecommendations('getAnamnese')"
                                            :disabled="loading_getAnamnese" color="success" outlined>
                                            <v-progress-circular v-if="loading_getAnamnese" indeterminate size="15"
                                                width="3" class="mr-2" />
                                            <v-icon v-else left small>mdi-auto-fix</v-icon>
                                            <span class="font-weight-medium">{{ anamnese_recommendations.length === 0 ?
                                                'Weitere' : 'Andere' }} Textbausteine vorschlagen</span>
                                        </v-chip>
                                    </v-card-text>
                                </v-card>

                                <v-card class="mt-5">
                                    <v-card-title>
                                        Angaben zum bisherigen Behandlungsverlauf & Störungsintensität
                                    </v-card-title>
                                    <v-card-text>
                                        <v-textarea rows="4" auto-grow outlined dense v-model="formData.field_2"
                                            placeholder="Hier die Angaben zum bisherigen Behandlungsverlauf & Störungsintensität ausfüllen..." />
                                        <p class="text-subtitle-2">Vorschläge:</p>
                                        <v-chip class="mr-2 mb-2"
                                            @click="addText('field_2', 'Hohe Behandlungsmotivation')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Hohe Behandlungsmotivation</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="addText('field_2', 'Gute Arbeitsbeziehung')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Gute Arbeitsbeziehung</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="addText('field_2', 'Hohe Compliance')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Hohe Compliance</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="addText('field_2', 'Psychoedukation')">
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>Psychoedukation</span>
                                        </v-chip>
                                        <v-chip v-for="(item, id) in progress_recommendations" :key="'progress-' + id"
                                            @click="addText('field_2', item)" class="mr-2 mb-2"
                                            color="deep-purple accent-4" outlined>
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>{{ item }}</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="getRecommendations('getProgress')"
                                            :disabled="loading_getProgress" color="success" outlined>
                                            <v-progress-circular v-if="loading_getProgress" indeterminate size="15"
                                                width="3" class="mr-2" />
                                            <v-icon v-else left small>mdi-auto-fix</v-icon>
                                            <span class="font-weight-medium">{{ progress_recommendations.length === 0 ?
                                                'Weitere' : 'Andere' }} Textbausteine vorschlagen</span>
                                        </v-chip>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="6" ref="iframe-pdf-2">
                                <v-card full-width class="d-flex flex-column align-center justify-center" rounded>
                                    <span class="text-h6">Vorschau</span>
                                    <span class="text-caption">(bitte keine Änderungen direkt in der Vorschau
                                        vornehmen)</span>

                                    <iframe v-if="form_url" :key="form_url" :src="form_url + '#toolbar=0'" @load="onIframeLoad(2)" :style="{
                                        width: '100%',
                                        height: `${scaledHeight}px`,
                                        border: 'none',
                                    }">
                                    </iframe>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>

                    <v-tab-item eager>
                        <v-row class="px-5 py-5">
                            <v-col cols="6">
                                <v-card>
                                    <v-card-title class="d-flex justify-space-between">
                                        Zielsetzung im Bezug auf die ICD-10 Diagnose & GAF-Wert
                                    </v-card-title>
                                    <v-card-text>
                                        <v-textarea rows="4" auto-grow outlined dense
                                            placeholder="Hier die Zielsetzung im Bezug auf die ICD-10 Diagnose & GAF-Wert angeben..."
                                            v-model="formData.field_3" />
                                        <v-chip class="mr-2 mb-2 v-chip-custom" @click="addText('field_3', goal)"
                                            v-for="(goal, index) in suggested_goals" :key="index"
                                            color="deep-purple accent-4" outlined>
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>{{ goal }}</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="getRecommendations('getGoals')"
                                            :disabled="loading_getGoals" color="success" outlined>
                                            <v-progress-circular v-if="loading_getGoals" indeterminate size="15"
                                                width="3" class="mr-2" />
                                            <v-icon v-else left small>mdi-auto-fix</v-icon>
                                            <span class="font-weight-medium">{{ suggested_goals.length === 0 ? '' :
                                                'Andere' }}
                                                Zielsetzungen vorschlagen</span>
                                        </v-chip>
                                    </v-card-text>
                                </v-card>

                                <v-card class="mt-5">
                                    <v-card-title class="d-flex justify-space-between">
                                        Beantragte Leistungen
                                    </v-card-title>
                                    <v-card-text>
                                        <div class="d-flex">
                                            <v-text-field class="mr-2" v-model="formData.therapy_start" outlined dense
                                                label="Beginn der Behandlung" />
                                            <v-text-field class="mx-2" v-model="formData.therapy_units_past" outlined dense
                                                label="Bisher stattgefundene Einheiten" />
                                            <v-text-field class="ml-2" v-model="formData.therapy_units_other" outlined dense
                                                label="Anzahl Einheiten bei anderen klin. Psycholog/innen oder Psychotherapeut/innen" />
                                        </div>
                                        <div class="d-flex">
                                            <v-text-field class="mr-2" v-model="formData.therapy_units" outlined dense
                                                label="Beantragte Einheiten" />
                                            <v-text-field class="mx-2" v-model="formData.therapy_units_total" outlined dense
                                                label="Voraussichtlich benötigte Gesamteinheiten" />
                                            <v-text-field class="ml-2" v-model="formData.therapy_frequency" outlined dense
                                                label="Geplante Frequenz" />
                                        </div>
                                        <v-textarea rows="3" v-model="formData.therapy_reason" auto-grow outlined dense
                                            label="Begründung des weiteren Therapiebedarfs" />
                                        <v-chip class="mr-2 mb-2 v-chip-custom"
                                            v-for="(reason, index) in suggested_reasons" :key="index"
                                            @click="addText('therapy_reason', reason, '. ')"
                                            color="deep-purple accent-4" outlined>
                                            <v-icon left>mdi-plus</v-icon>
                                            <span>{{ reason }}</span>
                                        </v-chip>
                                        <v-chip class="mr-2 mb-2" @click="getRecommendations('getReasons')"
                                            :disabled="loading_getReasons" color="success" outlined>
                                            <v-progress-circular v-if="loading_getReasons" indeterminate size="15"
                                                width="3" class="mr-2" />
                                            <v-icon v-else left small>mdi-auto-fix</v-icon>
                                            <span class="font-weight-medium">{{ suggested_reasons.length === 0 ? '' :
                                                'Andere'
                                                }}
                                                Begründungen vorschlagen</span>
                                        </v-chip>

                                        <p class="text-h6 mt-5 mb-0">Geplantes Setting</p>
                                        <div class="d-flex align-center">
                                            <v-radio-group v-model="formData.therapy_setting" row>
                                                <v-radio label="Einzelsetting" value="0"></v-radio>
                                                <v-radio label="Gruppensetting" value="1"></v-radio>
                                                <v-radio label="Anderes Setting" value="2"></v-radio>
                                            </v-radio-group>
                                            <v-text-field v-if="formData.therapy_setting === '2'" placeholder="Art des Settings"
                                                v-model="formData.therapy_setting_other_text" class="ml-2" outlined dense
                                                hide-details />
                                        </div>

                                        <span class="text-subtitle-1">Begleitende Behandlung von Bezugspersonen</span>
                                        <div class="d-flex align-center">
                                            <v-text-field v-model="formData.other_persons" label="Art des Bezuges" outlined
                                                dense />
                                        </div>

                                        <span class="text-subtitle-1">Anmerkungen</span>
                                        <div class="d-flex align-center">
                                            <v-text-field v-model="formData.notes" outlined dense />
                                        </div>
                                    </v-card-text>

                                </v-card>
                            </v-col>
                            <v-col cols="6" ref="iframe-pdf-3">
                                <v-card full-width class="d-flex flex-column align-center justify-center" rounded>
                                    <span class="text-h6">Vorschau</span>
                                    <span class="text-caption">(bitte keine Änderungen direkt in der Vorschau
                                        vornehmen)</span>

                                    <iframe v-if="form_url" :key="form_url" :src="form_url + '#toolbar=0'" @load="onIframeLoad(3)" :style="{
                                        width: '100%',
                                        height: `${scaledHeight}px`,
                                        border: 'none',
                                    }">
                                    </iframe>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { FunctionsHttpError } from "@supabase/supabase-js";
import { supabase } from '../supabase';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { debounce } from 'lodash';
import { saveAs } from 'file-saver';
import connector from '../helpers/supabase-connector.js';
import cipher from '@/helpers/cipher';
import dayjs from 'dayjs';
import json from '../assets/icdCodes.json';

export default {

    props: ['session', 'current_file'],

    data() {
        return {

            resizeHandler: null,
            iframeLoaded: false,
            clientWidth: 0,
            formWatchers: [],
            isMounted: true,  // Track if the component is still mounted

            isPrefilling: false,
            debouncedRenderPDF: debounce(this.renderPDF, 500),

            formData: {
                application_form: '0',
                insurance: '',
                insurance_state: '',
                insurance_street: '',
                insurance_place: '',
                self_insured: true,
                insurer_name: '',
                insurer_svnr: '',
                lastname: '',
                firstname: '',
                address: '',
                svnr: '',
                therapist_name: '',
                therapist_nr: '',
                therapist_address: '',
                icd_diagnosis: [],
                gaf_value: 0,
                has_job: 0,
                job: '',
                has_psychiatrist: 0,
                psychiatrist: '',
                field_1: '',
                field_2: '',
                field_3: '',
                therapy_setting: '0',
                therapy_start: '',
                therapy_units: '',
                therapy_units_past: '',
                therapy_units_other: '',
                therapy_units_total: '',
                therapy_frequency: '',
                therapy_reason: '',
                therapy_setting_other_text: '',
                other_persons: '',
                notes: '',
            },

            formDataDefault: {}, 

            pdfDoc: null,
            pdfForm: null,
            pdfPages: [],
            pdfFont: null,

            formConfig: {
                page1: {
                    applicationForm: [
                        { name: 'Erstantrag', type: 'checkbox', x: 77, y: 546, width: 10, height: 10, formDataKey: 'application_form', value: '0' },
                        { name: 'Folgeantrag', type: 'checkbox', x: 228, y: 546, width: 10, height: 10, formDataKey: 'application_form', value: '1' },
                        { name: 'Behandlerwechsel', type: 'checkbox', x: 380, y: 546, width: 10, height: 10, formDataKey: 'application_form', value: '2' },
                    ],
                    insuranceInfo: [
                        { name: 'insurance', type: 'text', x: 215, y: 618, width: 190, height: 14, formDataKey: 'insurance' },
                        { name: 'insurance_state', type: 'text', x: 215, y: 603, width: 190, height: 13, formDataKey: 'insurance_state' },
                        { name: 'insurance_street', type: 'text', x: 215, y: 588, width: 190, height: 13, formDataKey: 'insurance_street' },
                        { name: 'insurance_place', type: 'text', x: 215, y: 573, width: 190, height: 13, formDataKey: 'insurance_place' },
                    ],
                    personalInfo: [
                        { name: 'firstname', type: 'text', x: 80, y: 405, width: 250, height: 16, formDataKey: 'firstname' },
                        { name: 'svnr', type: 'text', x: 370, y: 405, width: 140, height: 16, formDataKey: 'svnr' },
                        { name: 'lastname', type: 'text', x: 80, y: 441, width: 430, height: 16, formDataKey: 'lastname' },
                        { name: 'address', type: 'text', x: 80, y: 352, width: 430, height: 36, multiline: true, formDataKey: 'address' },
                    ],
                    insurerInfo: [
                        { name: 'insurer_name', type: 'text', x: 80, y: 311, width: 250, height: 16, formDataKey: 'insurer_name' },
                        { name: 'insurer_svnr', type: 'text', x: 370, y: 311, width: 140, height: 16, formDataKey: 'insurer_svnr' },
                    ],
                    therapistInfo: [
                        {
                            name: 'therapist',
                            type: 'text',
                            x: 80,
                            y: 130,
                            width: 430,
                            height: 78,
                            multiline: true,
                            dependsOn: ['therapist_name', 'therapist_nr', 'therapist_address'],
                            getValue: (component) => `${component.formData.therapist_name}\n${component.formData.therapist_nr}\n${component.formData.therapist_address}`,
                        },
                    ],
                },
                page2: {
                    diagnosisInfo: [
                        {
                            name: 'icd_diagnosis',
                            type: 'text',
                            x: 365,
                            y: 692,
                            width: 170,
                            height: 16,
                            formDataKey: 'icd_diagnosis',
                            getValue: (component) => component.formData.icd_diagnosis.map((diagnosis) => diagnosis.short_code).join(', '),
                        },
                        { name: 'gaf_value', type: 'text', x: 365, y: 664, width: 170, height: 16, formDataKey: 'gaf_value' },
                    ],
                    jobInfo: [
                        { name: 'has_job_yes', type: 'checkbox', x: 363, y: 641, width: 10, height: 10, formDataKey: 'has_job', value: 0 },
                        { name: 'has_job_no', type: 'checkbox', x: 424, y: 641, width: 10, height: 10, formDataKey: 'has_job', value: 1 },
                        { 
                            name: 'job', 
                            type: 'text', 
                            x: 365, 
                            y: 625, 
                            width: 180, 
                            height: 14, 
                            formDataKey: 'job',
                            conditionalDisplay: {
                                dependsOn: 'has_job',
                                showWhen: 0
                            }
                        },
                    ],
                    psychiatristInfo: [
                        { name: 'has_psychiatrist_yes', type: 'checkbox', x: 363, y: 593, width: 10, height: 10, formDataKey: 'has_psychiatrist', value: 0 },
                        { name: 'has_psychiatrist_no', type: 'checkbox', x: 424, y: 593, width: 10, height: 10, formDataKey: 'has_psychiatrist', value: 1 },
                        { 
                            name: 'psychiatrist', 
                            type: 'text', 
                            x: 365, 
                            y: 552, 
                            width: 180, 
                            height: 16, 
                            formDataKey: 'psychiatrist',
                            conditionalDisplay: {
                                dependsOn: 'has_psychiatrist',
                                showWhen: 0
                            }
                        },
                    ],
                    anamnesis: [
                        { name: 'field_1', type: 'text', x: 80, y: 349, width: 470, height: 130, multiline: true, formDataKey: 'field_1' },
                    ],
                    progress: [
                        { name: 'field_2', type: 'text', x: 80, y: 87, width: 470, height: 182, multiline: true, formDataKey: 'field_2' },
                    ],
                },
                page3: {
                    goals: [
                        { name: 'field_3', type: 'text', x: 80, y: 650, width: 470, height: 83, multiline: true, formDataKey: 'field_3' },
                    ],
                    therapyInfo: [
                        { name: 'therapy_start', type: 'text', x: 472, y: 606, width: 70, height: 16, formDataKey: 'therapy_start' },
                        { name: 'therapy_units_past', type: 'text', x: 472, y: 574, width: 70, height: 16, formDataKey: 'therapy_units_past' },
                        { name: 'therapy_units_other', type: 'text', x: 472, y: 544, width: 70, height: 16, formDataKey: 'therapy_units_other' },
                        { name: 'therapy_units', type: 'text', x: 472, y: 514, width: 70, height: 16, formDataKey: 'therapy_units' },
                        { name: 'therapy_units_total', type: 'text', x: 472, y: 483, width: 70, height: 16, formDataKey: 'therapy_units_total' },
                    ],
                    reason: [
                        { name: 'therapy_reason', type: 'text', x: 80, y: 403, width: 470, height: 47, multiline: true, formDataKey: 'therapy_reason' },
                    ],
                    frequency: [
                        { name: 'therapy_frequency', type: 'text', x: 290, y: 379, width: 250, height: 16, formDataKey: 'therapy_frequency' },
                    ],
                    setting: [
                        { name: 'therapy_setting_single', type: 'checkbox', x: 290, y: 364, width: 10, height: 10, formDataKey: 'therapy_setting', value: '0' },
                        { name: 'therapy_setting_group', type: 'checkbox', x: 290, y: 348, width: 10, height: 10, formDataKey: 'therapy_setting', value: '1' },
                        { name: 'therapy_setting_other', type: 'checkbox', x: 290, y: 333, width: 10, height: 10, formDataKey: 'therapy_setting', value: '2' },
                        { name: 'therapy_setting_other_text', type: 'text', x: 353, y: 331, width: 190, height: 16, formDataKey: 'therapy_setting_other_text' },
                    ],
                    otherInfo: [
                        { name: 'other_persons', type: 'text', x: 290, y: 302, width: 250, height: 14, formDataKey: 'other_persons' },
                        { name: 'notes', type: 'text', x: 80, y: 213, width: 470, height: 52, multiline: true, formDataKey: 'notes' },
                    ],
                },
            },

            pdfPageHeight: 0,
            pdfPageWidth: 0,
            windowWidth: window.innerWidth,

            dialog_prompt_download: false,
            dialog_save_form: false,
            file_name: null,
            saving_form: false,

            icdCodes: [],

            dialog_google_terms: false,
            dialog_gaf_explanation: false,

            loading_getGoals: false,
            loading_getReasons: false,
            loading_getICDDiagnosis: false,
            loading_getAnamnese: false,
            loading_getProgress: false,
            loading_getGAF: false,

            n_customers: null,
            customers: [],
            client: null,

            current_tab: 0,
            loaded_customers: false,
            suggested_diagnosis: null,
            suggested_gaf_description: '',
            suggested_goals: [],
            suggested_reasons: [],
            anamnese_recommendations: [],
            progress_recommendations: [],
            dialog: false,

            pdf_document: null,
            form_url: null,

            selected_insurer: null,

            // if update, also change in Klienten.vue
            // and also in the DB (versicherungen)
            insurer: [
                { "id": 1, "name": "ÖGK", "state": "Burgenland", "address": "Siegfried Marcus-Straße 5", "place": "7000 Eisenstadt", "displayName": "ÖGK (Burgenland)" },
                { "id": 2, "name": "ÖGK", "state": "Kärnten", "address": "Kempfstraße 8", "place": "9021 Klagenfurt am Wörthersee", "displayName": "ÖGK (Kärnten)" },
                { "id": 3, "name": "ÖGK", "state": "Niederösterreich", "address": "Kremser Landstraße 3", "place": "3100 St. Pölten", "displayName": "ÖGK (Niederösterreich)" },
                { "id": 4, "name": "ÖGK", "state": "Oberösterreich", "address": "Gruberstraße 77", "place": "4021 Linz", "displayName": "ÖGK (Oberösterreich)" },
                { "id": 5, "name": "ÖGK", "state": "Salzburg", "address": "Engelbert-Weiß-Weg 10", "place": "5020 Salzburg", "displayName": "ÖGK (Salzburg)" },
                { "id": 6, "name": "ÖGK", "state": "Steiermark", "address": "Josef-Pongratz-Platz 1", "place": "8010 Graz", "displayName": "ÖGK (Steiermark)" },
                { "id": 7, "name": "ÖGK", "state": "Tirol", "address": "Klara-Pölt-Weg 2", "place": "6020 Innsbruck", "displayName": "ÖGK (Tirol)" },
                { "id": 8, "name": "ÖGK", "state": "Vorarlberg", "address": "Jahngasse 4", "place": "6850 Dornbirn", "displayName": "ÖGK (Vorarlberg)" },
                { "id": 9, "name": "ÖGK", "state": "Wien", "address": "Wienerbergstraße 15-19", "place": "1100 Wien", "displayName": "ÖGK (Wien)" },
                { "id": 10, "name": "BVAEB", "state": "Kärnten", "address": "Siebenhügelstraße 1", "place": "9020 Klagenfurt am Wörthersee", "displayName": "BVAEB (Kärnten)" },
                { "id": 11, "name": "BVAEB", "state": "Oberösterreich", "address": "Hessenplatz 14", "place": "4020 Linz", "displayName": "BVAEB (Oberösterreich)" },
                { "id": 12, "name": "BVAEB", "state": "Salzburg", "address": "Faberstraße 2A", "place": "5020 Salzburg", "displayName": "BVAEB (Salzburg)" },
                { "id": 13, "name": "BVAEB", "state": "Steiermark", "address": "Grieskai 106", "place": "8020 Graz", "displayName": "BVAEB (Steiermark)" },
                { "id": 14, "name": "BVAEB", "state": "Tirol", "address": "Meinhardstraße 1", "place": "6010 Innsbruck", "displayName": "BVAEB (Tirol)" },
                { "id": 15, "name": "BVAEB", "state": "Vorarlberg", "address": "Montfortstraße 11", "place": "6900 Bregenz", "displayName": "BVAEB (Vorarlberg)" },
                { "id": 16, "name": "BVAEB", "state": "Wien, NÖ & Burgenland", "address": "Josefstädter Straße 80", "place": "1080 Wien", "displayName": "BVAEB (Wien, NÖ & Burgenland)" },
                { "id": 17, "name": "SVS", "state": "Burgenland", "address": "Siegfried Marcus-Straße 5", "place": "7000 Eisenstadt", "displayName": "SVS (Burgenland)" },
                { "id": 18, "name": "SVS", "state": "Kärnten", "address": "Bahnhofstraße 67", "place": "9020 Klagenfurt", "displayName": "SVS (Kärnten)" },
                { "id": 19, "name": "SVS", "state": "Niederösterreich", "address": "Neugebäudeplatz 1", "place": "3100 Sankt Pölten", "displayName": "SVS (Niederösterreich)" },
                { "id": 20, "name": "SVS", "state": "Oberösterreich", "address": "Hanuschstraße 34", "place": "4020 Linz", "displayName": "SVS (Oberösterreich)" },
                { "id": 21, "name": "SVS", "state": "Salzburg", "address": "Auerspergstraße 24", "place": "5020 Salzburg", "displayName": "SVS (Salzburg)" },
                { "id": 22, "name": "SVS", "state": "Steiermark", "address": "Körblergasse 115", "place": "8010 Graz", "displayName": "SVS (Steiermark)" },
                { "id": 23, "name": "SVS", "state": "Tirol", "address": "Klara-Pölt-Weg 1", "place": "6020 Innsbruck", "displayName": "SVS (Tirol)" },
                { "id": 24, "name": "SVS", "state": "Vorarlberg", "address": "Schloßgraben 14", "place": "6800 Feldkirch", "displayName": "SVS (Vorarlberg)" },
                { "id": 25, "name": "SVS", "state": "Wien", "address": "Wiedner Hauptstraße 84-86", "place": "1051 Wien", "displayName": "SVS (Wien)" },
                { "id": 26, "name": "LKUF", "state": "Oberösterreich", "address": "Leonfeldner Straße 11", "place": "4040 Linz", "displayName": "LKUF (Oberösterreich)" },
                { "id": 27, "name": "KFL", "state": "Oberösterreich", "address": "Böhmerwaldstraße 16", "place": "4020 Linz", "displayName": "KFL (Oberösterreich)" },
                { "id": 28, "name": "KFG", "state": "Oberösterreich", "address": "Friedrichstraße 11", "place": "4041 Linz", "displayName": "KFG (Oberösterreich)" },
                { "id": 29, "name": "KFA", "state": "Steiermark", "address": "Hauptplatz 1", "place": "8010 Graz", "displayName": "KFA (Graz)" },
            ],

            loading_client_data: false,
            error_loading_anamnese: false,
            error_loading_documentation: false,
            appointments: [],
            anamnese: null,
        };
    },

    methods: {

        async loadClientData(id) {
            // disable all editing possibilities while loading and indicate loading state
            this.loading_client_data = true;

            // reset error indicators
            this.error_loading_anamnese = false;
            this.error_loading_documentation = false;

            // set to empty immediately, so that "Behandlungsverlauf" disappears
            this.appointments = [];
            this.anamnese = null;

            let anamnese = await connector.getDataOnlyFiltered(this, 'vwdokumentationanamnese', 'eq', 'id', id, 'id');
            if (anamnese === -1) {
                // error has already been displayed
                this.anamnese = null;
                this.error_loading_anamnese = true;
            } else if (anamnese.length > 0) {
                let dec_anamnese = await cipher.decryptObject(this, this.$store.state.aes_key, anamnese[0]);
                this.anamnese = dec_anamnese.anamnese;
            } else {
                this.anamnese = null;
            }

            let appointments = await connector.getDataOnlyFiltered(this, 'vwterminedokumentation', 'eq', 'fk_klienten_id', id, 'datum', false);
            if (appointments === -1) {
                // error has already been shown, we return, so that
                this.appointments = [];
                this.error_loading_documentation = true;

            } else {
                this.appointments = await cipher.decryptDataSync(this, appointments);
            }

            this.loading_client_data = false;
        },

        createTextField(form, fieldName, value, options) {
            const textField = form.createTextField(fieldName);
            textField.addToPage(options.page, {
                x: options.x,
                y: options.y,
                width: options.width || 100,
                height: options.height || 20,
                borderWidth: options.borderWidth || 0,
                borderColor: options.borderColor || rgb(1, 1, 1, 0),
                font: options.font,
            });
            if (options.multiline) textField.enableMultiline();
            form.getTextField(fieldName).setFontSize(options.fontSize || 12);
            textField.setText(value);
            if (options.readOnly) textField.enableReadOnly();
            else textField.disableReadOnly();
            return textField;
        },

        createCheckBox(form, fieldName, value, options) {
            const checkBox = form.createCheckBox(fieldName);
            checkBox.addToPage(options.page, {
                x: options.x,
                y: options.y,
                width: options.width || 10,
                height: options.height || 10,
                borderWidth: options.borderWidth || 1,
                borderColor: options.borderColor || rgb(0, 0, 0),
            });
            if (value) checkBox.check();
            if (options.readOnly) checkBox.enableReadOnly();
            else checkBox.disableReadOnly();
            return checkBox;
        },

        createAllFormFields() {
            Object.entries(this.formConfig).forEach(([pageKey, pageConfig]) => {
                const pageIndex = parseInt(pageKey.replace('page', '')) - 1;
                Object.values(pageConfig).forEach(sectionConfig => {
                    this.generateFormFields(this.pdfForm, sectionConfig, this.pdfPages[pageIndex], true, this.pdfFont);
                });
            });
        },

        generateFormFields(form, config, page, readOnly, font) {
            config.forEach(field => {
                let value = '';
                if (field.formDataKey && this.formData[field.formDataKey] !== undefined) {
                    value = this.formData[field.formDataKey];
                } else if (field.getValue) {
                    value = field.getValue(this);
                } else {
                    value = field.defaultValue || '';
                }
                if (field.type === 'text') {
                    this.createTextField(form, field.name, value.toString(), {
                        ...field,
                        page,
                        font: font,
                        readOnly,
                    });
                } else if (field.type === 'checkbox') {
                    const isChecked = value === field.value;
                    this.createCheckBox(form, field.name, isChecked, {
                        ...field,
                        page,
                        font: font,
                        readOnly
                    });
                }
            });
        },

        updateField(formDataKey, value) {
            if (!this.pdfForm) return;

              // Handle self_insured logic
            if (formDataKey === 'self_insured') {
                if (value) {
                    this.updateField('insurer_name', '');
                    this.updateField('insurer_svnr', '');
                } else {
                    if (this.formData.insurer_name) {
                        this.updateField('insurer_name', this.formData.insurer_name);
                    }
                    if (this.formData.insurer_svnr) {
                        this.updateField('insurer_svnr', this.formData.insurer_svnr);
                    }
                }
            }

            const pdfFields = [];

            // Find the PDF fields corresponding to this formDataKey
            Object.values(this.formConfig).forEach(pageConfig => {
                Object.values(pageConfig).forEach(sectionConfig => {
                    sectionConfig.forEach(fieldConfig => {
                        if (
                            fieldConfig.formDataKey === formDataKey ||
                            (fieldConfig.dependsOn && fieldConfig.dependsOn.includes(formDataKey)) ||
                            (fieldConfig.conditionalDisplay && fieldConfig.conditionalDisplay.dependsOn === formDataKey)
                            ) {
                                pdfFields.push(fieldConfig);
                        }
                    });
                });
            });

            // Update each PDF field
            pdfFields.forEach(field => {
                const pdfField = this.pdfForm.getField(field.name);
                if (pdfField) {
                    if (field.type === 'text') {
                        if (field.conditionalDisplay) {
                            const shouldDisplay = this.formData[field.conditionalDisplay.dependsOn] === field.conditionalDisplay.showWhen;
                            pdfField.setText(shouldDisplay ? this.formData[field.formDataKey].toString() : '');
                        } else if (field.getValue) {
                            pdfField.setText(field.getValue(this).toString());
                        } else {
                            pdfField.setText(value.toString());
                        }
                    } else if (field.type === 'checkbox') {
                        if (value === field.value) {
                            pdfField.check();
                        } else {
                            pdfField.uncheck();
                        }
                    }
                }
            });

            // Debounced PDF render to avoid unnecessary re-renders
            this.debouncedRenderPDF();
        },

        async saveSpecificPages(pageIndices) {
            const pdfBytes = await this.pdfDoc.save();
            const newPdfDoc = await PDFDocument.load(pdfBytes);

            const pageIndexToKeep = pageIndices[0];
            const totalPages = newPdfDoc.getPageCount();

            // Remove pages from the end to the beginning, except the one we want to keep
            for (let i = totalPages - 1; i >= 0; i--) {
                if (i !== pageIndexToKeep) {
                    newPdfDoc.removePage(i);
                }
            }
            // Serialize the new PDF document to bytes
            const newPdfBytes = await newPdfDoc.save();
            return newPdfBytes;
        },

        async renderPDF() {
            if (!this.pdfDoc || !this.isMounted) return;  // Check if still mounted
            const pdfBytes = await this.saveSpecificPages([this.current_tab]);
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            if (this.form_url) URL.revokeObjectURL(this.form_url);
            this.form_url = URL.createObjectURL(blob);
        },

        setAllFieldsReadOnly(isReadOnly) {
            if (!this.pdfForm) return;

            const fields = this.pdfForm.getFields();
            fields.forEach(field => {
                if (field.constructor.name === 'PDFTextField' || field.constructor.name === 'PDFCheckBox') {
                    if (isReadOnly) {
                        field.enableReadOnly();
                    } else {
                        field.disableReadOnly();
                    }
                }
            });
        },

        async downloadForm() {
            if (!this.pdfDoc) return;

            this.setAllFieldsReadOnly(false);
            const pdfBytes = await this.pdfDoc.save();
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            let name = this.formData.firstname.substring(0, 2) + this.formData.lastname.substring(0, 2);
            await saveAs(blob, "antrag_zuschuss_" + name + ".pdf");
            this.setAllFieldsReadOnly(true);
        },

        async createInitialPDF() {
            const url = '/zuschuss.pdf';
            const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
            this.pdf_document = await PDFDocument.load(existingPdfBytes);
            this.pdfDoc = await PDFDocument.create();

            const existingPages = await this.pdfDoc.copyPages(this.pdf_document, [0, 1, 2, 3]);
            existingPages.forEach((page) => this.pdfDoc.addPage(page));

            // Get the dimensions of the first page
            const { width, height } = this.pdfDoc.getPage(0).getSize();
            this.pdfPageHeight = height;
            this.pdfPageWidth = width;

            this.pdfForm = this.pdfDoc.getForm() || this.pdfDoc.createForm();
            this.pdfPages = this.pdfDoc.getPages();
            this.pdfFont = await this.pdfDoc.embedFont(StandardFonts.Helvetica);

            // Create all form fields
            this.createAllFormFields();

            // Initial render
            await this.renderPDF();
        },

        acceptTerms() {
            localStorage.setItem('agreed_google_terms', true);
            this.dialog_google_terms = false;

            if (this.session) {
                connector.logError(this, {
                    uid: this.session.user.id,
                    message: this.session.user.email + ' accepted the Google terms',
                });
            }
        },

        rejectTerms() {
            if (this.session) {
                connector.logError(this, {
                    uid: this.session.user.id,
                    message: this.session.user.email + ' REJECTED the Google terms',
                });
            }
            this.dialog_google_terms = false;
        },

        selectDiagnosis(diagnosis) {
            if (this.formData.icd_diagnosis.find((item) => item.code === diagnosis.code)) {
                return;
            } else {
                this.formData.icd_diagnosis.push(diagnosis);
            }
        },

        limitGAFValue() {
            if (this.formData.gaf_value < 0) {
                this.formData.gaf_value = 0;
            } else if (this.formData.gaf_value > 100) {
                this.formData.gaf_value = 100;
            }
        },

        addText(fieldName, text, join_char = ', ') {
            if (this.formData[fieldName] === '' || !this.formData[fieldName]) {
                this.formData[fieldName] = text;
            } else {
                this.formData[fieldName] += join_char + text;
                this.formData[fieldName] = this.formData[fieldName].trim();
            }
        },

        async getRecommendations(type) {
            const validationRules = {
                getProgress: () => this.validateDiagnosis(),
                getAnamnese: () => this.validateDiagnosis(),
                getICDDiagnosis: () => this.validateAnamnese(),
                getGoals: () => this.validateDiagnosisAndGAF(),
                getReasons: () => this.validateAllFields(),
                getGAF: () => this.validateDiagnosisAndAnamnese(),
            };

            if (!validationRules[type]()) {
                return;
            }

            if (!this.checkGoogleTermsAgreement()) {
                return;
            }

            const requestBody = this.buildRequestBody(type);

            try {
                this.setLoading(type, true);
                const { data, error } = await supabase.functions.invoke('get-text-response', { body: requestBody });

                if (error) {
                    this.handleError(type, error);
                    return;
                }

                this.setRecommendations(type, this.parseLLMResponseText(data));
            } catch (error) {
                this.handleError(type, error);
            } finally {
                this.setLoading(type, false);
            }
        },

        validateDiagnosis() {
            if (this.formData.icd_diagnosis.length === 0) {
                this.$emit('showError', {
                    message: 'Bitte gib zuerst eine Diagnose ein um passende Vorschläge zu erhalten.',
                    timeout: 10000
                });
                return false;
            }
            return true;
        },

        validateAnamnese() {
            if (!this.formData.field_1 || this.formData.field_1.length < 10) {
                this.$emit('showError', {
                    message: 'Gib zuerst eine behandlungsbezogene Anamnese ein, um Vorschläge zu erhalten. Diese sollte mindestens 10 Zeichen lang sein.',
                    timeout: 10000
                });
                return false;
            }
            return true;
        },

        validateDiagnosisAndAnamnese() {
            if (!this.validateDiagnosis() || !this.validateAnamnese()) {
                return false;
            }
            return true;
        },

        validateDiagnosisAndGAF() {
            let missing = [];
            if (this.formData.icd_diagnosis.length === 0) missing.push('eine Diagnose');
            if (!this.formData.gaf_value) missing.push('den GAF-Wert');

            if (missing.length > 0) {
                let missing_text = missing.join(' und ');
                let message_text = 'Bitte gib zuerst ' + missing_text + ' an um passende Ziele vorgeschlagen zu bekommen.';
                this.$emit('showError', { message: message_text });
                return false;
            }
            return true;
        },

        validateAllFields() {
            let missing = [];
            if (this.formData.icd_diagnosis.length === 0) missing.push('eine Diagnose');
            if (!this.formData.field_1 || this.formData.field_1.length < 10) missing.push('eine Anamnese');
            if (!this.formData.field_2 || this.formData.field_2.length < 10) missing.push('einen Behandlungsverlauf');
            if (!this.formData.field_3 || this.formData.field_3.length < 10) missing.push('eine Zielsetzung');

            if (missing.length > 0) {
                let missing_text = missing.join(', ');
                let message_text = 'Bitte gib zuerst ' + missing_text + ' an um passende Begründungen vorgeschlagen zu bekommen. Diese sollten mindestens jeweils 10 Zeichen lang sein.';
                this.$emit('showError', { message: message_text, timeout: 10000 });
                return false;
            }
            return true;
        },

        checkGoogleTermsAgreement() {
            if (!localStorage.getItem('agreed_google_terms')) {
                this.dialog_google_terms = true;
                return false;
            }
            return true;
        },

        buildRequestBody(type) {
            const baseBody = {
                type,
                anamnese: this.formData.field_1,
                progress: this.formData.field_2,
                diagnosis: this.formData.icd_diagnosis.length > 0 ? this.formData.icd_diagnosis.map((diagnosis) => diagnosis.code).join(', ') : '',
            };

            if (type === 'getGoals' || type === 'getReasons') {
                baseBody.gaf = this.formData.gaf_value;
            }

            if (type === 'getReasons') {
                baseBody.goals = this.formData.field_3;
            }

            return baseBody;
        },

        setLoading(type, value) {
            this[`loading_${type}`] = value;
        },

        setRecommendations(type, recommendations) {
            const recommendationMap = {
                getProgress: 'progress_recommendations',
                getAnamnese: 'anamnese_recommendations',
                getICDDiagnosis: 'suggested_diagnosis',
                getGoals: 'suggested_goals',
                getReasons: 'suggested_reasons',
            };
            if (type === 'getGAF') {
                this.formData.gaf_value = recommendations[0].gaf;
                this.suggested_gaf_description = recommendations[0].description;
            } 
            else this[recommendationMap[type]] = recommendations;
        },

        async handleError(type, error) {
            const errorMessages = {
                getProgress: 'Textbausteine',
                getAnamnese: 'Textbausteine',
                getICDDiagnosis: 'Diagnosen',
                getGoals: 'Ziele',
                getReasons: 'Begründung',
                getGAF: 'GAF'
            };

            let error_message = error.message || '';

            if (error instanceof FunctionsHttpError) {
                let error_json = await error.context.json();
                error_message = error_json.error;
            }

            if (error_message.includes('429 Too Many Requests')) {
                this.$emit('showError', {
                    message: `Die Anfragen an die KI sind aktuell überlastet. Warte eine kurze Zeit und versuche es erneut.`,
                    timeout: 10000,
                });
            } else {
                this.$emit('showError', {
                    message: `Ein Fehler ist beim Vorschlagen der ${errorMessages[type]} aufgetreten. Bitte versuche es erneut.`,
                    timeout: 5000,
                });
            }
        },

        async initialize() {

            // set data for user therapist
            if (this.$store.state.client.name) this.formData.therapist_name = this.$store.state.client.name;
            if (this.$store.state.client.vpnr) this.formData.therapist_nr = 'VPNR: ' + this.$store.state.client.vpnr;
            if (this.$store.state.client.adresse) this.formData.therapist_address = this.$store.state.client.adresse;
            if (this.$store.state.client.plz) this.formData.therapist_address += '\n' + this.$store.state.client.plz;
            if (this.$store.state.client.ort) this.formData.therapist_address += ' ' + this.$store.state.client.ort;

            let customers = await connector.getDataOnly(this, 'vwklienten', 'id', true)
            this.checkReturnValueOfCustomers(customers);

            await this.createInitialPDF();
        },

        closeDialog() {
            this.dialog_prompt_download = false;
            this.dialog = false;
            if (this.form_url) {
                URL.revokeObjectURL(this.form_url);
                this.form_url = null;
            }
            this.removeWatchers();
        },

        removeWatchers() {
            this.formWatchers.forEach(unwatch => unwatch());
            this.formWatchers = [];
        },

        showSaveDialog() {
            this.dialog_save_form = true;
        },

        async saveForm() {
            if (!this.file_name) {
                this.$emit('showError', {
                    message: 'Bitte gib einen Dateinamen an.',
                    timeout: 10000
                });
                return;
            }

            if (!this.client) {
                this.$emit('showError', {
                    message: 'Bitte wähle zuerst einen Klienten aus.',
                    timeout: 10000
                });
                return;
            }

            // replace all german umlauts and ß with ae oe ue ss etc, also consider capital letters
            this.file_name = this.file_name.replace(/ä/g, 'ae').replace(/Ä/g, 'Ae').replace(/ö/g, 'oe').replace(/Ö/g, 'Oe').replace(/ü/g, 'ue').replace(/Ü/g, 'Ue').replace(/ß/g, 'ss');

            // check if the file name contains special characters, if so, show an error message and skip the file
            if (this.file_name.match(/[^a-zA-Z0-9. \-()_]/)) {
                this.$emit('showError', {
                    message: 'Dateinamen dürfen keine Sonderzeichen oder Umlaute enthalten.',
                });
                return;
            }

            this.saving_form = true;

            let form = {
                client_id: this.client.fk_klienten_id,
                application_form: this.formData.application_form,
                selected_insurer: this.selected_insurer,
                insurance: this.formData.insurance,
                insurance_state: this.formData.insurance_state,
                insurance_street: this.formData.insurance_street,
                insurance_place: this.formData.insurance_place,
                self_insured: this.formData.self_insured,
                insurer_name: this.formData.insurer_name,
                insurer_svnr: this.formData.insurer_svnr,
                lastname: this.formData.lastname,
                firstname: this.formData.firstname,
                address: this.formData.address,
                svnr: this.formData.svnr,
                therapist_name: this.formData.therapist_name,
                therapist_nr: this.formData.therapist_nr,
                therapist_address: this.formData.therapist_address,
                icd_diagnosis: this.formData.icd_diagnosis,
                gaf_value: this.formData.gaf_value,
                has_job: this.formData.has_job,
                job: this.formData.job,
                has_psychiatrist: this.formData.has_psychiatrist,
                psychiatrist: this.formData.psychiatrist,
                field_1: this.formData.field_1,
                field_2: this.formData.field_2,
                field_3: this.formData.field_3,
                therapy_setting: this.formData.therapy_setting,
                therapy_start: this.formData.therapy_start,
                therapy_units: this.formData.therapy_units,
                therapy_units_past: this.formData.therapy_units_past,
                therapy_units_other: this.formData.therapy_units_other,
                therapy_units_total: this.formData.therapy_units_total,
                therapy_frequency: this.formData.therapy_frequency,
                therapy_reason: this.formData.therapy_reason,
                therapy_setting_other_text: this.formData.therapy_setting_other_text,
                other_persons: this.formData.other_persons,
                notes: this.formData.notes,
            };

            let formString = new TextEncoder().encode(JSON.stringify(form));
            let encrypted_file = await cipher.encryptFile(this.$store.state.aes_key_file, formString);
            let fileSize = '1 KB';

            const fileDataJSON = JSON.stringify({
                iv: encrypted_file.iv,
                file: encrypted_file.file,
                size: fileSize,
            });

            let blob = new Blob([fileDataJSON], { type: 'application/json' });
            let client_id_folder = this.client.fk_klienten_id + '/';
            let uploaded = await connector.uploadFileToBucket(this, 'documentation', this.session.user.id + '/' + client_id_folder, this.file_name + '.kpzpsy', blob, '0', 'application/json');
            
            if (uploaded) {
                this.$emit('showInfo', {
                    message: 'Antrag wurde erfolgreich gespeichert. Du kannst ihn jederzeit in den Dokumenten des Klienten wiederfinden.',
                    timeout: 5000
                });
                this.dialog_save_form = false;
            }
            this.saving_form = false;
        },

        async editForm(prefill = null) {
            this.isPrefilling = true;
            await this.initialize();
            this.icdCodes = json.icdCodes;
            this.current_tab = 0;

            if (prefill) {
                if (this.current_file && 'name' in this.current_file) {
                    this.file_name = this.current_file.name.replace('.kpzpsy', '');
                }
                this.client = this.customers.find((customer) => customer.id === prefill.client_id);
                // we are loading client data here, as in the watcher the async causes issues with the waiting, causing diagnosis overwrites (empty)
                await this.loadClientData(this.client.fk_klienten_id);
                for (let key in prefill) {
                    if (key in this.formData) {
                        this.formData[key] = prefill[key];
                        this.updateField(key, prefill[key]);
                    }
                }
                this.setupWatchers();
                this.isPrefilling = false; // Set flag after prefill
                await this.renderPDF();
                this.dialog = true;
            } else {
                this.setupWatchers();
                this.isPrefilling = false; // Set flag after prefill
                this.dialog = true;
            }
        },

        sortListOfObjectsByString(a, b) {
            const nameA = a.name.toUpperCase() // ignore upper and lowercase
            const nameB = b.name.toUpperCase() // ignore upper and lowercase
            if (nameA > nameB) {
                return 1
            }
            if (nameA < nameB) {
                return -1
            }
            // names must be equal
            return 0
        },

        async checkReturnValueOfCustomers(customers) {
            if (customers === -1) {
                // wrong key
                this.customers = []
                this.n_customers = 0
            }
            else {
                this.customers = []
                this.n_customers = customers.length
                this.customers = await cipher.decryptDataSync(this, customers);
                this.customers = this.customers.map((dec) => {
                    let customer = dec;
                    customer.name = customer.nachname + ' ' + customer.vorname;
                    customer.fk_klienten_id = customer.id;
                    return customer;
                })

                this.loaded_customers = true;
            }
        },

        parseLLMResponseText(response) {
            let candidate_parts = response.candidates;
            let content_parts = candidate_parts.filter((item) => 'content' in item && 'parts' in item.content).map((item) => item.content.parts).flat(1);
            let text = content_parts.filter((item) => 'text' in item).map((item) => item.text).join("");

            if (!text) {
                return [];
            }

            try {
                const parsed_json = JSON.parse(text);

                if (typeof parsed_json === 'object' && parsed_json !== null && 'suggestions' in parsed_json) {
                    return Array.isArray(parsed_json.suggestions) ? parsed_json.suggestions : [];
                } else {
                    console.error('The parsed JSON does not contain a "suggestions" key or it\'s not an array');
                    return [];
                }
            } catch (error) {
                console.error('Error parsing JSON:', error);
                return [];
            }
        },

        setupWatchers() {
            const formDataKeys = Object.keys(this.formData);
            formDataKeys.forEach(key => {
                const unwatch = this.$watch(`formData.${key}`, (newValue) => {
                    if (!this.isPrefilling) {
                        if (key === 'gaf_value') {
                            this.limitGAFValue();
                        }
                        this.updateField(key, newValue);
                    }
                });
                this.formWatchers.push(unwatch);
            });
        },

        onIframeLoad(id) {
            this.iframeLoaded = true;
            this.updateClientWidth(id);
        },

        updateClientWidth(id) {
            this.$nextTick(() => {
                const iframe = this.$refs['iframe-pdf-' + id];
                if (iframe && iframe.clientWidth > 0) {
                    this.clientWidth = iframe.clientWidth;
                }
            });
        }
    },

    watch: {

        selected_insurer(newVal) {
            if (newVal !== null && newVal !== undefined && 'address' in newVal) {
                if (this.isPrefilling) return; 
                this.formData.insurance = newVal.name;
                this.formData.insurance_state = newVal.state;
                this.formData.insurance_street = newVal.address;
                this.formData.insurance_place = newVal.place;
            }
        },

        // watch if client changes, if yes, load the documentation for the client and set it. The function is asnyc.
        async client() {
            if (this.client !== null && this.client !== undefined && this.client.fk_klienten_id !== undefined && this.client.fk_klienten_id !== null) {
                // retrieve the client's anamnese from the database in the view vwdokumentationanamnese

                // reset selected insurer
                this.selected_insurer = null;

                const { mitversichert_bei, versicherungsträger } = this.client;

                let insurerId;
                if (mitversichert_bei) {
                    const propertyName = `${mitversichert_bei}_versicherungsträger`;
                    insurerId = this.client[propertyName];
                    
                    if (!(propertyName in this.client)) {
                        this.$emit('showError', {
                            message: 'Fehler beim Lesen der Versicherten Person. Fehler: Unbekannte Person. Bitte wende dich an den Support.',
                        });
                        return;
                    }
                } else {
                    insurerId = versicherungsträger;
                }

                this.selected_insurer = this.insurer.find(insurer => insurer.id === insurerId);

                if (this.isPrefilling) return; // <-- IMPORTANT: Ignore watcher until initial prefill is done otherwhise, we might overwrirte values

                await this.loadClientData(this.client.fk_klienten_id);

                // set all the client information
                if (this.client.vorname) this.formData.firstname = this.client.vorname;
                else this.formData.firstname = '';

                if (this.client.nachname) this.formData.lastname = this.client.nachname;
                else this.formData.lastname = '';

                this.formData.address = '';
                if (this.client.adresse) this.formData.address += this.client.adresse;
                if (this.client.plz) this.formData.address += '\n' + this.client.plz;
                if (this.client.ort) this.formData.address += ' ' + this.client.ort;

                if (this.client.svnr) this.formData.svnr = this.client.svnr;
                else this.formData.svnr = '';

                this.formData.icd_diagnosis = [];
                if (this.client.zusatztext) {
                    // try to parse diagnosis
                    let diagnoses = this.client.zusatztext.match(/([F][0-9]{2}\.[0-9]{1,2})/g);
                    if (diagnoses && diagnoses.length > 0) {
                        // find the diagnosis in the list of diagnoses
                        for (let diagnosis of diagnoses) {
                            let diagnosis_obj = this.icdCodes.find((diag) => diag.short_code === diagnosis);
                            if (diagnosis_obj) {
                                this.formData.icd_diagnosis.push(diagnosis_obj);
                            }
                        }
                    }
                }

                if (this.client.mitversichert_bei) {
                    this.formData.self_insured = false;

                    let insurance_obj = null;

                    // get the insurer information and set it
                    if (this.client.mitversichert_bei === 'vater') {
                        this.formData.insurer_name = this.client.vater_vorname + ' ' + this.client.vater_nachname;
                        this.formData.insurer_svnr = this.client.vater_svnr;
                        insurance_obj = this.insurer.find(insurer => insurer.id === this.client.vater_versicherungsträger);
                    } else if (this.client.mitversichert_bei === 'mutter') {
                        this.formData.insurer_name = this.client.mutter_vorname + ' ' + this.client.mutter_nachname;
                        this.formData.insurer_svnr = this.client.mutter_svnr;
                        insurance_obj = this.insurer.find(insurer => insurer.id === this.client.mutter_versicherungsträger);
                    } else if (this.client.mitversichert_bei === 'partner') {
                        this.formData.insurer_name = this.client.partner_vorname + ' ' + this.client.partner_nachname;
                        this.formData.insurer_svnr = this.client.partner_svnr;
                        insurance_obj = this.insurer.find(insurer => insurer.id === this.client.partner_versicherungsträger);
                    } else {
                        this.$emit('showError', {
                            message: 'Fehler beim Lesen der Versicherten Person. Fehler: Unbekannte Person. Bitte wende dich an den Support.',
                        });
                    }

                    if (insurance_obj) {
                        this.formData.insurance = insurance_obj.name;
                        this.formData.insurance_state = insurance_obj.state;
                        this.formData.insurance_street = insurance_obj.address;
                        this.formData.insurance_place = insurance_obj.place;
                    } else {
                        this.formData.insurance = '';
                        this.formData.insurance_state = '';
                        this.formData.insurance_street = '';
                        this.formData.insurance_place = '';
                    }
                } else {
                    this.formData.self_insured = true;
                    // reset those two values, as those might be set from previous client.
                    this.formData.insurer_name = '';
                    this.formData.insurer_svnr = '';
                    let insurance_obj = this.insurer.find(insurer => insurer.id === this.client.versicherungsträger);

                    if (insurance_obj) {
                        this.formData.insurance = insurance_obj.name;
                        this.formData.insurance_state = insurance_obj.state;
                        this.formData.insurance_street = insurance_obj.address;
                        this.formData.insurance_place = insurance_obj.place;
                    } else {
                        this.formData.insurance = '';
                        this.formData.insurance_state = '';
                        this.formData.insurance_street = '';
                        this.formData.insurance_place = '';
                    }
                }

                if (this.appointments.length > 0) {
                    let appointments = this.appointments.filter(appointment => appointment.fk_klienten_id === this.client.fk_klienten_id)
                    // get the last appointment as it is the first one and set the datum as therapy_start
                    if (appointments.length > 0) {
                        // format the date as DD.MM.YYYY using dayjs
                        this.formData.therapy_start = dayjs(appointments[appointments.length - 1].datum).format('DD.MM.YYYY');
                    }

                    // get all past appointments and sum up the units, use dayjs to compare the dates
                    let past_appointments = appointments.filter(appointment => appointment.fk_klienten_id === this.client.fk_klienten_id && dayjs(appointment.datum).isBefore(dayjs()));
                    if (past_appointments.length > 0) {
                        //  use the length and convert the number to a string
                        this.formData.therapy_units_past = past_appointments.length.toString();
                    }
                }
            } else {

                this.selected_insurer = null;
                // clear all the fields
                this.formData = Object.assign({}, this.formDataDefault);
                
                // set data for user therapist
                if (this.$store.state.client.name) this.formData.therapist_name = this.$store.state.client.name;
                if (this.$store.state.client.vpnr) this.formData.therapist_nr = 'VPNR: ' + this.$store.state.client.vpnr;
                if (this.$store.state.client.adresse) this.formData.therapist_address = this.$store.state.client.adresse;
                if (this.$store.state.client.plz) this.formData.therapist_address += '\n' + this.$store.state.client.plz;
                if (this.$store.state.client.ort) this.formData.therapist_address += ' ' + this.$store.state.client.ort;
            }
        },

        async current_tab() {
            await this.renderPDF();
        },
    },

    mounted() {
        this.isMounted = true;
        this.formDataDefault = Object.assign({}, this.formData);
        this.resizeHandler = () => {
            this.updateClientWidth(this.current_tab + 1);
            this.windowWidth = window.innerWidth;
            this.debouncedRenderPDF();
        };
        window.addEventListener('resize', this.resizeHandler);
    },
    beforeDestroy() {
        this.isMounted = false;  // Set to false when component is about to unmount
        window.removeEventListener('resize', this.resizeHandler);
        this.removeWatchers();
    },

    computed: {

        scaledHeight() {

            if (!this.pdfPageWidth || !this.iframeLoaded) {
                return 300; // Default height
            }

            let scale = (this.clientWidth / this.pdfPageWidth) * (this.windowWidth / this.windowWidth);
            scale = scale || 1; // Fallback to 1 if scale is 0

            let height = this.pdfPageHeight ? this.pdfPageHeight * scale : 300;
            return height;
        },

        gaf_color() {
            const gafValue = this.formData.gaf_value;
            if (!gafValue) return 'red darken-4';
            if (gafValue < 11) return 'red darken-4';
            if (gafValue < 21) return 'red darken-1';
            if (gafValue < 31) return 'orange darken-4';
            if (gafValue < 41) return 'orange darken-1';
            if (gafValue < 51) return 'yellow darken-4';
            if (gafValue < 61) return 'yellow darken-1';
            if (gafValue < 71) return 'lime accent-4';
            if (gafValue < 81) return 'light-green accent-4';
            if (gafValue < 91) return 'green accent-4';
            return 'green';
        },

        gafValueDescription() {
            const gafValue = this.formData.gaf_value;
            if (gafValue === 0) return 'Unzureichende Informationen';
            if (gafValue < 11) return 'Ständige Gefahr oder anhaltende Unfähigkeit';
            if (gafValue < 21) return 'Selbst- und Fremdgefährlichkeit';
            if (gafValue < 31) return 'Leistungsunfähigkeit in fast allen Bereichen';
            if (gafValue < 41) return 'Starke Beeinträchtigung in mehreren Bereichen';
            if (gafValue < 51) return 'Ernsthafte Beeinträchtigung';
            if (gafValue < 61) return 'Mäßig ausgeprägte Störung';
            if (gafValue < 71) return 'Leichte Beeinträchtigung';
            if (gafValue < 81) return 'Höchstens leichte Beeinträchtigungen';
            if (gafValue < 91) return 'Gute Leistungsfähigkeit auf allen Gebieten';
            return 'Optimale Funktion in allen Bereichen';
        },

        sortedCustomers() {
            return [...this.customers].sort(this.sortListOfObjectsByString);
        },

        suggestedDiagnoses() {
            if (!this.suggested_diagnosis || this.suggested_diagnosis.length === 0) {
                return "";
            }

            // match the icd codes with regex in the format FDD.D and store it in an array
            const matches = this.suggested_diagnosis;
            const responseArray = matches ? [...new Set(matches)] : [];

            // try to split the response into an array of strings using , as delimiter

            let mapped_diagnoses = [];

            // iterate over the array and try to find the most similar diagnoses from the list of icd10 diagnoses
            for (let i = 0; i < responseArray.length; i++) {
                let similarDiagnoses = this.icdCodes.filter((diagnosis) => diagnosis.short_code.toLowerCase() === responseArray[i].trim().toLowerCase());
                // add the similar diagnoses to the mapped_diagnoses array
                mapped_diagnoses.push(similarDiagnoses);
            }

            return [...new Set(mapped_diagnoses.flat(1))];
        },
    }
};
</script>

<style scoped>
.v-chip-custom {
    height: auto !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    overflow-wrap: break-word !important;
    word-wrap: break-word !important;
    white-space: normal !important;
}
.v-sheet.v-card {
    border-radius: 6px;
}
</style>
