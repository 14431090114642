<template>
    <v-card class="pa-5">
        <FilePreview ref="filePreview" :session="session" :client_id="clientId" @showError="(arg) => $emit('showError', arg)" @showInfo="(arg) => $emit('showInfo', arg)" @getBucketFiles="getBucketFiles" />

        <div class="d-flex align-center mb-5">
            <div class="text-h6">
                Dokumente
            </div>
            <v-progress-circular v-if="retrievingFiles" indeterminate color="primary" size="20" class="ml-3" />
            <v-btn v-else outlined small class="ml-3" @click="selectFiles" :disabled="error_retrieving_files">
                <v-icon left>mdi-plus</v-icon>
                Hinzufügen
            </v-btn>
        </div>

        <v-row no-gutters v-if="sortedAllUploadedFiles.length > 0">
            <v-col v-for="(file) in sortedAllUploadedFiles" :key="'all-' + file.id" cols="12" :sm="smCols" :md="mdCols">
                <v-hover v-slot="{ hover }">
                    <v-card class="d-flex align-center px-3 py-1 ma-2 file-card" :elevation="hover ? 2 : 0" outlined
                        @click.stop="openFile(file)" :disabled="file.uploading">
                        <v-icon v-if="!file.uploading" class="mr-3" :color="file.iconColor">{{ file.icon
                            }}</v-icon>
                        <v-progress-circular v-else class="mr-3" :size="30" indeterminate color="primary" />
                        <div class="file-info-container d-flex flex-column justify-center flex-grow-1">
                            <span class="text-caption text-truncate-file">{{ file.name }}</span>
                            <span>
                                {{ file.size }}
                                <v-chip v-if="file.appointment_id" class="ml-2" small>Einheit: {{
                    getDateFromAppointmentId(file.appointment_id) }}</v-chip>
                            </span>
                        </div>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>

        <v-card v-if="sortedAllUploadedFiles.length === 0 && !retrievingFiles && !error_retrieving_files" class="pa-5 mt-3 upload-area" outlined
            v-on:dragover.prevent="dragOver = true" v-on:dragleave.prevent="dragOver = false"
            v-on:drop.prevent="handleDrop">
            <div v-if="!dragOver">
                <v-icon left>mdi-file-upload-outline</v-icon>
                Ziehe Dokumente, Bilder, oder PDFs hierher um sie hochzuladen.
            </div>
            <div v-if="!dragOver" class="py-3">
                Oder wähle die Dateien aus um sie hochzuladen.
            </div>
            <div v-if="dragOver" class="py-5 text-h6">Lass einfach los.</div>
            <v-btn v-if="!dragOver" :color="$store.state.theme.primary" dark @click="selectFiles">
                <v-icon left>
                    mdi-plus
                </v-icon>
                Dateien Hinzufügen
            </v-btn>
        </v-card>
        <div v-else-if="error_retrieving_files">
            <v-icon left>mdi-connection</v-icon>
            Die Dokumente konnten nicht geladen werden.<br/>
            <v-btn outlined :color="$store.state.theme.green" class="mt-5" @click="getBucketFiles">
                <v-icon left>
                    mdi-refresh
                </v-icon>
                Neu Laden
            </v-btn>
        </div>
        <input type="file" ref="fileInput" hidden multiple @change="handleFiles" />
    </v-card>
</template>

<script>
import connector from '../helpers/supabase-connector.js'
import cipher from '../helpers/cipher.js'
import dayjs from 'dayjs'
import FilePreview from '../components/FilePreview.vue';

export default {
    name: 'UploadedFilesCard',
    components: {
        FilePreview
    },
    emits: [
        'showError', 
        'showInfo',
        'update:uploadedAppointmentFiles',
        'updatedFiles'
    ],
    props: {
        session: {
            type: Object,
            required: true
        },
        clientId: {
            type: Number,
            required: true
        },
        appointments: {
            type: Array,
            default: () => []
        },
        smCols : {
            type: Number,
            default: () => 6
        },
        mdCols : {
            type: Number,
            default: () => 6
        }
    },
    data() {
        return {
            retrievingFiles: false,
            error_retrieving_files: false,
            dragOver: false,
            uploadedFiles: [],
            uploadedAppointmentFiles: [],
            selected_appointment_id: null,
        }
    },

    watch: {
        clientId: {
            immediate: true,
            handler(newClientId, oldClientId) {
                if (newClientId !== oldClientId) {
                    this.getBucketFiles();
                }
            }
        }
    },

    methods: {

        emitUploadedFiles() {
            this.$emit('update:uploadedAppointmentFiles', this.uploadedAppointmentFiles);
            this.$emit('updatedFiles');
        },

        selectFilesForAppointment(appointmentId) {
            this.selected_appointment_id = appointmentId;
            this.$refs.fileInput.click();
        },

        async getBucketFiles() {

            this.retrievingFiles = true;
            this.error_retrieving_files = false;
            this.uploadedFiles = [];
            this.uploadedAppointmentFiles = [];

            // list all the files in the documentation folder of the selected client
            let client_id_folder = this.clientId + '/'
            let files = await connector.listFilesInBucket(this, 'documentation', this.session.user.id + '/' + client_id_folder);
            if (files === -1) {
                // error has already been displayed
                // set error variable and continue normally
                this.error_retrieving_files = true;
                files = []; // set to empty array from -1
            }

            this.uploadedFiles = [...files.filter(file => file.id !== null && file.name !== '.emptyFolderPlaceholder').map(file => {
                return {
                    id: file.id,
                    created_at: file.created_at,
                    updated_at: file.updated_at,
                    name: file.name,
                    size: this.bytesToSize(file.metadata.size),
                    icon: this.getFileIcon(file.metadata.mimetype),
                    mimetype: file.metadata.mimetype,
                    iconColor: this.getIconColor(file.metadata.mimetype),
                    uploading: false,
                }
            })];

            // get the files which are uploaded to subfolders of the client folder, which are the appointment ids which they belong to
            let uploadedAppointmentPaths = files.filter(file => file.id === null && file.name !== '.emptyFolderPlaceholder');
            
            for (const path of uploadedAppointmentPaths) {
                let appointment_id = path.name;
                let appointment_files = await connector.listFilesInBucket(this, 'documentation', this.session.user.id + '/' + client_id_folder + appointment_id + '/');
                if (appointment_files === -1) {
                    // error has already been shown
                    // stop the loop, and clear all files and prompt reload dialog
                    this.uploadedFiles = [];
                    this.uploadedAppointmentFiles = [];
                    this.error_retrieving_files = true;
                    break;
                }

                // concat the appointment files to the uploadedAppointmentFiles array
                this.uploadedAppointmentFiles = this.uploadedAppointmentFiles.concat(appointment_files.filter(file => file.id !== null).map(file => {
                    return {
                        id: file.id,
                        appointment_id: parseInt(appointment_id),
                        created_at: file.created_at,
                        updated_at: file.updated_at,
                        name: file.name,
                        size: this.bytesToSize(file.metadata.size),
                        icon: this.getFileIcon(file.metadata.mimetype),
                        mimetype: file.metadata.mimetype,
                        iconColor: this.getIconColor(file.metadata.mimetype),
                        uploading: false,
                    }
                }));
            }

            this.emitUploadedFiles();
            this.retrievingFiles = false;
        },

        bytesToSize(bytes) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
            if (bytes === 0) return '0 Byte'
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
        },

        selectFiles() {
            this.$refs.fileInput.click();
        },

        handleDrop(event) {
            this.dragOver = false;
            this.handleFiles(event);
        },

        getDateFromAppointmentId(appointment_id, return_as_dayjs = false) {
            // format it to DD.MM.YYYY
            let appointment = this.appointments.find(appointment => appointment.id === appointment_id);
            if (appointment && appointment.datum) {
                if (return_as_dayjs) return dayjs(appointment.datum);
                else return dayjs(appointment.datum).format('DD.MM.YYYY');
            } else {
                return null
            }
        },

        async handleFiles(event) {
            const files = event.target.files || event.dataTransfer.files;
            try {
                let uploaded = await this.uploadFilesToSupabase(files);
                if (uploaded.length === 0 || uploaded.some((file) => file === null)) {
                    await connector.logError(this, {
                        uid: this.session.user.id,
                        message: 'LOG: Possible error during file upload in documentation.',
                    });
                    // some uploads or all failed, so reload the files. 
                    this.$nextTick(() => {
                        this.getBucketFiles();
                    });
                } else {
                    // all good.
                    this.emitUploadedFiles();
                    this.$emit('showInfo', {
                        message: uploaded.length === 1 ? 'Die Datei wurde erfolgreich hochgeladen.' : 'Die Dateien wurden erfolgreich hochgeladen.',
                        timeout: 5000
                    });
                }
            } catch (error) {
                this.$emit('showError', {
                    message: 'Ein Fehler ist beim Hochladen der Dateien aufgetreten. Bitte versuche es erneut.',
                    timeout: 10000,
                    error: error
                });
                this.$nextTick(() => {
                    this.getBucketFiles();
                });
            } finally {
                this.resetFileInput();
            }
        },

        resetFileInput() {
            if (this.$refs.fileInput) {
                this.$refs.fileInput.value = '';
            }
            this.selected_appointment_id = null;
        },

        async uploadFilesToSupabase(files) {
            if (files.length === 0) return;

            let client_id_folder = this.clientId + '/';
            if (this.selected_appointment_id !== null) {
                client_id_folder = this.clientId + '/' + this.selected_appointment_id + '/';
            }

            let newUploadedFiles = [];

            // check if aes_key_file is set, if not then try to load it again
            if (!this.$store.state.aes_key_file) {
                let keys = await cipher.getAESKeys(this);
                this.$store.state.aes_key_file = keys['aes_key_file'];
            }

            for (const file of files) {

                let file_name = file.name;

                // iterate over each character and check if it is an umlaute, if so replace it with
                for (let i = 0; i < file_name.length; i++) {
                    if (file_name.charCodeAt(i) === 776) {
                        // umlaute, replace with e
                        file_name = file_name.replace(file_name[i], 'e');
                    } else if (file_name.charCodeAt(i) === 223) {
                        // ß, replace with ss
                        file_name = file_name.replace(file_name[i], 'ss');
                    }
                }

                // check if the file name contains special characters, if so, show an error message and skip the file
                if (file_name.match(/[^a-zA-Z0-9. \-()_]/)) {
                    this.$emit('showError', {
                        message: 'Dateinamen dürfen keine Sonderzeichen oder Umlaute enthalten.',
                    });
                    continue;
                }

                let duplicate_files = [];
                // check if the file already exists in the folder, if so, add a number to the file name
                if (this.selected_appointment_id !== null) {
                    duplicate_files = this.uploadedAppointmentFiles.filter(f => f.name === file_name);
                } else {
                    duplicate_files = this.uploadedFiles.filter(f => f.name === file_name);
                }
                
                if (duplicate_files.length > 0) {
                    let i = 1; // Start with 1 and increment this number until a unique file name is found
                    let newName = file_name;
                    let baseName, extension;

                    // Check if the file has an extension
                    if (file_name.includes('.')) {
                        baseName = file_name.substring(0, file_name.lastIndexOf('.'));
                        extension = file_name.substring(file_name.lastIndexOf('.'));
                    } else {
                        baseName = file_name;
                        extension = '';
                    }

                    if (this.selected_appointment_id !== null) {
                        while (this.uploadedAppointmentFiles.some(f => f.name === newName)) {
                            newName = `${baseName} (${i})${extension}`;
                            i++;
                        }
                    } else {
                        // Loop until a unique file name is found
                        while (this.uploadedFiles.some(f => f.name === newName)) {
                            newName = `${baseName} (${i})${extension}`;
                            i++;
                        }
                    }

                    file_name = newName; // Assign the unique file name
                }

                // Create a unique placeholder ID
                const placeholderId = 'placeholder_' + Date.now() + '_' + Math.random().toString(36).slice(2, 11);

                // Add template file to uploadedFiles so that the user can see the progress
                if (this.selected_appointment_id === null) {
                    this.uploadedFiles.unshift({
                        id: placeholderId,
                        name: file_name,
                        uploading: true,
                        size: 'wird hochgeladen...'
                    });
                } else {
                    this.uploadedAppointmentFiles.unshift({
                        id: placeholderId,
                        name: file_name,
                        appointment_id: this.selected_appointment_id,
                        uploading: true,
                        size: 'wird hochgeladen...'
                    });
                }

                try {
                    const reader = new FileReader();
                    const fileData = await new Promise((resolve, reject) => {
                        reader.onload = (e) => resolve(e.target.result);
                        reader.onerror = reject;
                        reader.readAsArrayBuffer(file);
                    });

                    let encrypted_file = await cipher.encryptFile(this.$store.state.aes_key_file, fileData);
                    let fileSize = file.size > 1024 * 1024 ? (file.size / (1024 * 1024)).toFixed(0) + ' MB' : (file.size / 1024).toFixed(0) + ' KB';

                    const fileDataJSON = JSON.stringify({
                        iv: encrypted_file.iv,
                        file: encrypted_file.file,
                        size: fileSize,
                    });

                    const fType = file.type ? { type: file.type } : { type: 'application/octet-stream' };
                    const blob = new Blob([fileDataJSON], fType);

                    const result = await connector.uploadFileToBucket(this, 'documentation', this.session.user.id + '/' + client_id_folder, file_name, blob, '3600', 'application/json');
                    if (result === null) {
                        // error has already been shown
                        newUploadedFiles.push(null);
                        continue;
                    }

                    const uploadedFile = {
                        id: result.id,
                        name: file_name,
                        size: fileSize,
                        icon: this.getFileIcon(file.type),
                        iconColor: this.getIconColor(file.type),
                        mimetype: file.type,
                        uploading: false,
                        appointment_id: this.selected_appointment_id,
                        created_at: new Date().toISOString(),
                        updated_at: new Date().toISOString(),
                    };

                    if (this.selected_appointment_id === null) {
                        const placeholderIndex = this.uploadedFiles.findIndex(file => file.id === placeholderId);
                        if (placeholderIndex !== -1) {
                            this.$set(this.uploadedFiles, placeholderIndex, uploadedFile);
                        } else {
                            // this should not happen, probalby reload all files.
                            this.uploadedFiles.push(uploadedFile);
                        }
                    } else {
                        const placeholderIndex = this.uploadedAppointmentFiles.findIndex(file => file.id === placeholderId);
                        if (placeholderIndex !== -1) {
                            this.$set(this.uploadedAppointmentFiles, placeholderIndex, uploadedFile);
                        } else {
                            // this should not happen, probalby reload all files.
                            this.uploadedAppointmentFiles.push(uploadedFile);
                        }
                    }
                    newUploadedFiles.push(true);
                } catch (error) {
                    this.$emit('showError', {
                        message: 'Es ist ein Fehler während des hochladen der Datei passiert. Bitte versuche es erneut.',
                        timeout: 10000,
                        error: error
                    });
                    newUploadedFiles.push(null);
                }
            }

            return newUploadedFiles;
        },

        getFileIcon(type) {
            // Return an icon based on the file type
            switch (type) {
                case 'application/pdf':
                    return 'mdi-file-pdf-box';
                case 'image/jpeg':
                case 'image/png':
                case 'image/heic':
                    return 'mdi-file-image';
                // Add more cases for different file types
                default:
                    return 'mdi-file-document-outline';
            }
        },

        getIconColor(type) {
            // Return a color based on the file type
            switch (type) {
                case 'application/pdf':
                    return 'red';
                case 'image/jpeg':
                case 'image/png':
                case 'image/heic':
                    return 'orange';
                // Add more cases for different file types
                default:
                    return 'blue';
            }

        },

        async openFile(file) {
            file.uploading = true; // Show loading spinner
            await this.$refs.filePreview.openFile(file);
            file.uploading = false; // Hide loading spinner
        },
    },

    computed: {
        sortedAllUploadedFiles() {

            const nonAppointmentFiles = this.uploadedFiles.filter(file => !file.appointment_id)
                .sort((a, b) => dayjs(b.updated_at).diff(dayjs(a.updated_at)));

            const appointmentFiles = this.uploadedAppointmentFiles.filter(file => file.appointment_id)
                .sort((a, b) => {
                    const dateA = this.getDateFromAppointmentId(a.appointment_id, true);
                    const dateB = this.getDateFromAppointmentId(b.appointment_id, true);
                    if (!dateA || !dateB) {
                        // potential error during loading appointments, therefore just return 0 (considered equal in sorting)
                        return 0;
                    }
                    return dateB.diff(dateA);
                });

            return [...nonAppointmentFiles, ...appointmentFiles];
        },
    }
}
</script>

<style scoped>
.v-sheet.v-card {
  border-radius: 6px;
}

.upload-area {
    border: dashed 2px lightgrey;
    border-radius: 20px;
    text-align: center;
}

.upload-area.drag-over {
    background-color: #f0f0f0;
}

/* Additional styles for the file info container */
.file-info-container {
    min-width: 0;
    /* This ensures that the container can shrink as needed */
}

/* Ensure text truncation is applied */
.text-truncate-file {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.file-card {
    transition: box-shadow .3s ease;
    cursor: pointer;
    /* position: relative; */
    /* Ensure the positioning context for the speed dial */
}

</style>